<template>
  <finalise-onboarding type="login" :is-closeable="isCloseable" is-modal @close="$router.push('/')"/>
</template>
<script lang="ts">
  import FinaliseOnboarding from '../../pages/Onboarding/RegistrationContainer.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      }
    },
    components: {
      FinaliseOnboarding,
    },
  })
</script>
