<template>
  <finalise-onboarding type="packages-sign-up" is-modal/>
</template>

<script>
  import FinaliseOnboarding from '../../pages/Onboarding/RegistrationContainer.vue'

  export default {
    name: 'PackagesSignUpModal.vue',
    components: {
      FinaliseOnboarding,
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/_variables.scss";

  .promocode-validation__container {
    border-right: 1px solid $color-borders;
    padding-right: 15px;
    margin-right: 15px;
    width: 50%;
  }

  .payment-info__container {
    width: calc(50% - 15px);
  }

  :deep(.modal__header .divider) {
    max-width: 100%;
  }

  .modal-no-footer {
    :deep(.modal__body) {
      margin-bottom: 0;
    }
  }

  :deep(.modal__mask) {
    padding-top: 0
  }

  :deep(.modal__wrapper) {
    max-height: 90%;
  }

  :deep(fieldset) {
    width: 100%;
    display: inline-block;
  }

  .firstStep {
    display: flex;
  }

  .notFirstStep {
    display: flex;
    flex-direction: column-reverse;
  }
</style>
