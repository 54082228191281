<template>
  <div class="list-view">
    <table class="table table--fill table--striped table--hover" v-if="actors && actors.length">
      <thead>
      <tr>
        <th width="50" v-if="displayExplorationColumn"></th>
        <th-sort by="name" width="200">Name</th-sort>
        <template v-for="column in columns">
          <th-sort v-if="column.sortable" :by="getSortingProperty(column)" :width="column.width">{{ humanizeColumn(column.text) }}</th-sort>
          <th v-else :width="column.width">{{ humanizeColumn(column.text) }}</th>
        </template>
      </tr>
      </thead>
      <tbody>
      <sortable-list-view-row
        v-for="(actor, index) in actors"
        :columns="columns"
        :with-exploration-column="displayExplorationColumn"
        :key="index"
        :company="actor"
        @click="openSidePanel(actor)"
      />
      </tbody>
    </table>
    <div class="explore__empty-state" v-else-if="this.$store.state.actors && this.$store.state.actors.listData.loading">
      Loading actors...
    </div>
    <div class="explore__empty-state" v-else>
      No actors found!
    </div>
  </div>
</template>

<script lang="ts">
  import SortableListViewRow from './SortableListViewRow.vue'
  import ThSort from './ThSort.vue'

  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  import { getReportFieldById } from '../../util/helpers.js'

  import { humanize } from '../../constants/properties.js'

  import UiMixin from '../../util/UiMixin.js'
  import { defineComponent } from 'vue'

  const thProperties = [
    {
      columnName: 'name',
      text: '',
      columnWidth: 200,
    },
    {
      columnName: 'total_funding',
      text: 'Funding raised',
      columnWidth: 160,
    },
    {
      columnName: 'total_investment',
      text: 'Amount of funding rounds',
      columnWidth: 160,
    },
    {
      columnName: 'funding_date',
      text: 'Funding round - date',
      columnWidth: 150,
    },
    {
      columnName: 'funding_type',
      text: 'Funding round - type',
      columnWidth: 150,
    },
    {
      columnName: 'funding_amount',
      text: 'Funding round - amount',
      columnWidth: 150,
    },
    {
      columnName: 'founding_date',
      text: 'Founding date',
      columnWidth: 150,
    },
    {
      columnName: 'created_at',
      text: 'Created At ',
      columnWidth: 150,
    },
    {
      columnName: 'updated_at',
      text: 'Updated At',
      columnWidth: 150,
    },
    {
      columnName: 'zip',
      text: 'ZIP code',
      columnWidth: 70,
    },
    {
      columnName: 'city',
      text: 'City',
      columnWidth: 150,
    },
    {
      columnName: 'country',
      text: 'Country',
      columnWidth: 100,
    },
    {
      columnName: 'market',
      text: 'Target markets',
      columnWidth: 200,
    },
    {
      columnName: 'industries',
      text: 'Industries',
      columnWidth: 200,
    },
    {
      columnName: 'lastFundingType',
      text: 'Last funding round - type',
      columnWidth: 200,
    },
    {
      columnName: 'lastFundingDate',
      text: 'Last funding round - date',
      columnWidth: 200,
    },
    {
      columnName: 'stage',
      text: 'Growth Stage',
      columnWidth: 100,
    },
    {
      columnName: 'custom_score',
      text: 'Custom Score',
      columnWidth: 60,
    },
    {
      columnName: 'vacancy_count',
      text: 'Vacancy Count',
      columnWidth: 60,
    },
    {
      columnName: 'funnel_stage',
      text: 'CRM Stage',
      columnWidth: 100,
    },
    {
      columnName: 'category',
      text: 'Category',
      columnWidth: 200,
    },
    {
      columnName: 'subIndustries',
      text: 'Subindustries',
      columnWidth: 160,
    },
    {
      columnName: 'membership',
      text: 'Membership',
      columnWidth: 140,
    },
    {
      columnName: 'maturity',
      text: 'Maturity',
      columnWidth: 120,
    },
    {
      columnName: 'funding_stage',
      text: 'Funding stage',
      columnWidth: 120,
    },
    {
      columnName: 'technology',
      text: 'Technologies',
      columnWidth: 140,
    },
    {
      columnName: 'activities',
      text: 'Activities',
      columnWidth: 140,
    },
    {
      columnName: 'possible_duplicate',
      text: 'Possible duplicate',
      columnWidth: 70,
    },
    {
      columnName: 'is_zombie',
      text: 'Zombie',
      columnWidth: 70,
    },
    {
      columnName: 'patent_count',
      text: 'Patent count',
      columnWidth: 70,
    },
    {
      columnName: 'relationship_count',
      text: 'Total Connections',
      columnWidth: 70,
    },
    {
      columnName: 'product_relationship_count',
      text: 'Product Count',
      columnWidth: 70,
    },
    {
      columnName: 'completeness',
      text: 'Complete',
      columnWidth: 70,
    },
    {
      columnName: 'claimed',
      text: 'Claimed',
      columnWidth: 70,
    },
    {
      columnName: 'accelerated',
      text: 'Accelerated',
      columnWidth: 100,
    },
    {
      columnName: 'accelerated_by',
      text: 'Accelerated By',
      columnWidth: 100,
    },
    {
      columnName: 'acquired',
      text: 'Acquired',
      columnWidth: 100,
    },
    {
      columnName: 'acquired_by',
      text: 'Acquired By',
      columnWidth: 100,
    },
    {
      columnName: 'collaborates_with',
      text: 'Collaborates With',
      columnWidth: 100,
    },
    {
      columnName: 'has_account_manager',
      text: 'Has Account Manager',
      columnWidth: 100,
    },
    {
      columnName: 'has_administrators',
      text: 'Has Administrator',
      columnWidth: 100,
    },
    {
      columnName: 'has_collaboration_with',
      text: 'Collaborates With',
      columnWidth: 100,
    },
    {
      columnName: 'has_employee',
      text: 'Has Employee',
      columnWidth: 100,
    },
    {
      columnName: 'has_founded',
      text: 'Has Founded',
      columnWidth: 100,
    },
    {
      columnName: 'invested',
      text: 'Invested',
      columnWidth: 100,
    },
    {
      columnName: 'invested_by',
      text: 'Invested By',
      columnWidth: 100,
    },
    {
      columnName: 'is_account_manager_of',
      text: 'Is Account Manager Of',
      columnWidth: 100,
    },
    {
      columnName: 'is_administrator',
      text: 'Is Administrator',
      columnWidth: 100,
    },
    {
      columnName: 'is_employee_of',
      text: 'Is Employee Of',
      columnWidth: 100,
    },
    {
      columnName: 'is_founded_by',
      text: 'Is Founded By',
      columnWidth: 100,
    },
    {
      columnName: 'member_of',
      text: 'Member Of',
      columnWidth: 100,
    },
    {
      columnName: 'members',
      text: 'Members',
      columnWidth: 160,
    },
    {
      columnName: 'spinoff_from',
      text: 'Spinoff From',
      columnWidth: 160,
    },
    {
      columnName: 'spinoffs',
      text: 'Spinoffs',
      columnWidth: 160,
    },
    {
      columnName: 'industry_codes',
      text: 'Main NACE Code',
      columnWidth: 70,
    },
    {
      columnName: 'tags',
      text: 'Tags',
      columnWidth: 70,
    },
    {
      columnName: 'private_tags',
      text: 'Private Tags',
      columnWidth: 70,
    },
  ]

  export default defineComponent({
    props: ['actors', 'userColumns'],
    computed: {
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      displayExplorationColumn () {
        return this.$store.getters.hasAccessToRelevancyUi && this.$store.getters.isMember
      },
      // Returns mapping e.g. [{ industries: {name: 'columnName', text: 'columnText', width: 'columnWidth', sortable: true/false}, ... }]
      columns () {
        const userColumns = this.userColumns.filter(userColumn => userColumn.text !== '')
        const viewList = this.userColumns && this.userColumns.length ? this.userColumns : this.$store.state.config.viewList || []

        // Columns that cannot be used to sort
        var unsortableColumns = ['total_investment', 'industries', 'lastFundingType', 'lastFundingDate', 'industry_codes']
        unsortableColumns = unsortableColumns.concat(this.filterableRelationships)

        // Columns that only belong to the funding list dashboard
        var fundingDashboardColumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

        var columns = []

        if (viewList) {
          for (var i = 0; i < viewList.length; i++) {
            var columnObject = {}
            var columnText = thProperties.filter(th => th.columnName == viewList[i]).length > 0 ? thProperties.filter(th => th.columnName == viewList[i])[0].text : ''
            var columnWidth = thProperties.filter(th => th.columnName == viewList[i]).length > 0 ? thProperties.filter(th => th.columnName == viewList[i])[0].columnWidth : ''

            if (unsortableColumns.includes(viewList[i]) || viewList[i].indexOf('report_field_') >= 0) {
              if (!this.isInFundingDashboard && viewList[i] == 'industries') {
                columnObject.sortable = true
              } else {
                columnObject.sortable = false
              }
            } else {
              columnObject.sortable = true
            }

            columnObject.name = viewList[i]
            columnObject.text = columnText || viewList[i]
            columnObject.width = columnWidth
            columns.push(columnObject)
          }
        }

        // If the we're not in the funding list dashboard, we filter out all possible columns that only belong to the funding list dashboard
        if (!this.isInFundingDashboard) {
          columns = columns.filter(column => {
            return !fundingDashboardColumns.includes(column.name)
          })
        }

        return columns
      },
      order () {
        return this.$store.state.filters.paging.order
      },
      isInFundingDashboard () {
        return this.$route.path === '/dashboards/funding-list'
      },
    },
    methods: {
      getSortingProperty (column) {
        if (column.name == 'current_employees') {
          return 'current_employees.upper_bound'
        }

        return column.name
      },
      humanizeColumn (columnText) {
        if (typeof columnText !== 'string') {
          console.error('columnText isn\'t a string', columnText)
          return ''
        }

        if (columnText.indexOf('report_field_') >= 0) {
          var reportFieldId = columnText.replace('report_field_', '')
          var reportField = getReportFieldById(reportFieldId)

          if (!reportField) {
            return 'report'
          }

          return reportField.label
        }

        return humanize(columnText)
      },
      toggleOrder (order) {
        if (order == 'current_employees') {
          order = 'current_employees.upper_bound'
        }
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_ORDER, order)
      },
      openSidePanel (val) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: val.id })
      },
    },
    mixins: [UiMixin],
    components: {
      SortableListViewRow,
      ThSort,
    },
  })
</script>
