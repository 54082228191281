<template>
  <template v-if="community && community.id && Number(community.id) === Number(communityId)">
    <!-- move this out of the community detail div, otherwise scrolling wont work, this component needs to be reworked a bit so that sub navigation sections are used in the most vanilla way they can be, instead of being wrapped in divs -->
    <template
        v-if="areMessageBoardsEnabled && hasAccessToAnnouncementsAndEventsForCommunities && subNavSection === 'announcements' && !$route.params.id">
      <AnnouncementsNew :allow-create-announcement="canCreateAnnouncement(community)"/>
    </template>
    <template
        v-if="areMessageBoardsEnabled && hasAccessToAnnouncementsAndEventsForCommunities && subNavSection === 'events' && !$route.params.id">
      <EventsNew :allow-create-announcement="canCreateAnnouncement(community)"/>
    </template>

    <div class="page-community-detail scrollable communities"
         :class="{'apply-community-color': subNavSection !== 'members' || !$route.params.id}">
      <!-- don't apply community color on actor detail page -->
      <BasicHero
          :title="community.name"
          :subtitle="community.description"
          v-if="!subNavSection"
      />

      <div v-if="subNavSection === ''">
        <!-- left side -->
        <div class="container communities-container" style="margin-bottom: 20px;">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6" v-if="manager">
              <community-detail-manager-card :manager="manager">
                <h4 class="h4">{{ manager.name }}</h4>
                <div class="community-detail__manager-bio">{{ manager.bio }}</div>
              </community-detail-manager-card>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
              <div style="display: flex">
                <router-link
                    :to="`/communities/${community.id}/members`"
                    class="communities-members-link"
                    :class="{'has-action-buttons': isMember}"
                    style="text-decoration: none">
                  <div class="communities-title">
                    {{
                      $t('community_latest_members', { community: community.name })
                    }}
                  </div>
                </router-link>
                <div class="communities-title-action-item">
                  <ds-button
                      class=""
                      icon="plus-big"
                      variant="minimal"
                      v-if="isMember"
                      @click="displayAddMemberModal"
                  />
                </div>
                <div class="communities-title-action-item">
                  <ds-button
                      icon="chart-pie"
                      v-if="isMember"
                      variant="minimal"
                      @click="navigateToDynamicDashboard"
                  />
                </div>
              </div>
              <div class="community-detail__members" id="introjs-communities-detailpage"
                   :class="{'with-see-all-button': isMember}">
                <div v-if="latestActors.loading" style="margin: auto;">
                  <loading/>
                  <br>
                </div>
                <community-detail-members-card
                    :actors="latestActors.data"
                    :users="latestMembers.data"
                    v-else-if="latestActors && latestActors.data.length > 0"/>
                <div style="margin: auto;" v-else>
                  {{ $t('community_members_empty_text') }}
                </div>
              </div>

              <router-link :to="'/settings/users?community=' + community.id" v-if="isMember">
                {{ $t('communities_members_see_all') }}
              </router-link>
            </div>
          </div>

          <div v-if="areMessageBoardsEnabled && hasAccessToAnnouncementsAndEventsForCommunities" class="row">
            <!-- Announcements show card for visitors -->
            <div v-if="!isLoggedIn" class="col-xs-12 col-sm-12 col-md-6">
              <div class="communities-title">
                {{
                  $t('community_recent_announcements', { announcements: announcementsLabel })
                }}
              </div>
              <div class="community-detail__announcements">
                <div class="communities-detail__empty">
                  {{
                    $t('community_please_register_view_announcements', { announcements: announcementsLabel })
                  }}
                </div>
              </div>
            </div>
            <!-- Announcements show card for members -->
            <div v-if="isLoggedIn && (community.user_has_full_access)" class="col-xs-12 col-sm-12 col-md-6">
              <div class="communities-title with-inline-buttons">
                <router-link
                    :to="`/communities/${community.id}/announcements`"
                    v-tooltip.top="$t('tooltip_announcements_simplified')"
                    style="text-decoration: none">
                  {{
                    $t('community_recent_announcements')
                  }}
                </router-link>
                <div class="communities-title-action-item" style="padding: 0">
                  <ds-button
                      style="margin-left: 10px;"
                      icon="plus-big"
                      v-if="canCreateAnnouncement(community)"
                      v-tooltip.top="$t('announcements_create_cta', { user: this.$store.state.user.profile.name })"
                      variant="minimal"
                      @click.prevent="createAnnouncement('announcement')"
                  />
                </div>
              </div>
              <div
                  class="community-detail__announcements community-detail__announcements-empty"
                  v-if="recentAnnouncements.loading">
                <div style="margin: auto;">
                  <loading/>
                </div>
              </div>
              <div v-else-if="recentAnnouncements.data.length">
                <div>
                  <simplified-announcement-preview
                      style="margin-bottom: 0"
                      :is-vertical="true"
                      :is-simplified="true"
                      :is-container="false"
                      :is-community-detail="true"
                      :community="community"
                      :announcement="recentAnnouncements.data[0]"/>
                </div>
              </div>
              <!-- Announcements show card when empty -->
              <div v-else class="community-detail__announcements community-detail__announcements-empty">
                <div class="communities-detail__empty">
                  {{
                    $t('community_announcements_empty_text', { announcements: announcementsLabel })
                  }}
                </div>
              </div>
            </div>

            <!-- Upcoming events show card for visitors -->
            <div v-if="!isLoggedIn" class="col-xs-12 col-sm-12 col-md-6">
              <div class="communities-title with-inline-buttons">
                {{
                  $t('community_upcoming_events')
                }}
              </div>
              <div class="community-detail__announcements community-detail__announcements-empty">
                <div class="communities-detail__empty">
                  {{
                    $t('community_please_register_view_events', { events: eventsLabel })
                  }}
                </div>
              </div>
            </div>
            <!-- Upcoming events show card for members -->
            <div v-if="isLoggedIn && (community.user_has_full_access)" class="col-xs-12 col-sm-12 col-md-6">
              <div class="communities-title with-inline-buttons">
                <router-link
                    :to="`/communities/${community.id}/events`"
                    style="text-decoration: none">
                  {{
                    $t('community_upcoming_events')
                  }}
                </router-link>
                <div class="communities-title-action-item" style="padding: 0">
                  <ds-button
                      style="margin-left: 10px;"
                      icon="plus-big"
                      v-if="canCreateAnnouncement(community)"
                      v-tooltip.top="$t('announcements_create_cta', { user: this.$store.state.user.profile.name })"
                      variant="minimal"
                      @click.prevent="createAnnouncement('event')"
                  />
                </div>
              </div>
              <div class="community-detail__events" v-if="upcomingEvents.loading">
                <div style="margin: auto">
                  <loading/>
                </div>
              </div>
              <div v-else-if="upcomingEvents.data.length > 0" class="row community-detail__upcoming-events-container">
                <div
                    :class="{'col-xs-12 col-sm-12': true, 'col-md-6': upcomingEvents.data.length > 1}"
                    v-for="(event, index) in upcomingEvents.data"
                    :key="'upcoming-events' + index">
                  <div class="community-detail__upcoming-event">
                    <event-card
                        is-community-detail
                        :community="community"
                        show-image
                        :event="event"
                        class="event-simplified-image-card has-background-white"
                        :link="`/communities/${communityId}/announcements/${event.id}`"
                    />
                  </div>
                </div>
              </div>
              <div class="community-detail__events" v-else>
                <div class="communities-detail__empty">
                  {{ $t('community_events_empty_text') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="subNavSection === 'members'" :class="$route.params.id ? '' : 'scrollable'">
        <loading v-if="loadingCommunity"/>
        <community-detail-members-access-denied
            :manager="manager"
            v-if="manager && !hasAccessToMembers"/>
        <ActorsSimplified
            v-if="!$route.params.id && hasAccessToMembers"
            :card-class="'community-simplified-image-card'"
        />
        <SimplifiedActorDetail v-else-if="hasAccessToMembers"/>
      </div>

      <template v-if="subNavSection === 'announcements' && $route.params.id && $route.params.communityId">
        <announcement-detail-simplified-new/>
      </template>

      <template v-if="subNavSection === 'events' && $route.params.id && $route.params.communityId">
        <announcement-detail-simplified-new/>
      </template>

      <div v-if="subNavSection === 'knowledge-base'" class="scrollable" style="min-height: 70vh">
        <knowledge-base-simplified :enable-create="$store.getters.canUserUploadContent"/>
      </div>
      <SimplifiedFooter v-if="subNavSection === ''" style="margin-top: auto"/>
    </div>
  </template>
  <template v-else>
    <div class="page-community-detail scrollable communities apply-community-color" style="padding-top: 75px;">
      <Loading/>
    </div>
  </template>
</template>

<script lang="ts">
import BasicHero from '../../components/BasicHero/BasicHero.vue'
import ActorsSimplified from '../../pages/Simplified/ActorsSimplified.vue'
import AnnouncementsNew from '../../pages/AnnouncementsNew.vue'
import Avatar from '../../components/Avatar/Avatar.vue'
import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
import SimplifiedImageCard from '../../components/Connection/SimplifiedImageCard.vue'
import CommunityDetailMembersCard from '../../components/Communities/CommunityDetailMembersCard.vue'
import KnowledgeBaseSimplified from '../../components/Dashboard/KnowledgeBaseSimplified.vue'

import { fetchPublicNotifications } from '../../api/notifications.js'
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { getCardsPerCarouselPageBasedOnWindowWidth, getImageForAnnouncement } from '../../util/helpers.js'

import CommunityDetailManagerCard from '../../components/Communities/CommunityDetailManagerCard.vue'
import CommunitiesCard from '../../components/Communities/CommunitiesCard.vue'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../../store/modules/communities.js'
import { MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '../../store/modules/notifications.js'
import SimplifiedActorDetail from '../ActorDetail/SimplifiedActorDetail.vue'
import NewCard from '../../components/NewCard/NewCard.vue'
import CommunityDetailMembersAccessDenied from '../../components/Communities/CommunityDetailMembersAccessDenied.vue'
import AnnouncementDetailSimplifiedNew from './AnnouncementDetailSimplifiedNew.vue'
import SimplifiedAnnouncementPreview from '../../components/Simplified/SimplifiedAnnouncementPreview.vue'
import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
import EventCard from '../../components/Simplified/EventCard.vue'
import { mix } from 'polished'
import IntroJsMixin from '../../util/IntroJsMixin.js'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import MODAL_IDS from '../../constants/modal-ids.js'
import { fetchLatestActors, fetchMembers } from '@/api/communities.js'
import EventsNew from '../EventsNew.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    EventsNew,
    SimplifiedFooter,
    CommunityDetailMembersAccessDenied,
    NewCard,
    CommunitiesCard,
    CommunityDetailManagerCard,
    CommunityDetailMembersCard,
    ActorsSimplified,
    BasicHero,
    AnnouncementsNew,
    Carousel,
    Slide,
    Navigation,
    Avatar,
    Loading,
    SimplifiedImageCard,
    SimplifiedActorDetail,
    AnnouncementDetailSimplifiedNew,
    EventCard,
    SimplifiedAnnouncementPreview,
    KnowledgeBaseSimplified,
  },
  data() {
    return {
      recentAnnouncements: {
        data: [],
        isLoading: false,
      },
      upcomingEvents: {
        data: [],
        isLoading: false,
      },
      latestActors: {
        data: [],
        isLoading: false,
      },
      latestMembers: {
        data: [],
        isLoading: false,
      },
      cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
    }
  },
  computed: {
    loadingCommunity() {
      return this.$store.state.communities.detail.loading
    },
    community() {
      return this.$store.state.communities.detail.data
    },
    manager() {
      return this.community && this.community.managers && this.community.managers[0]
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    isMember() {
      return this.$store.getters.isMember
    },
    hasAccessToMembers() {
      if (!this.community) {
        return false
      }

      if (this.isMember) {
        return true
      }

      return !!this.community.user_has_members_access
    },
    areMessageBoardsEnabled() {
      return this.$store.getters.areMessageBoardsEnabled
    },
    hasAccessToAnnouncementsAndEventsForCommunities() {
      return this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
    },
    communityId() {
      return this.$route.params.communityId
    },
    subNavSection() {
      return this.$route.params.section
    },
    config() {
      return this.$store.state.config
    },
  },
  methods: {
    navigateToDynamicDashboard() {
      this.$router.push(`/settings/ecosystem-heartbeat?community=${this.communityId}`)
    },
    async loadContent() {
      if (this.community && this.community.color && !this.$store.state.communities.detail.loading) {
        this.setCommunityColor(this.community.color)
      }

      if (!this.$store.getters.hasAccessToNewCommunities) {
        await this.$router.push('/')
      }

      this.recentAnnouncements.loading = true
      this.upcomingEvents.loading = true
      this.latestActors.loading = true

      try {
        await this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITY_DETAIL, Number(this.communityId))

        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, this.community.portfolio['id'])
      } catch (exception) {
        console.log(exception)
      }

      this.fetchNotifications()
      await this.fetchLatestMembers()
    },
    canCreateAnnouncement(community) {
      return this.isLoggedIn && this.$store.getters.canCreateAnnouncement && community.user_has_full_access
    },
    displayAddMemberModal() {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        community: {
          name: this.community.name,
          id: this.communityId,
        },
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_MEMBER_TO_COMMUNITY_MODAL)
    },
    getImageForAnnouncement,
    fetchNotifications() {
      const filters = { communities: this.communityId }

      this.recentAnnouncements.loading = true

      fetchPublicNotifications(1, 0, { ...filters, is_event: false })
          .then(items => {
            this.recentAnnouncements.loading = false
            this.recentAnnouncements.data = items
          })
          .catch(error => {
            this.recentAnnouncements.loading = false
            console.log(error)
          })

      this.upcomingEvents.loading = true

      fetchPublicNotifications(2, 0, { ...filters, is_event: true, upcoming_events: true })
          .then(items => {
            this.upcomingEvents.loading = false
            this.upcomingEvents.data = items
          })
          .catch(error => {
            this.upcomingEvents.loading = false
            console.log(error)
          })
    },
    async fetchLatestMembers() {
      this.latestActors.loading = true

      fetchMembers({ communityId: this.communityId })
          .then(items => {
            this.latestMembers.loading = false
            this.latestMembers.data = items
          })
          .catch(error => {
            this.latestMembers.loading = false
            console.log(error)
          })

      return fetchLatestActors({ communityId: this.communityId })
          .then(items => {
            this.latestActors.loading = false
            this.latestActors.data = items
          })
          .catch(error => {
            this.latestActors.loading = false
            console.log(error)
          })
    },
    setCommunityColor(color) {
      const communityDark = mix(0.9, color, '#000')
      const communityDarker = mix(0.75, color, '#000')
      const communityDarkest = mix(0.5, color, '#000')
      const communityExtraDarkest = mix(0.25, color, '#000')
      const communityLight = mix(0.9 * 100 / 255, color, '#fff')
      const communityLighter = mix(0.75 * 100 / 255, color, '#fff')
      const communityLightest = mix(0.5 * 100 / 255, color, '#fff')
      const communityExtraLightest = mix(0.25 * 100 / 255, color, '#fff')
      document.documentElement.style.setProperty('--primary-community', color)
      document.documentElement.style.setProperty('--primary-community-dark', communityDark)
      document.documentElement.style.setProperty('--primary-community-darker', communityDarker)
      document.documentElement.style.setProperty('--primary-community-darkest', communityDarkest)
      document.documentElement.style.setProperty('--primary-community-extra-darkest', communityExtraDarkest)
      document.documentElement.style.setProperty('--primary-community-light', communityLight)
      document.documentElement.style.setProperty('--primary-community-lighter', communityLighter)
      document.documentElement.style.setProperty('--primary-community-lightest', communityLightest)
      document.documentElement.style.setProperty('--primary-community-extra-lightest', communityExtraLightest)
    },
    startIntroJs() {
      if (this.seenIntros.includes('communitiesDetailPage')) {
        return
      }
      const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
      if (!config || !config.communitiesDetailPage || !config.communitiesDetailPage.steps) {
        return
      }

      config.communitiesDetailPage.steps = this.getIntroJsStepsConfig(config.communitiesDetailPage.steps)
      const intro = this.introJs().setOptions(config.communitiesDetailPage)
      this.currentIntro = intro

      const componentScope = this
      intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
      intro.onafterchange(this.introJsAfterStepCallback.bind(this))
      intro.oncomplete(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })
      intro.onexit(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })

      intro.start()
    },
    createAnnouncement(type) {
      console.log(type)
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: {
          is_event: (type === 'event'),
          communities: [{
            label: this.community.name,
            value: this.community.id,
          }],
        },
      })
      if (type === 'event') {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_EVENT)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      }
    },
  },
  async mounted() {
    if (['announcements', 'events'].includes(this.subNavSection) && !this.$route.params.id && !this.hasAccessToAnnouncementsAndEventsForCommunities) {
      this.$router.push('/communities/' + this.$route.params.communityId)
    }

    this.$bus.on('invited-community-member', () => {
      this.fetchLatestMembers()
    })
    await this.loadContent()
    // this setTimeout is used because the startIntrojs loaded faster than the content on the page when we use clickAfter
    setTimeout(() => {
      this.startIntroJs()
    })

    this.$bus.on('announcementCreated', () => {
      this.fetchNotifications()
    })
  },
  beforeUnmount() {
    this.$bus.off('invited-community-member')
    this.$bus.off('announcementCreated')

    this.$store.dispatch(COMMUNITY_ACTION_TYPES.RESET_COMMUNITY_DETAIL)
    this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
    this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
    this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_DEFAULT_FILTER)
    this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_TAGS)
    this.setCommunityColor(this.$store.state.config.primaryColor)
  },
  watch: {
    '$store.state.communities.detail.data'() {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

      if (this.community && this.community.id) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, this.community.portfolio['id'])
        if (this.community.color) {
          this.setCommunityColor(this.community.color)
        }
      }
    },
    '$route.params.communityId': {
      async handler() {
        if (this.$route.params.communityId) {
          await this.loadContent()
        }
      },
      immediate: false, // calls the handler on component mount/create as well, so no need to call fn on mounted again
    },
  },
  mixins: [TranslationsMixin, IntroJsMixin]
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.page-community-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .community-detail__sub-nav {
    .active {
      text-decoration: underline;
    }
  }

  h1.h1 {
    font-size: 26px;
    text-transform: uppercase;
    font-family: $font-stack-secondary;
    font-weight: 200;
  }
}

.filters {
  overflow: hidden;
  overflow-y: auto;
  padding: 1.5rem 2rem;

  @media (max-width: $screen-md) {
    padding: 1.5rem;
  }
}

.communities-members-link {
  text-decoration: none;
  display: block;

  &.has-action-buttons {
    width: calc(100% - 36px);
  }
}

.filters-title-container {
  display: flex;
  align-items: center;
}

.communities-detail__empty {
  font-size: 14px;
  text-align: center;
  line-height: 85px;
  margin: auto;
}
</style>
