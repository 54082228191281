<template>
  <div class="page-sign-up" v-if="closeable">
    <template v-if="signUpViaEcosystemMemberPackages">
      <packages-sign-up-modal/>
    </template>
    <template v-else-if="intent == 'finaliseRegistration'">
      <finalise-sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></finalise-sign-up-modal>
    </template>
    <template v-else-if="invitationToken && invitationEmail">
      <finalise-onboarding type="accept-invite" :is-closeable="closeable"/>
    </template>
    <template v-else-if="$store.state.config.allowAdd">
      <actor-sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></actor-sign-up-modal>
    </template>
    <template v-else>
      <sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></sign-up-modal>
    </template>
  </div>
  <finalise-onboarding
    type="accept-invite"
    :is-closeable="closeable"
    v-else-if="!signUpViaEcosystemMemberPackages && intent !== 'finaliseRegistration' && invitationToken && invitationEmail"
  />

  <div class="page-sign-up page-authentication" :style="style" v-else>
    <div :style="backgroundStyle">
      <div class="home__titlebar">
        <div class="home__logo">
          <img :src="$store.state.config.logo_url">
        </div>
        <div class="home__title">
          {{ $store.state.config.title }}
        </div>
        <template v-if="ecosystemMemberPackagesEnabled">
          <packages-sign-up-modal/>
        </template>
        <template v-else-if="intent == 'finaliseRegistration'">
          <finalise-sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></finalise-sign-up-modal>
        </template>
        <template v-else-if="$store.state.config.allowAdd">
          <actor-sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></actor-sign-up-modal>
        </template>
        <template v-else>
          <sign-up-modal :is-closeable="closeable" @close="$router.push('/')"></sign-up-modal>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import SignUpModal from '../components/Modals/SignUpModal.vue'
  import ActorSignUpModal from '../components/Modals/ActorSignUpModal.vue'
  import FinaliseSignUpModal from '../components/Modals/FinaliseSignUpModal.vue'
  import PackagesSignUpModal from '../components/Modals/PackagesSignUpModal.vue'

  import { hexToRgb } from '../util/color.js'
  import FinaliseOnboarding from './Onboarding/RegistrationContainer.vue'
  import { defineComponent } from '~/vue'
  import OnboardingMixin from '@/util/OnboardingMixin'

  export default defineComponent({
    data() {
      return {
        invitationName: window.name,
        invitationToken: window.invitationToken,
        invitationEmail: window.email,
      }
    },
    mixins: [OnboardingMixin],
    computed: {
      signUpViaEcosystemMemberPackages(): boolean {
        return this.ecosystemMemberPackagesEnabled && !this.isUserSigningUpAsTeamMemberOrOwner
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      // Return the style for the sign-up page when the sign-up page is taking up the entire screen (i.e. part of OAuth or private ecosystem Log In)
      style() {
        var cover = this.$store.state.config.coverSrc ? this.$store.state.config.coverSrc : '/images/covers/skyline.jpeg'

        return 'background-image: url(' + cover + ');background-color: #F7F7F7; background-repeat: no-repeat; background-size: cover;'
      },
      // Return the overlay style with the primary colour based on the ecosystem
      backgroundStyle() {
        return 'background: rgba(' + hexToRgb(this.$store.getters.primaryColor) + ', 0.5); overflow: hidden; height: 100%; z-index: 2;'
      },
      closeable() {
        // The modal is not closeable if either the ecosystem does not allow anonymous users
        // or the login is part of an oauth request being resolved
        return this.$store.getters.hasAccessToPublisher && !!this.$store.state.config.allow_anonymous && !window.oauth_request
      },
      intent() {
        return window.intent
      },
      config() {
        return window.config
      },
      ecosystemMemberPackagesEnabled(): boolean {
        return this.$store.getters.hasAccessToEcosystemMemberPackages
      },
    },
    mounted() {
      if (this.isLoggedIn) {
        this.$router.push('/')
      }

      if (this.intent == 'finaliseRegistration') {
        this.$router.replace({ query: { email: window.email } })
      }
    },
    components: {
      SignUpModal,
      ActorSignUpModal,
      FinaliseSignUpModal,
      PackagesSignUpModal,
      FinaliseOnboarding,
    },
  })
</script>
