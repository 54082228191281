<template>
  <with-configuration-tabs>
    <div class="profile fixed-heading">
      <div class="profile-update__header">
        <div class="profile-update__header__upper-container">
          <h2 class="profile-update__header__info">
            <span
              class="profile-update__header__title"
              v-if="$route.hash === '#my-actors'"
            >{{ $t('profile_settings_company_info') }}</span>
            <span
              class="profile-update__header__title"
              v-else-if="$route.hash === '#notification-settings'"
            >{{ $t('profile_settings_notifications') }}</span>
            <span
              class="profile-update__header__title"
              v-else-if="$route.hash === '#password-and-security'"
            >{{ $t('profile_settings_password') }}</span>
            <span
              class="profile-update__header__title"
              v-else
            >{{ $t('profile_title') }}</span>
          </h2>
          <div class="profile-update__header__actions">
            <ds-button
              v-if="$route.hash === ''"
              variant="outline"
              icon="trash"
              @click="removeUserPopup"
            />
          </div>
        </div>
      </div>

      <div
        class="scrollable notification-settings-panel"
        v-if="isNotificationTab"
      >

        <p
          class="form-group__label__text"
          style="margin-bottom: 0.5rem;"
          v-if="isOwner && ! isSlackEnabled"
        >To send notifications via Slack, configure
          your webhook <a href="/settings/integrations"> here</a>.</p>

        <table class="notification-settings-panel__settings-table">
          <tbody v-for="(category, index) in displayedCategories">
          <tr>
            <th class="label-column">{{ category.label }}</th>
            <th class="website-column"><span v-if="index === 0">{{ $t('notification_settings_in_app') }}</span></th>
            <th class="email-column"><span v-if="index === 0">{{ $t('notification_settings_email') }}</span></th>
            <th
              class="slack-column"
              v-if="showSlackSettings"
            ><span v-if="index === 0">Slack</span>
            </th>
          </tr>
          <tr v-for="notification in category.notifications">
            <template v-if="user.settings.notifications[notification.name]">
              <td class="label-column">{{ notification.label }}</td>
              <td class="website-column">
                <toggle-button
                    v-model="user.settings.notifications[notification.name].database.enabled"
                    :enabled="user.settings.notifications[notification.name].database.enabled"
                    color="success"
                />
              </td>
              <td class="email-column">
                <dropdown
                    :options="emailOptions"
                    :modelValue="getEmailValue(notification.name)"
                    @update:modelValue="setEmailValue(notification.name, $event)"
                />
              </td>
              <td
                class="slack-column"
                v-if="showSlackSettings"
              >
                <toggle-button
                    v-model="config.notifications[notification.name].slack.enabled"
                    :enabled="config.notifications[notification.name].slack.enabled"
                    color="success"
                />
              </td>
            </template>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th class="label-column">{{ $t('notification_settings_weekly_digest') }}</th>
            <th class="website-column"></th>
            <th class="email-column"></th>
            <th
              class="slack-column"
              v-if="showSlackSettings"
            ></th>
          </tr>
          <tr v-if="isOwner && hasAccessToPublisher">
            <td class="label-column">{{ $t('notification_settings_curation_digest') }}</td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                :options="emailOptions"
                v-model="curationDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr>
            <td class="label-column">{{ $t('notification_settings_activities_digest') }}</td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                :options="emailOptionsOnlyWeekly"
                v-model="weeklyDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="hasAccessToMonitoring">
            <td class="label-column">{{ $t('notification_settings_articles_digest') }}</td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                :options="emailOptions"
                v-model="contentDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="config.newsletter">
            <td class="label-column">{{ $t('notification_settings_newsletter') }}</td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                :options="emailOptionsOnOff"
                v-model="userNewsletter"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div
        class="scrollable has-padding"
        v-else-if="$route.hash === '#password-and-security'"
      >
        <form
          class="profile__form"
          @submit.prevent="submit"
          style="max-width: 680px"
        >
          <form-group
            :label="$t('profile_password_current_label')"
            :errors="errors.current_password"
          >
            <ds-input
              type="password"
              v-model="user.current_password"
            />
          </form-group>

          <form-group
            :label="$t('profile_password_new_label')"
            :errors="errors.password"
          >
            <ds-input
              type="password"
              v-model="user.password"
              :noAutocomplete="true"
            />
          </form-group>

          <form-group
            :label="$t('profile_password_confirm_label')"
            :errors="errors.password_confirmation"
          >
            <ds-input
              type="password"
              v-model="user.password_confirmation"
            />
          </form-group>
        </form>
      </div>
      <div
        class="scrollable has-padding"
        v-else-if="$route.hash === '#my-actors'"
      >
        <div class="row">
          <div
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
              v-for="(actor, productIndex) in profile.claims"
          >
            <SimpleCard
                :imgUrl="actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()"
                :force-aspect-ratio="true"
                @click="handleClickActorCard(actor)"
                :title="actor.name + (actor.claim_verified ? '': '\n(claim in progress)')"
            >
              <template v-slot:actions>
                <div class="actor-card__show-invitees">
                  <DsButton
                    v-if="userIsActorOwner"
                    @click="showContributors($event, actor)"
                    variant="primary"
                    icon="user-friends"
                  :icon-fill-color="this.$store.state.config.primaryColor"/>
                </div>
              </template>
            </SimpleCard>
          </div>
        </div>
        <hover-overlay-button
          class="application-plus-btn"
          @openPanel="openCreateActorModal"
          v-if="userCanCreate && !canOnlyCreateProducts"
          :label="maybeTranslate('add_new_actor')"
          orientation="right"
        ></hover-overlay-button>
      </div>
      <div
        class="scrollable has-padding"
        v-else
      >
        <div
          class="profile-update__claimed-profile-container has-padding"
          v-if="userTeams && userTeams.length > 0"
        >
          <p>{{ $t('profile_ecosystem_selection') }}</p>
          <template v-if="userTeams && userTeams.length > 0">
            <a
              v-for="team in userTeams"
              :href="redirectLink(team.url)"
              target="_blank"
              class="profile-update__claimed-profile-card"
              :style="{ backgroundImage: 'url(' + team.logo_url + ')' }"
            >
              <div class="profile-update__claimed-profile-text">
                {{ $t('user_role.' + team.role) }}
              </div>
            </a>
          </template>
        </div>
        <form
          class="profile__form"
          @submit.prevent="submit"
          style="max-width: 680px"
        >
          <div style="clear: both">
            <form-group
              class="profile__photo-url"
              :label="$t('profile_picture_label')"
              :instructions="$t('profile_picture_format')"
              :errors="errors.photo_url"
              style="display: inline-block; float: left;"
            >
              <image-input
                v-model="user.photo_url"
                width="240"
                height="240"
              />
            </form-group>
            <div style="display: inline-block; margin-left: 1em; width: 50%; padding-top: 1em;">
              <form-group
                :label="$t('profile_name_label')"
                style="max-width: 80em"
                :errors="errors.name"
              >
                <ds-input v-model="user.name"/>
              </form-group>

              <div style="max-width: 80em">
                <form-group
                  :label="$t('profile_email_label')"
                  :errors="errors.email"
                >
                  <ds-input
                    v-model="user.email"
                    disabled
                  />
                </form-group>

                <form-group
                  :label="$t('profile_update_email_label')"
                  :errors="newEmailErrors.newEmail"
                >
                  <ds-input v-model="newEmail"/>
                  <span v-if="newEmailMessage">{{ newEmailMessage }}</span>
                </form-group>

                <ds-button
                  @click="updateEmailAddress"
                  :label="$t('profile_update_btn_email_label')"
                  :disabled="!validNewEmail"
                />
                <ds-button
                  @click="updateEmailAddress"
                  :label="$t('profile_update_btn_email_label')"
                  :disabled="!validNewEmail"
                />
              </div>
            </div>
          </div>
          <form-group
            :label="$t('terms_conditions_label')"
            style="max-width: 400px; display: block; clear: both"
          >
            <template v-if="! userAcceptedTerms">
              <input
                type="checkbox"
                @update:modelValue="acceptTerms"
                :checked="userAcceptedTerms"
                width="240"
                height="240"
                v-if="!userAcceptedTerms"
              >
              <i18n-t
                scope="global"
                keypath="terms_conditions_accept"
                tag="span"
              >
                <template v-slot:terms>
                  <a
                    :href="termsOfUseUrl"
                    target="_blank"
                  >{{ $t('terms_of_use') }}</a>
                </template>
                <template v-slot:privacy>
                  <a
                    :href="privacyPolicyUrl"
                    target="_blank"
                  >{{ $t('privacy_policy') }}</a>
                </template>
              </i18n-t>
            </template>
            <template v-else>
              <i18n-t
                scope="global"
                keypath="terms_conditions_agreed"
                tag="span"
              >
                <template v-slot:terms>
                  <a
                    :href="termsOfUseUrl"
                    target="_blank"
                  >{{ $t('terms_of_use') }}</a>
                </template>
                <template v-slot:privacy>
                  <a
                    :href="privacyPolicyUrl"
                    target="_blank"
                  >{{ $t('privacy_policy') }}</a>
                </template>
              </i18n-t>
            </template>
          </form-group>

          <form-group
            :label="$t('profile_phone')"
            :errors="errors.phone"
          >
            <ds-input v-model="user.phone"/>
          </form-group>
          <form-group
            :label="$t('profile_bio')"
            :errors="errors.bio"
          >
            <ds-textarea v-model="user.bio"/>
          </form-group>
          <form-group :label="$t('profile_permissions_label')">
            <checkbox
              :label="$t('permissions_updates_features')"
              v-model="userUpdatesAndFeatures"
            />
            <checkbox
              :label="$t('permissions_blogposts')"
              v-model="userBlogPosts"
            />
            <checkbox
              :label="$t('permissions_webinars')"
              v-model="userEventInvites"
            />
            <checkbox
              :label="$t('permissions_newsletter')"
              v-model="userDataScoutsNewsletter"
            />
            <checkbox
              :label="$t('permissions_beta_testing')"
              v-model="userBetaInvites"
            />
          </form-group>

          <form-group
            :label="$t('permissions_email_subscriptions')"
            :errors="errors.newsletter"
            v-if="$store.getters.isPublisherEnabled"
          >
            <checkbox
                :label="$t('permissions_email_subscriptions_permission', {ecosystem: ecosystemDisplayName, interpolation: { escapeValue: false }})"
                v-model="userNewsletter"
            />
          </form-group>
        </form>
        <p>{{ $t('permissions_disclaimer') }}</p>
      </div>
      <action-bar :editing="isEditing">
        <ds-button
          variant="secondary"
          label="Save changes"
          @click="submit"
        />
      </action-bar>
      <modal
        v-if="show.contributors"
        :title="`Contributors for ${showingContributorsForActor.name}`"
        @close="show.contributors = false"
        close-on-blur
        is-closeable
      >
        <template v-slot:body>
          <form @submit.prevent="inviteContributor(show.contributors.email)">
            <form-group
              label="Invite by email"
              :error="show.contributors.errors.email || show.contributors.errors.message"
            >
              <ds-input
                v-model="show.contributors.email"
                placeholder="Email address"
              />
            </form-group>
            <ds-button
              type="submit"
              variant="secondary"
              label="Invite contributor"
            />
            <br><br>
            <p v-if="show.contributors.message">
              {{ show.contributors.message }}
            </p>
            <div
              class="contributor"
              v-for="user in show.contributors.list"
            >
              <span
                class="contributor__remove"
                role="presentation"
                @click="removeContributor(user.user_id)"
              >&times;</span>
              {{ user.user_email }}
            </div>
          </form>
        </template>
      </modal>
    </div>
  </with-configuration-tabs>
</template>

<script>
import Dropdown from '../Dropdown/Dropdown.vue'
import Checkbox from '../Form/Checkbox.vue'
import ImageInput from '../Form/ImageInput.vue'

import {acceptTerms, changeEmailRequest, fetchActorProfiles, updateProfile} from '../../api/user'

import isEqual from 'lodash/isEqual'

  import MODAL_IDS from '../../constants/modal-ids'
  import {humanize} from '../../constants/properties'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui'
  import {prepareUserForEditing} from '../../util/user-settings'
  import {LOCALE_LABELS} from '../../store/modules/localization'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import {fetchAndPrepareConfigForEditing, prepareConfigForEditing, removeDefaultsFrom} from '../../util/config.js'
  import NotificationSettingConfig from '../../config/notificationSettings'
  import {updateConfig} from '../../api/config'
  import {getDefaultFallbackImageUrl, inert} from '../../util/helpers'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ToggleButton from '../Form/ToggleButton.vue'
  import ActionBar from '../Form/ActionBar.vue'
  import SimpleCard from '../SimpleCard/SimpleCard.vue'
  import {trackHeapEvent} from '../../util/analytics.js'
  import {ACTION_TYPES as ACTORS_ACTION_TYPES} from '../../store/modules/actors.js'
  import HoverOverlayButton from '../Overlay/HoverOverlayButton.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import {fetchContributors, inviteContributor, removeContributor,} from '../../api/actors.js'
  import Modal from '../Modals/Modal.vue'
  import {DEFAULT_URLS} from '../../constants/default-urls.js'
  import {SiteTemplate} from '../../store/modules/config.js'

export default {
  data() {
    return {
      errors: {},
      user: this.currentUser(),
      profiles: [],
      originalLocale: this.$store.getters.activeLocale,
      config: prepareConfigForEditing(this.$store.state.config),
      errorMessage: null,
      newEmail: null,
      newEmailMessage: null,
      newEmailErrors: {},
      show: {
        contributors: false,
      },
      showingContributorsForActor: null,
    }
  },
  mixins: [ConceptSearchMixin, TranslationsMixin],
  computed: {
    emailOptionsOnOff() {
      return [
        {label: this.$t('notification_settings_off'), value: 'off'},
        {label: this.$t('notification_settings_on'), value: 'on'}
      ]
    },
    emailOptionsOnlyWeekly() {
      return [
        {label: this.$t('notification_settings_off'), value: 'none'},
        {label: this.$t('notification_settings_weekly'), value: 'yes'}
      ]
    },
    emailOptions() {
      return [
        // Selecting "Off" will set enabled to false as well, any other sets enabled to true
        {label: this.$t('notification_settings_off'), value: 'none'},
        {label: this.$t('notification_settings_daily'), value: 'daily'},
        {label: this.$t('notification_settings_weekly'), value: 'weekly'}
      ]
    },
    isSimplifiedTemplateUsed() {
      return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
    },
    validNewEmail() {
      if (!this.newEmail || this.newEmail === this.user.email) {
        return false
      }

      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(this.newEmail).toLowerCase())
    },
    canOnlyCreateProducts() {
      return this.$store.getters.claimableActorTypes.includes('Product') && this.$store.getters.claimableActorTypes.length === 1
    },
    isNotificationTab() {
      return this.$route.hash === '#notification-settings' || !this.$route.hash
    },
    userCanCreate() {
      return this.$store.getters.userCanCreate
    },
    profile() {
      return this.$store.state.user.profile
    },
    languageOptions() {
      return this.enabledLocales.map(locale => ({
        value: locale,
        label: this.labelForLocale(locale),
      }))
    },
    enabledLocales() {
      return this.$store.state.localization.enabledLocales
    },
    ecosystemDisplayName() {
      return this.$store.getters.ecosystemDisplayName
    },
    termsOfUseUrl() {
      return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
    },
    privacyPolicyUrl() {
      return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
    },
    userAcceptedTerms() {
      return this.$store.getters.hasAcceptedTerms
    },
    isEditing() {
      return !isEqual(this.user, this.currentUser())
    },
    userTeams() {
      return this.$store.state.user.profile.teams
    },
    userReports: {
      get() {
        return !!(this.user.settings || {}).reports
      },
      set(v) {
        this.user.settings.reports = v ? 'weekly' : ''
      },
    },
    weeklyDigest: {
      get() {
        const setting = (this.user.settings || {}).weeklyDigest
        const validOptions = this.emailOptionsOnlyWeekly.map(option => option.value)
        return validOptions.includes(setting) ? setting : 'yes'
      },
      set(v) {
        this.user.settings.weeklyDigest = v
      },
    },
    userNewsletter: {
      get() {
        return !!(this.user.settings || {}).newsletter
      },
      set(v) {
        this.user.settings.newsletter = v ? 'yes' : ''
      },
    },
    userUpdatesAndFeatures: {
      get() {
        return !!(this.user.settings || {}).updatesAndFeatures
      },
      set(v) {
        this.user.settings.updatesAndFeatures = v ? 'yes' : ''
      },
    },
    userDataScoutsNewsletter: {
      get() {
        return !!(this.user.settings || {}).dataScoutsNewsletter
      },
      set(v) {
        this.user.settings.dataScoutsNewsletter = v ? 'yes' : ''
      },
    },
    userBlogPosts: {
      get() {
        return !!(this.user.settings || {}).blogPosts
      },
      set(v) {
        this.user.settings.blogPosts = v ? 'yes' : ''
      },
    },
    userEventInvites: {
      get() {
        return !!(this.user.settings || {}).eventInvites
      },
      set(v) {
        this.user.settings.eventInvites = v ? 'yes' : ''
      },
    },
    userBetaInvites: {
      get() {
        return !!(this.user.settings || {}).betaInvites
      },
      set(v) {
        this.user.settings.betaInvites = v ? 'yes' : ''
      },
    },
    hasAccessToMonitoring() {
      return this.$store.getters.hasAccessToMonitoring
    },
    hasAccessToPublisher() {
      return this.$store.getters.hasAccessToPublisher
    },
    isFollowingSearches() {
      return this.followedConceptSearchIds.length > 0
    },
    showSlackSettings() {
      return (
          this.isOwner && this.config.slack && this.config.slack.endpoint
      )
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isSlackEnabled() {
      return this.config.slack && this.config.slack && this.config.slack.endpoint
    },
    displayedCategories() {
      const userNotificationSettings = this.user.settings.notifications
      const ecosystemNotificationSettings = this.config.notifications
      const result = []

      for (const category of NotificationSettingConfig) {
        const resultCategory = {
          label: category.label === 'Announcement' ? this.announcementLabel : category.label,
          notifications: [],
        }

        for (const notification of category.notifications) {
          if (userNotificationSettings[notification.name] === undefined) {
            continue
          }

          if (this.showSlackSettings && ecosystemNotificationSettings[notification.name] === undefined) {
            continue
          }

          resultCategory.notifications.push({
            name: notification.name,
            label: notification.label.indexOf('_') >= 0 ? this.$t(notification.label) : notification.label,
          })
        }

        if (resultCategory.notifications.length > 0) {
          result.push(resultCategory)
        }
      }

      // If challenges are enabled, display the settings for challenges
      if (this.$store.getters.areChallengesEnabled) {
        const challengesNotifications = {
          label: this.challengesLabel,
          notifications: [
            {
              name: 'new_public_challenge',
              label: this.$t('notification_new_challenge')
            },
            {
              name: 'new_challenge_comment',
              label: this.$t('notification_new_comment', {announcements: this.challengesLabel}),
            },
            {
              name: 'challenge_pinned',
              label: this.$t('notification_challenge_pinned', {challenge: this.challengeLabel})
            },
            {
              name: 'challenge_mentioned_actor',
              label: this.$t('notification_challenge_mentioned')
            }
          ]
        }

        result.push(challengesNotifications)
      }

      return result
    },
    curationDigest: {
      get() {
        const setting = (this.user.settings || {}).curationDigest

        return !setting ? 'daily' : setting
      },
      set(v) {
        this.user.settings.curationDigest = v
      },
    },
    contentDigest: {
      get() {
        const setting = (this.user.settings || {}).contentDigest
        return setting || 'none'
      },
      set(v) {
        if (this.contentDigest === 'none' && v !== 'none' && !this.isFollowingSearches) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTENT_DIGEST_INFO)
        }

        this.user.settings.contentDigest = v
      }
    },
  },
  methods: {
    getDefaultFallbackImageUrl,
    userIsActorOwner(actor) {
      if (this.$store.getters.isActor) {
        const claim = this.$store.state.user.profile.claims.find(claim => claim.id === actor.id) || {}
        return claim.claim_type === 'actor-owner' && claim.claim_verified
      }
      return false
    },
    removeContributor(userId) {
      removeContributor({userId, actorId: this.showingContributorsForActor.id})
          .then(data => {
            Object.assign(this.show.contributors, {errors: {}, message: null}, data)
            this.showContributors()
          })
          .catch(this.handleContributorError)
    },
    inviteContributor(email) {
      inviteContributor({email, actor_id: this.showingContributorsForActor.id})
          .then(data => {
            Object.assign(this.show.contributors, {errors: {}, message: null, email: ''}, data)
            this.showContributors()
          })
          .catch(this.handleContributorError)
    },
    showContributors($event, actor) {
      if ($event) {
        $event.stopPropagation()
      }

      if (actor) {
        this.showingContributorsForActor = actor
      }

      if (!this.showingContributorsForActor) {
        return
      }

      if (!this.show.contributors) {
        this.show.contributors = {email: '', list: [], errors: {}, message: null}
      }

      fetchContributors(this.showingContributorsForActor.id, {})
          .then(list => {
            Object.assign(this.show.contributors, {list})
          })
          .catch(this.handleContributorError)
    },
    handleContributorError(errors) {
      if (errors && errors.message === 'Unexpected error') {
        errors.message = 'You can only add contributors to published profiles.'
      }
    },
    openCreateActorModal() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
    },
    handleClickActorCard(actor) {
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, actor)
      trackHeapEvent('userProfile.claims.clickActorCard')
      // this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: { actorId: actor.id, isPreview: true } })
      window.location.href = '/actors/' + actor.id
    },
    humanize,
    labelForLocale(locale) {
      return LOCALE_LABELS[locale]
    },
    acceptTerms() {
      acceptTerms().then(response => {
        this.$store.commit('USER/UPDATE_PROFILE', {accepted_terms: response.accepted_terms})
      })
          .catch(error => {
          })
    },
    removeUserPopup() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.USER_REMOVE)
    },
    currentUser() {
      const profile = this.$store.state.user.profile
      return prepareUserForEditing(profile)
    },
    updateEmailAddress() {
      this.newEmailErrors = {}
      this.newEmailMessage = ''

      changeEmailRequest(this.newEmail)
          .then(response => {
            this.newEmailMessage = 'An email has been sent to ' + this.newEmail + ' in order to complete the update.'
            this.newEmail = ''
          })
          .catch(errors => {
            this.newEmailErrors = errors
          })
    },
    async submit() {
      await this.saveNotificationChanges()
    },
    redirectLink(url) {
      var link = ''
      // If the user has a claimed actor, the user is redirected to the actors page
      // Else the user is redirected to the ecosystem home page
      if (this.profiles.length > 0) {
        this.profiles.forEach(profile => {
          if (profile.url == url) {
            link = profile.url + '/actors/' + profile.id
          } else {
            link = url
          }
        })
      } else {
        link = url
      }
      return link
    },
    hidePanel() {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    getEmailValue(notificationName) {
      return this.user.settings.notifications[notificationName].email.digest
    },
    setEmailValue(notificationName, value) {
      this.user.settings.notifications[notificationName].email.enabled = (value !== 'none')
      this.user.settings.notifications[notificationName].email.digest = value
    },
    async saveNotificationChanges() {
      const promises = []

      // Apply changes to user settings
      promises.push(
          updateProfile(this.user)
              .then(data => {
                this.errors = {}
                this.errorMessage = null
                this.$store.commit('USER/UPDATE_PROFILE', data)
                this.user = this.currentUser()
              })
      )

      if (this.showSlackSettings) {
        // Apply changes to ecosystem config
        const formData = removeDefaultsFrom(this.config, {})
        delete formData.possibleCategories
        delete formData.categories
        delete formData.hexBorders
        delete formData.colors

        promises.push(
            updateConfig(formData)
                .then(config => {
                  this.errorMessage = null
                  this.$store.commit('CONFIG_SET', inert(config))
                  this.config = config
                })
        )
      }

      try {
        await Promise.all(promises)
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    fetchActorProfiles()
        .then(profiles => {
          this.profiles = profiles
        })
        .catch()
    if (this.$store.getters.isMember) {
      fetchAndPrepareConfigForEditing().then(config => {
        this.config = config
      })
    }
  },
  components: {
    WithConfigurationTabs,
    Checkbox,
    ImageInput,
    Dropdown,
    DsTextarea,
    ToggleButton,
    ActionBar,
    SimpleCard,
    HoverOverlayButton,
    Modal,
  },
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.profile-update {

}

.profile__photo-url {
  max-width: 240px;
}

.profile-update__header {
  border-bottom: 1px solid $color-borders;
  padding: 20px;
}

.profile-update__header__upper-container {
  display: flex;
  align-items: center;
}

.profile-update__header__info {
  flex: 1;
  display: flex;
  align-items: center;
}

.profile-update__header__title {
  text-transform: uppercase;
  font-family: $font-stack-secondary;
  letter-spacing: 1.7px;
  font-size: 24px;
  font-weight: normal;
  margin-right: 10px;
}

.profile-update__header__actions {
  display: flex;
  position: absolute;
  right: 15px;
}

.subscriptions__divider {
  font-family: $font-stack-primary;
  font-size: 12px;
  font-weight: normal;
}

.profile-update__claimed-profile-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-family: $font-stack-primary;

  p {
    font-size: 12px;
    font-weight: normal;
    color: #555;
  }

  .profile-update__claimed-profile-card {
    display: inline-block;
    height: 100px;
    width: 230px;
    background-color: $color-background-light-grey;
    text-decoration: none;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center 30%;
    margin-right: 20px;
    margin-bottom: 20px;

    .profile-update__claimed-profile-text {
      text-align: center;
      text-transform: capitalize;
      margin: auto;
      margin-top: 75px;
      font-size: 12px;
      font-weight: normal;
      color: #555;
      padding: 2px;
      border-radius: 3px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      background-color: rgba(255, 255, 255, 0.5)
    }
  }
}

.notification-settings-panel, .profile-settings-panel {
  padding: 20px 20px 60px 20px;
}

.actor-card__show-invitees {
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
