<template>
  <div
    class="navigation__wrapper" :class="{'navigation__wrapper--hover': hoveringOverNav}" @click="handleNavClick"
    @mouseleave="handleNavOut"
  >
    <nav
      class="navigation navigation--stretch navigation-panel-left"
      :class="[{'navigation-panel-scroll' : hasScroll }, 'navigation-panel-small-icons']"
    >
      <ul class="navigation__links" @mouseenter="handleNavOver">
        <navigation-item
          to="/dashboards/home"
          icon="home"
          label="Home"
          orientation="left"
          :disable-tooltip="true"
        />
        <li class="navigation__divider" v-if="displayAnnouncements || displayChallenges || displayCommunities"></li>
        <navigation-item
          v-if="displayAnnouncements"
          :to="announcementsUrl"
          id="introjs-message-board"
          icon="bullhorn"
          icon-size="22"
          label="Message Board"
          :title="getTooltipFor('Message Board')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayEvents"
          to="/events"
          icon="calendar-plus"
          label="Events"
          :title="getTooltipFor('Message board')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayChallenges"
          to="/challenges"
          icon="comment"
          :label="challengesLabel"
          :title="getTooltipFor('Challenges')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayCommunities && communities.length > 0"
          to="/communities"
          icon="users"
          :label="$t('communities_generic')"
          orientation="left"
          :disable-tooltip="true"
        />
        <li class="navigation__divider"></li>
        <navigation-item
          v-if="configuredViews.includes(MODES.LIST)"
          id="introjs-list-view"
          :to="'/actors/?actor_type='+ activeActorType"
          :class="[ rightPanelMode === MODES.LIST && currentRoute.name === 'explore' ? 'navigation-active-view-state' :  'navigation-inactive-view-state' ]"
          icon="view-list"
          label="List View"
          orientation="left"
          :disable-tooltip="true"
          @click="handleChangeRightPanel(MODES.LIST)"
        />
        <navigation-item
          v-if="configuredViews.includes(MODES.GALLERY)"
          id="introjs-gallery-view"
          :to="'/actors/?actor_type=' + activeActorType"
          :class="[ rightPanelMode === MODES.GALLERY && currentRoute.name === 'explore' ? 'navigation-active-view-state' : 'navigation-inactive-view-state' ]"
          icon="view-gallery"
          label="Gallery View"
          orientation="left"
          :disable-tooltip="true"
          @click="handleChangeRightPanel(MODES.GALLERY)"
        />
        <navigation-item
          v-if="configuredViews.includes(MODES.MAP)"
          id="introjs-map-view"
          :to="'/actors/?actor_type='+ activeActorType"
          :class="[ viewDirectory.indexOf(MODES.MAP) > -1 && currentRoute.name === 'explore' ? 'navigation-active-view-state' :  'navigation-inactive-view-state']"
          icon="view-map"
          label="Map View"
          orientation="left"
          :disable-tooltip="true"
          @click="handleChangeLeftPanel(MODES.MAP)"
        />
        <navigation-item
          v-if="viewActorTypes.includes('Product')"
          :to="'/product-gallery'"
          icon="icons"
          :label="productLabel + ' Gallery'"
          orientation="left"
          :disable-tooltip="true"
        />
        <li
          class="navigation__divider"
          v-if="displayExploreContent || displayNewsOverview || displayKnowledgeBase || displayInstantMonitoring || displayActorScouting"
        ></li>
        <navigation-item
          v-if="displayExploreContent && !displayActorScouting"
          to="/dashboards/explore-content"
          icon="tag"
          :label="isExplorationOnly ? 'Scouting' : 'Exploration'"
          :title="getTooltipFor('Trend Monitor')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayActorScouting"
          to="/dashboards/actor-scouting"
          icon="house-radar"
          :label="'Actor Scouting'"
          :title="getTooltipFor('Actor Scouting')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayNewsOverview"
          to="/dashboards/news-overview"
          icon="tag"
          label="News Overview"
          :title="getTooltipFor('News Overview')"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayInstantMonitoring"
          to="/dashboards/instant-monitoring"
          icon="tag"
          label="Instant Monitoring"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayKnowledgeBase && !displayActorScouting"
          to="/dashboards/knowledge-base"
          icon="repository-icon"
          label="Knowledge Base"
          :title="getTooltipFor('Knowledge Base')"
          orientation="left"
          :disable-tooltip="true"
        />
        <li class="navigation__divider" v-if="hasAccessToSpottingAreas || displayPortfolios"></li>
        <navigation-item
          v-if="hasAccessToSpottingAreas && spottingAreas.length > 0 "
          to="/spotting-areas"
          icon="microscope"
          :label="spottingAreasLabel"
          orientation="left"
          :disable-tooltip="true"
        />
        <navigation-item
          v-if="displayPortfolios"
          id="introjs-portfolio-view"
          to="/portfolios"
          icon="portfolios"
          label="Portfolios"
          :title="getTooltipFor('portfolios')"
          orientation="left"
          :disable-tooltip="true"
        />
        <template v-if="dashboards.length > 1">
          <li class="navigation__divider"></li>
          <navigation-item
            v-for="(item, index) in analyticsArray"
            :key="`analyticsArray-${index}`"
            :to="'/dashboards/' + item.value"
            :id="`introjs-analytics-${item.value}`"
            :icon="item.icon"
            :label="item.label"
            :title="getTooltipFor(item.value)"
            orientation="left"
            :disable-tooltip="true"
            @click="buzzExpand(item.value)"
          />
          <li v-if="socialArray.length > 1" class="navigation__divider"></li>
          <navigation-item
            v-for="(item, index) in socialArray"
            :key="`socialArray-${index}`"
            :to="'/dashboards/' + item.value"
            :id="`introjs-analytics-${item.value}`"
            :icon="item.icon"
            :label="item.label"
            :title="getTooltipFor(item.value)"
            orientation="left"
            :disable-tooltip="true"
            @click="buzzExpand(item.value)"
          />
          <li v-if="scoresArray.length > 1" class="navigation__divider"></li>
          <navigation-item
            v-for="(item, index) in scoresArray"
            :key="`scoresArray-${index}`"
            :to="'/dashboards/' + item.value"
            :id="`introjs-analytics-${item.value}`"
            :icon="item.icon"
            :label="item.label"
            :title="getTooltipFor(item.value)"
            orientation="left"
            :disable-tooltip="true"
          />
          <li v-if="marketArray.length > 1" class="navigation__divider"></li>
          <navigation-item
            v-for="(item, index) in marketArray"
            :key="`marketArray-${index}`"
            :to="'/dashboards/' + item.value"
            :id="`introjs-analytics-${item.value}`"
            :icon="item.icon"
            :label="item.label"
            :title="getTooltipFor(item.value)"
            orientation="left"
            :disable-tooltip="true"
          />
        </template>
        <template v-if="$store.getters.isPublisherEnabled">
          <li class="navigation__divider"></li>
          <navigation-item
            to="/info"
            icon="information-outline"
            label="Information"
            :title="getTooltipFor('info')"
            orientation="left"
            :disable-tooltip="true"
          />
        </template>
      </ul>
    </nav>
  </div>
</template>

<script>
  import _intersection from 'lodash/intersection'

  import NavigationItem from './NavigationItem.vue'

  import { viewDashboardOptions } from '../../constants/config'
  import MODAL_IDS from '../../constants/modal-ids'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'

  import { inert, isIE11 } from '../../util/helpers'
  import UiMixin from '../../util/UiMixin'

  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import NavigationMixin from '../../util/NavigationMixin.js'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '../../store/modules/spottingareas.js'

  const MODES = {
    LIST: 'LIST',
    GALLERY: 'GALLERY',
    MAP: 'MAP',
    GRAPH: 'GRAPH',
  }

  const MAX_WINDOW_WIDTH_FOR_DOUBLE_PANEL_LAYOUT = 1040
  const HOVERING_OVER_NAV_DELAY = 300

  export default {
    data () {
      return {
        hoveringOverNav: false,
        hasScroll: false,
        analyticsArray: [],
        scoresArray: [],
        marketArray: [],
        socialArray: [],
        previousRoute: this.$route.name,
        show: {
          columns: false,
          double: this.doublePanelCheck(),
        },
        MODES,
      }
    },
    computed: {
      isCollapsed () {
        return this.$root.collapsable.nav
      },
      leftPanelMode () {
        return this.enabledViews.includes(MODES.MAP) && !this.isIE11() ? MODES.MAP : false
      },
      rightPanelMode () {
        if (!this.show.double && this.leftPanelMode) {
          return false
        }
        return this.enabledViews.includes(MODES.LIST) ? MODES.LIST : this.enabledViews.includes(MODES.GALLERY) ? MODES.GALLERY : false
      },
      enabledViews () {
        let enabled = _intersection(this.viewDirectory, this.configuredViews)

        if (this.isIE11()) {
          enabled = enabled.filter(view => view !== 'MAP')
        }

        return enabled.length ? enabled : [MODES.LIST]
      },
      viewDirectory: {
        get () {
          let views = (this.$store.getters.userSettings.viewDirectory || '')
            .split(',')
            .filter(Boolean)

          if (this.isIE11()) {
            views = views.filter(view => view !== 'MAP')
          }

          return views.length ? views : this.configuredViews
        },
        set (v) {
          this.$store.commit('USER/STORE_SETTINGS', { viewDirectory: v.join(',') || MODES.LIST })
        },
      },
      configuredViews () {
        let views = [
          this.config.viewGallery && MODES.GALLERY,
          this.config.viewMap && MODES.MAP,
          this.config.viewList && MODES.LIST,
        ]

        if (this.isIE11()) {
          views = views.filter(view => view !== 'MAP')
        }
        return views
      },
      dashboards () {
        // empty the arrays before filling them
        this.analyticsArray = []
        this.scoresArray = []
        this.marketArray = []
        this.socialArray = []

        const dbOptions = viewDashboardOptions

        const selected = this.config.viewDashboard || []
        const selectedDashboards = dbOptions.filter(opt => selected.includes(opt.value))

        // Group the selected dashboards
        selectedDashboards.map(val => {
          switch (val.value) {
            case 'analytics':
              if (!this.isPortfolioMember) {
                this.analyticsArray.push(val)
              }
              break
            case 'funding-list':
            case 'connectedness':
              this.analyticsArray.push(val)
              break
            case 'dynamic-dashboard':
              if (this.isOwner) {
                this.analyticsArray.push(val)
              }
              break
            case 'media-buzz':
              this.socialArray.push(val)
              break
            case 'buzz':
              if (this.isBuzzEnabled) {
                // this.socialArray.push(val)
              }
              break
            case 'scores-list':
            case 'digital-footprint':
            case 'business-size':
              if (this.$store.getters.hasAccessToScores) {
                this.scoresArray.push(val)
              }
              break
            case 'country':
            case 'sankey':
            case 'competitive-overview':
            case 'compare':
            case 'funding':
              this.marketArray.push(val)
              break
          }
        })

        // Count the number of buttons visible in the side navbar to see if we need to enable the scroll on the navbar
        let buttonCounter = Number(this.marketArray.length) + Number(this.scoresArray.length) + Number(this.analyticsArray.length)

        selected.find(dashboard => {
          if (dashboard === 'home') {
            buttonCounter += 1
          }
        })

        // in case of being a public ecosystem, to add the info button to the counter
        if (this.$store.getters.isPublic) {
          buttonCounter += 1
        }

        if (this.directories.length > 0) {
          buttonCounter += this.directories.length
        } else {
          buttonCounter += 1
        }

        this.hasScroll = buttonCounter >= 19
        /* if (buttonCounter.length >= 19) {
          this.hasScroll = true
        } else {
          this.hasScroll = false
        } */
        return viewDashboardOptions.filter(opt => selected.includes(opt.value))
      },
    },
    methods: {
      isIE11,
      async fetchSpottingAreas () {
        if (this.spottingAreas.length === 0) {
          await this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
        }
      },
      getData () {
        // This is temporary
        const listFilters = inert(this.$store.getters.listFilterObject)
        /* if (listFilters.portfolio && listFilters.actor_type === ('Person'|| 'Community')) {
          delete listFilters.portfolio
        } */

        if (this.leftPanelMode === MODES.MAP) {
          const filters = this.$store.getters.mapFilterObject
          if (filters.tl) {
            this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
          }
        } else if (this.leftPanelMode === MODES.GRAPH) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_GRAPH, this.$store.getters.baseFilterObject)
        }

        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)
        // This is temporary as later on the portfolio filters will include People and Communities
        // this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, this.$store.getters.listFilterObject)
      },
      getMapData () {
        const filters = this.$store.getters.mapFilterObject
        if (filters.tl) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
        }
      },
      handleChangeLeftPanel (panelMode) {
        this.show.double = this.doublePanelCheck()

        if (this.previousRoute !== this.$route.name) {
          // Prevent the first click to both go to the explore and toggle the panel
          this.previousRoute = this.$route.name
          return
        }

        // If the same mode is clicked and there is a right panel, we can safely hide the left panel
        if (this.viewDirectory.includes(panelMode)) {
          const removed = this.viewDirectory.filter(v => v !== panelMode)
          if (removed.length) {
            this.viewDirectory = removed
          }
        } else {
          this.viewDirectory = this.viewDirectory.concat(panelMode)
        }

        this.getData()
      },
      handleChangeRightPanel (panelMode) {
        if (this.previousRoute !== this.$route.name) {
          // Prevent the first click to both go to the explore and toggle the panel
          this.previousRoute = this.$route.name
          return
        }

        if (this.rightPanelMode !== panelMode) {
          this.viewDirectory = this.viewDirectory.filter(v => v !== this.rightPanelMode).concat(panelMode)
        } else if (this.viewDirectory.includes(panelMode) && (this.leftPanelMode || !this.show.double)) {
          this.viewDirectory = this.viewDirectory.filter(v => v !== panelMode)
        }

        this.getData()
        if (this.$options && this.$options.parent && this.$options.parent.$refs && this.$options.parent.$refs.view && this.$options.parent.$refs.view.$refs.mapPane) {
          this.$options.parent.$refs.view.$refs.mapPane.invalidateSize()
        }
      },
      doublePanelCheck () {
        return window.innerWidth > MAX_WINDOW_WIDTH_FOR_DOUBLE_PANEL_LAYOUT
      },
      getTooltipFor (value) {
        return ((this.config.navigationItems || []).find(n => n.value === value) || {}).tooltip
      },
      setActorType (values) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'actor_type', values })
      },
      buzzExpand (item) {
        // This is only to toggle the expand state of the charts in the buzz dashborad.
        if (item === 'buzz' && this.currentRoute.params.panel === item) {
          this.$bus.emit('chartExpanded', false)
        }
      },
      handleNavClick () {
        this.clearHoveringOverNav()
      },
      handleNavOver () {
        this.setHoveringOverNav()
      },
      handleNavOut () {
        this.clearHoveringOverNav()
      },
      setHoveringOverNav () {
        if (this.$options.navHoverTimeout === null) {
          this.$options.navHoverTimeout = setTimeout(() => {
            this.hoveringOverNav = true
          }, HOVERING_OVER_NAV_DELAY)
        }
      },
      clearHoveringOverNav () {
        if (this.$options.navHoverTimeout !== null) {
          clearTimeout(this.$options.navHoverTimeout)
          this.$options.navHoverTimeout = null
        }

        this.hoveringOverNav = false
      },
    },
    navHoverTimeout: null,
    created () {
      this.getData()
    },
    mounted () {
      this.$bus.on('resize300', () => {
        this.show.double = this.doublePanelCheck()
      })

      /*if (this.$store.getters.isLoggedIn) {
        this.fetchSpottingAreas()
      }*/ // This happens on initial load in app.vue
    },
    watch: {
      currentRoute (val, oldVal) {
        this.previousRoute = oldVal.name
      },
    },
    components: {
      NavigationItem,
    },
    mixins: [UiMixin, TranslationsMixin, NavigationMixin],
  }
</script>

<style lang="scss">
  @media (max-width: 768px) {
    .navigation__wrapper {
      display: none;
    }
  }
</style>
