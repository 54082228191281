<template>
  <component :is="panel"/>
</template>

<script>
import Ecosystems from '../components/Profile/Ecosystems.vue'
import Invite from '../components/Profile/Invite.vue'
import Update from '../components/Profile/Update.vue'
import ConversationDetail from '../components/Profile/ConversationDetail.vue'
import Conversations from '../components/Profile/Conversations.vue'
import PersonalSubscription from '../components/Profile/PersonalSubscription.vue'
import UpdateNew from '../components/Profile/UpdateNew.vue'

export default {
  name: 'profile',
  computed: {
    panel () {
      if (this.$route.params.panel === 'subscription') {
        return 'PersonalSubscription'
      }

      if (this.$route.params.panel === 'conversations') {
        // Show the detail, even if no ID is given. If there's no ID it will display the first conversation or display an empty state message
        return 'ConversationDetail'
      }

      if (this.$route.params.panel === 'update' && this.$route.path.indexOf('old') < 0) {
        return 'UpdateNew'
      }

      return this.$route.params.panel || 'update'
    }
  },
  components: {
    Ecosystems,
    Invite,
    Update,
    UpdateNew,
    ConversationDetail,
    PersonalSubscription,
    Conversations,
  }
}
</script>
