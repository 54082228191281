export const MATOMO_EVENT_CATEGORIES = {
  GLOBAL_SEARCH: 'GlobalSearch',
  NO_PERMISSIONS: 'No Permissions',
  ACTOR_LETS_CHAT: 'ActorLetsChat',
  ACTOR_GET_IN_TOUCH: 'ActorGetInTouch',
  ACTOR_SEND_EMAIL: 'ActorSendEmail',
  ACTOR_PHONE_NUMBER: 'ActorPhoneNumber',
  INTERACTIONS: 'Interactions',
  ACTOR_CLAIM: 'Actor claim',
  ANNOUNCEMENT_COMMENT: 'Announcement comment',
  ANNOUNCEMENT_LIKE: 'Announcement like',
  ANNOUNCEMENT_PIN: 'Announcement pin',
  HIGHLIGHTED_SPOTTING_AREA: 'Highlighted Spotting Area',
  ONBOARDING: 'Onboarding',
}

export const MATOMO_EVENT_ACTIONS = {
  CLICK: 'Click',
  OPEN_CONVERSATION: 'Open Conversation',
  ACTOR_VISIT: 'Actor Visit',
  KEYWORDS: 'Keywords',
  BUTTON_CLICK: 'Button Click',
  REMOVE_PORTFOLIO_BUTTON: 'Remove Portfolio Button',
  REMOVE_PORTFOLIO_BUTTON_SIDEPANEL: 'Remove Portfolio Button Sidepanel',
  EXPORT_ACTORS: 'Export actors',
  EXPORT_FINANCIALS: 'Export financials',
  COMMENT: 'Comment',
  LIKE: 'Like',
  PIN: 'Pin',
  CLAIM: 'Claim',
  TOP_BAR_BUTTON: 'Top Bar Button',
  HOMEPAGE_BUTTON: 'Homepage Button',
  ADVANCED_ACTOR_DETAIL_PAGE: 'Advanced Actor Detail Page',
  SIMPLIFIED_ACTOR_DETAIL_PAGE: 'Simplified Actor Detail Page',
  SPOTTING_AREA_SIMPLIFIED_ACTOR_DETAIL_PAGE: 'Spotting Area Simplified Actor Detail Page',
  ONBOARDING_STEPS_NEXT: 'Onboarding Steps next',
  ONBOARDING_STEPS_SKIP: 'Onboarding Steps skip',
  ONBOARDING_STEPS_SOCIAL_LOGIN: 'Onboarding Steps social login',
}

export const MATOMO_EVENT_NAMES = {
  LOGGED_IN: 'Logged In',
  REGISTER: 'Register',
  OPEN_REGISTER_MODAL: 'Open Register Modal',
  GET_IN_TOUCH_ON_LOGIN: 'Get in touch on login',
  SIGN_UP_FOR_EVENT: 'Sign up for event',
  SPOTTING_AREA_CTA_LOGIN: 'Spotting Area CTA: login required',
  SPOTTING_AREA_CTA_UPGRADE: 'Spotting Area CTA: upgrade package',
}
