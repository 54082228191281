<template>
  <div>
    <h1 class="h1">
      {{ isRegistrationFlow ? onboardingTextConfig.onboardingTitle : onboardingTextConfig.onboardingQuestion7 }}</h1>
    <h4 class="h4">
      {{ isRegistrationFlow ? onboardingTextConfig.onboardingSubtitle : onboardingTextConfig.onboardingQuestion8 }}</h4>
    <p v-if="allErrors.message" class="form-group__error" v-html="allErrors.message"></p>
    <form @submit.prevent="submit" :key="componentKey">
      <form-group label="hidden" :error="allErrors.name" is-simplified v-if="isRegistrationFlow">
        <ds-input
          v-model="name"
          class="onboarding-name"
          is-simplified
          :placeholder="$t('onboarding_full_name')"
        />
      </form-group>
      <form-group :error="allErrors.password" is-simplified label="hidden">
        <ds-input
          v-model="password" :placeholder="$t('onboarding_password')" type="password" is-onboarding-simplified
          icon="key"
        />
        <div
          class="pw-strength" :class="'pw-strength' + passwordScore"
          style="margin-top: -6px; width: 60%"
        >
          <div class="pw-strength-line"></div>
        </div>
      </form-group>
      <form-group label="hidden" is-simplified>
        <ds-input
          v-model="passwordConfirmation" type="password" is-onboarding-simplified icon="key"
          :placeholder="$t('onboarding_confirm_password')"
        />
      </form-group>
      <form-group :error="allErrors.accepted_terms" label="hidden" is-simplified>
        <div class="checkbox-input">
          <checkbox type="checkbox" v-model="acceptedTerms" id="accepted-terms" is-simplified/>
          <label for="accepted-terms">
        <span>&nbsp;{{ $t('signup_terms_agree') }}
          <a :href="termsOfUseUrl" target="_blank">
            {{ $t('signup_terms_link_text') }}
          </a> {{ $t('and') }}
          <a :href="privacyPolicyUrl" target="_blank">
            {{ $t('privacy_policy') }}
          </a>
        </span>
          </label>
        </div>

        <p>{{ $t('onboarding_service_notifications') }}</p>
        <br>
        <br>
      </form-group>
      <form-group :error="allErrors.register" label="hidden" is-simplified>
        <ds-button variant="rounded" :label="$t('actor_detail_next')" type="submit"/>
      </form-group>
    </form>
    <div class="onboarding-invited-by" v-if="invitedBy">
      {{ $t('onboarding_invited_by') }} {{ invitedBy }}
    </div>
  </div>
</template>
<script lang="ts">
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { loadChunk } from '../../util/chunk-loader.js'
  import { fetchProfile, loginRequest, registerRequest } from '../../api/user.js'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import _isEmpty from 'lodash/isEmpty.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import { ACTION_TYPES as LOCALIZATION_ACTION_TYPES } from '../../store/modules/localization.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import { defineComponent } from '~/vue'


  export default defineComponent({
    props: {
      type: {
        type: String,
      },
      selectedPackage: Object,
      paymentMethodId: String,
      useTrial: Boolean,
      couponCode: String,
      billingInfo: Object,
      registrationErrors: Object,
    },
    emits: ['updateRegistrationData', 'next', 'setRegistrationErrors'],
    data() {
      return {
        componentKey: 0,
        name: '',
        invitationToken: '',
        passwordScore: 0,
        passwordFeedback: [],
        errors: {},
        password: '',
        acceptedTerms: false,
        passwordConfirmation: '',
        invitedBy: window.invited_by,
      }
    },
    computed: {
      allErrors() {
        return { ...this.errors, ...this.registrationErrors }
      },
      isRegistrationFlow() {
        return !!this.invitationToken
      },
      isPublic() {
        return this.$store.getters.isPublic
      },
      termsOfUseUrl() {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl() {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      hasUserAnInvitation(): boolean {
        console.log(this.$route.query)
        return !!this.$route?.query.invitation
      },
    },
    mounted() {
      this.name = window.name
      this.invitationToken = window.invitationToken
    },
    components: {
      Checkbox,
    },
    methods: {
      submit() {
        this.performValidation()

        if (!_isEmpty(this.errors)) {
          if (this.isRegistrationFlow) {
            trackHeapEvent('onboarding-private-registration-password-invalid-form')
          } else {
            trackHeapEvent('onboarding-public-registration-password-invalid-form')
          }
          return
        }

        const data = {
          name: this.name,
          email: window.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          invitationToken: this.invitationToken,
          accepted_terms: this.acceptedTerms,
          locale: this.$store.getters.activeLocale,
          includeCompany: false, // if the request happens here, there's no company
          fullRedirectUri: this.fullRedirectUri,
        }

        if (this.type === 'packages-sign-up') {
          data.chosenPackage = this.selectedPackage.id
          data.paymentMethodId = this.paymentMethodId
          data.useTrial = this.useTrial
          data.promoCode = this.couponCode
          if (this.billingInfo) {
            data.billingInfo = this.billingInfo
          }
        }

        if (this.shouldShowActorSignUp) {
          // Don't register yet, show the next step instead
          this.$emit('updateRegistrationData', data)

          return
        }

        registerRequest(data)
          .then(data => {
            if (data.success) {
              this.errors = {}

              if (data.invitationCompleted) {
                this.invitationCompleted = data.invitationCompleted
              }

              // Only users completing the registration via an invitation can log in immediately
              if (!!this.invitationCompleted) {
                loginRequest({
                  email: window.email,
                  password: this.password,
                  remember: true,
                  locale: this.$store.getters.activeLocale,
                })
                  .then(data => {
                    if (data && data.id) {
                      this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, data)
                      this.$store.dispatch(LOCALIZATION_ACTION_TYPES.UPDATE_ACTIVE_LOCALE, { locale: this.$store.getters.activeLocale, i18n: this.$i18n })
                      this.$emit('next')
                    } else {
                      if (data.message) {
                        this.errors.register = data.message
                      } else {
                        this.errors.register = 'Your registration token is invalid'
                      }
                    }
                  })
                  .catch(err => {
                    console.log('login failed', err)

                    if (this.isRegistrationFlow) {
                      trackHeapEvent('onboarding-private-registration-password-failure')
                    } else {
                      trackHeapEvent('onboarding-public-registration-password-failure')
                    }
                    this.errors.register = 'Your registration token is invalid'
                  })
              } else {
                this.$emit('next')
              }

              trackHeapEvent('signUp', {
                name: this.name,
                email: window.email,
              })
            }
          })
          .catch(err => {
            this.componentKey++

            if (this.isRegistrationFlow) {
              trackHeapEvent('onboarding-private-registration-password-failure')
            } else {
              trackHeapEvent('onboarding-public-registration-password-failure')
            }
            if (err.email) {
              this.errors.message = err.email[0]

              // check if there is a redirect_uri and add it to the redirect link
              setTimeout(() => {
                if (document.getElementById('login-error__redirect') && this.$route.query.redirect_uri) {

                  // Construct the URL with encoded parameters
                  const newHref = `/login?redirect_uri=${this.$route.query.redirect_uri}`;

                  // Set the new href attribute
                  document.getElementById('login-error__redirect').setAttribute('href', newHref);
                }

              }, 10)
            }

            if (err.accepted_terms) {
              this.errors.accepted_terms = [err.accepted_terms]
            }

            if (err.message || typeof err === 'string') {
              this.errors.register = err.message
            }

            if (_isEmpty(this.errors)) {
              this.errors.register = 'Your registration token has expired'
            }
          })
      },
      performValidation() {
        this.errors = {}
        this.$emit('setRegistrationErrors', {})
        if (!this.name) {
          this.errors.name = 'The name field is required.'
        } else if (!this.password) {
          this.errors.password = 'The password field is required.'
        } else if (this.password.length < 6) {
          this.errors.password = `The password must be at least 6 characters.`
        } else if (this.passwordScore < 2) {
          this.errors.password = this.passwordFeedback
        } else if (!this.passwordConfirmation) {
          this.errors.password = 'Please confirm your password.'
        } else if (this.password !== this.passwordConfirmation) {
          this.errors.password = `Passwords don't match.`
        }
        if (!this.acceptedTerms) {
          this.errors.accepted_terms = this.$t('signup_agree_error_message')
        }
      },
    },
    watch: {
      password(password) {
        this.errors = {}
        return loadChunk('zxcvbn', () => {
          this.passwordFeedback = []
          const result = window.zxcvbn(password)
          const score = result.score
          if (result.feedback && result.feedback.suggestions) {
            this.passwordFeedback = result.feedback.suggestions
          }

          if (password.length < 6 && score > 2) {
            this.passwordScore = 2
          } else {
            this.passwordScore = score
          }
        })
      },
    },
    mixins: [OnboardingMixin],
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .onboarding-name {
    width: 60%;
  }

  .checkbox-input {
    display: flex;

    label {
      margin-top: 6px;
    }
  }
</style>
