import { snakeCase } from './string.ts'
import { getCallTimeDifference } from './date.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
import MODAL_IDS from '../constants/modal-ids.js'
import { fetchActor } from '../api/actors.js'
import { MUTATION_TYPES as REPORT_MUTATION_TYPES } from '../store/modules/reports.js'
import TranslationsMixin from '../util/TranslationsMixin'
import _isEmpty from 'lodash/isEmpty.js'
import slugify from './slugify.js'

export default {
  data () {
    return {
      hasSubmittedReport: { value: false, isLoading: false },
      keepReportSelectedAfterDestroy: false
    }
  },
  computed: {
    reportSettings () {
      return this.$store.getters.reportSettings
    },
    allReportFieldsMap () {
      const map = {}

      this.reportSettings.forEach((reportTemplate) => {
        reportTemplate.reportFields.forEach(field => {
          map['report_field_' + field.id] = field
        })
      })

      return map
    },
    reportTemplateTemplateSlugPrefix () {
      return '#report-'
    },
    selectedReportTemplate () {
      if (!_isEmpty(this.$store.getters.firstSelectedReportTemplate)) {
        return this.$store.getters.firstSelectedReportTemplate
      }

      return this.$store.getters.selectedReportTemplate
    },
    firstSelectedReportTemplate () {
      return this.$store.getters.firstSelectedReportTemplate
    },
    hasSelectedReport () {
      if (!_isEmpty(this.firstSelectedReportTemplate)) {
        return false
      }

      return !!this.selectedReportTemplate && !!this.selectedReportTemplate.name
    },
    hasFirstSelectedReport () {
      return !!this.firstSelectedReportTemplate && !!this.firstSelectedReportTemplate.name
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    applyButtonIcon () {
      if (!this.callCountdownTemplate || !this.isLoggedIn || !this.ownedLegalEntityClaim) {
        return 'reporting'
      }

      if (this.hasSubmittedReport.isLoading) {
        return 'spinner'
      }

      if (this.getCallTimeDifference(this.callCountdownTemplate.due_date).startsWith(this.$t('countdown_expired')) && !this.hasSubmittedReport.value) {
        return 'clock'
      }

      return this.hasSubmittedReport.value ? 'check' : 'reporting'
    },
    applyButtonLabel () {
      if (!this.callCountdownTemplate || !this.isLoggedIn || !this.ownedLegalEntityClaim) {
        return this.$t('actor_detail_reports_apply')
      }

      if (this.hasSubmittedReport.isLoading) {
        return this.$t('loading')
      }

      if (this.getCallTimeDifference(this.callCountdownTemplate.due_date).startsWith(this.$t('countdown_expired')) && !this.hasSubmittedReport.value) {
        return this.$t('countdown_expired')
      }

      return this.hasSubmittedReport.value ? this.$t('actor_detail_reports_submitted') : this.$t('actor_detail_reports_apply')
    },
    ownedLegalEntityClaim () {
      let legalEntityClaim
      if (this.$store.getters.isActor) {
        legalEntityClaim = this.getLegalEntityOwnerClaim(this.$store.state.user.profile.claims)

        if (!legalEntityClaim) {
          legalEntityClaim = this.getLegalEntityContributorClaim(this.$store.state.user.profile.claims)
        }
        return legalEntityClaim
      }
    },
    ownedLegalEntityReportUrl () {
      if (this.ownedLegalEntityClaim) {
        return `/actors/${this.ownedLegalEntityClaim.id}`
      }
    },
    ecosystemHasReportHeadlineConfigured () {
      return this.config.headlineType && this.config.headlineType.startsWith('report-template-') && this.localizedDisplayProperty(this.config, 'headlineText')
    },
    selectedReportTemplateForHeadline () {
      if (this.ecosystemHasReportHeadlineConfigured) {
        // The headlineType can be configured to display a global search input field, some text, or a report "call" banner. In case of the banner,the ID of the selected report is added in the 'report-template-{report-template-id}' string
        const reportTemplateId = Number(this.config.headlineType.split('-')[2])
        return this.reportSettings.find(template => template.id === reportTemplateId)
      }
    },
    callCountdownTemplate () {
      if (this.selectedReportTemplateForHeadline && this.getCallTimeDifference(this.selectedReportTemplateForHeadline.due_date)) {
        return this.selectedReportTemplateForHeadline
      }
    },
    config () {
      return this.$store.state.config
    },
    reportLabel () {
      if (this.hasFirstSelectedReport) {
        return this.firstSelectedReportTemplate.name
      }

      if (!this.selectedReportTemplate) {
        return this.$t('actor_detail_reports')
      }

      return this.selectedReportTemplate.name || this.$t('actor_detail_reports')
    },
    fileReportFieldIds () {
      if (!this.reportSettings || this.reportSettings.length === 0) {
        return []
      }

      var fileFieldIds = []

      this.reportSettings
        .forEach(setting => {
          if (!setting.reportFields || setting.reportFields.length === 0) {
            return
          }

          setting.reportFields.forEach(field => {
            if (field.type === 'file') {
              fileFieldIds.push(field.id)
            }
          })
        })

      return fileFieldIds
    },
    submittedDate () {
      if (!this.actor || !this.actor.current_report || !this.actor.current_report.length) {
        return
      }

      return this.actor.current_report.find(report => report.submitted_at).submitted_at
    },
    isReportTemplateACall () {
      return this.selectedReportTemplate && this.selectedReportTemplate.is_call === true
    },
    isSubmittedCall () {
      if (!this.actor || !this.actor.current_report || !this.actor.current_report.length) {
        return false
      }

      const hasBeenSubmitted = this.reportHasBeenSubmitted(this.actor.current_report, this.selectedReportTemplate)

      return this.isReportTemplateACall && hasBeenSubmitted
    },
    filterableReportFieldsForActor () {
      const templates = this.availableReportTemplatesForCurrentActor

      if (!templates || templates.length === 0) {
        return []
      }

      const reportFields = []

      templates.forEach(template => {
        if (template.reportFields && template.reportFields.length) {
          template.reportFields.forEach(field => {
            if (field.can_be_used_as_filter) {
              reportFields.push(field)
            }
          })
        }
      })

      return reportFields
    },
    availableReportTemplatesForCurrentActor () {
      if (!this.actor || !this.actor.actor_type) {
        return []
      }

      var settingName = 'enabled_for_' + snakeCase(this.actor.actor_type)
      var applicableSettings = this.reportSettings.filter(setting => setting[settingName] === true)

      if (!applicableSettings || applicableSettings.length === 0) {
        return []
      }

      const reportTemplatesSortedByOpenCallStatus = [...applicableSettings.filter(template => template.is_call), ...applicableSettings.filter(template => !template.is_call)]
      const sortedReportTemplatesWithoutEmptyExpiredCalls = reportTemplatesSortedByOpenCallStatus.filter(reportTemplate => {
        if (reportTemplate && reportTemplate.is_call && (!this.actor.current_report || !this.actor.current_report.length) && !this.getCallTimeDifference(reportTemplate.due_date)) {
          // call time difference is empty if report has been expired for >2 days
          return false
        }

        if (reportTemplate && reportTemplate.is_call && this.actor.current_report && this.actor.current_report.length && this.getOpenCallStatus(this.actor, reportTemplate) === 'EXPIRED') {
          return this.actor.current_report.filter(reportField => this.isIncludedInTemplate(reportTemplate, reportField)).length > 0
        }

        const actorPortfolios = this.actor.includedPortfolios || this.actor.portfolios

        // Filter out report templates of which portfolios are not configured to be accessible for the actor. NOTE: when no report template portfolios are configured, ignore the check
        if (reportTemplate && reportTemplate.portfolios && reportTemplate.portfolios.length > 0) {
          const portfolioForReport = reportTemplate.portfolios.filter(portfolio => {
            if (!actorPortfolios || actorPortfolios.length <= 0) {
              return false
            }

            const allowedActorPortfolios = actorPortfolios.filter(actorPortfolio => {
              const actorPortfolioId = actorPortfolio.id || actorPortfolio

              return portfolio.id == actorPortfolioId
            })

            return allowedActorPortfolios.length && allowedActorPortfolios.length > 0
          })

          // Return if actor is allowed to access report via accessible portfolios
          return portfolioForReport.length && portfolioForReport.length > 0
        }

        // Other checks have passed
        return true
      })

      return sortedReportTemplatesWithoutEmptyExpiredCalls
    },
    hasReportTemplate () {
      return this.availableReportTemplatesForCurrentActor && this.availableReportTemplatesForCurrentActor.length
    },
    applyButtonTooltip () {
      return this.$t('report_apply_cta')
    },
    showOpenCallBannerOnCurrentActorProfile () {
      const actorDetail = this.$store.getters.detailActor
      return this.callCountdownTemplate && this.isLoggedIn && this.ownedLegalEntityClaim && actorDetail && actorDetail.id === this.ownedLegalEntityClaim.id
    },
  },
  methods: {
    hasAtleastOneReportFieldForTemplate (reportFields, reportTemplate) {
      if (!reportFields || !reportTemplate || !reportTemplate.reportFields || !reportTemplate.reportFields.length || !reportFields.length) {
        return false
      }
      return reportFields.filter(reportField => {
        return !!reportTemplate.reportFields.find(templateField => reportField.ecosystem_report_field_id === templateField.id)
      }).length > 0
    },
    isOpenCallTemplateDueDateExpired (openCallTemplate) {
      if (!openCallTemplate) {
        return false
      }

      if (!openCallTemplate.is_call || !openCallTemplate.due_date) {
        return false
      }

      const dueDate = new Date(openCallTemplate.due_date).getTime()
      const currentDate = new Date().getTime()

      return dueDate < currentDate
    },
    getCallTimeDifference,
    getOpenCallStatus (actor, reportTemplate) {
      if (!actor || !reportTemplate.is_call || !reportTemplate.due_date) {
        return ''
      }
      if ((!this.getCallTimeDifference(reportTemplate.due_date) || this.getCallTimeDifference(reportTemplate.due_date).startsWith(this.$t('countdown_expired'))) && !this.reportHasBeenSubmitted(actor.current_report, reportTemplate)) {
        return this.$t('actor_detail_reports_expired')
      }

      return this.reportHasBeenSubmitted(actor.current_report, reportTemplate) ? this.$t('actor_detail_reports_submitted') : this.$t('actor_detail_reports_new')
    },
    isIncludedInTemplate (reportTemplate, reportField) {
      if (!reportTemplate || !reportTemplate.reportFields || !reportTemplate.reportFields.length || !reportField || !reportField.ecosystem_report_field_id) {
        return false
      }
      return !!reportTemplate.reportFields.find(reportTemplateField => reportTemplateField.id === reportField.ecosystem_report_field_id)
    },
    selectReportTemplate (reportTemplate) {
      this.$store.commit(REPORT_MUTATION_TYPES.SET_FIRST_SELECTED_REPORT_TEMPLATE, {})

      if (this.isOpenCallEmptyAndExpired(this.actor.current_report, reportTemplate)) {
        return
      }

      this.$store.commit(REPORT_MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE, reportTemplate)
      this.$bus.emit('selectReportTemplate')

      this.goToReportUrl(reportTemplate)
    },
    setFirstReportTemplate (reportTemplate) {
      this.$store.commit(REPORT_MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE, {})

      if (this.isOpenCallEmptyAndExpired(this.actor.current_report, reportTemplate)) {
        return
      }

      this.$store.commit(REPORT_MUTATION_TYPES.SET_FIRST_SELECTED_REPORT_TEMPLATE, reportTemplate)
    },
    showOrHideReport () {
      if (window.location && window.location.hash && window.location.hash.startsWith(this.reportTemplateTemplateSlugPrefix)) {
        const slug = window.location.hash.split('-')
        const templateId = slug[slug.length - 1]

        // eslint-disable-next-line no-undef
        const slugTemplateId = atob(templateId)
        const currentTemplates = this.availableReportTemplatesForCurrentActor
        const currentTemplate = currentTemplates.filter(reportTemplate => reportTemplate.id === parseInt(slugTemplateId))

        const currentReportTemplate = currentTemplate[0]
        this.$store.commit(REPORT_MUTATION_TYPES.SET_FIRST_SELECTED_REPORT_TEMPLATE, {})
        this.$store.commit(REPORT_MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE, currentReportTemplate)
        this.$bus.emit('selectReportTemplate')
      } else {
        this.hideReport()
      }
    },
    hideReport () {
      this.$store.commit(REPORT_MUTATION_TYPES.SET_FIRST_SELECTED_REPORT_TEMPLATE, {})
      this.$store.commit(REPORT_MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE, {})

      this.$router.push({ hash: '' })
    },
    goToReportUrl (reportTemplate, withHash = true, withActor = true) {
      if (withActor) {
        this.$router.push(this.getReportTemplateSlug(reportTemplate, withHash, withActor))
      } else {
        this.$router.push({ hash: this.getReportTemplateSlug(reportTemplate, withHash, withActor) })
      }
    },
    getTemplateForReport (report) {
      // to be updated
      if (this.availableReportTemplatesForCurrentActor && this.availableReportTemplatesForCurrentActor.length) {
        return this.availableReportTemplatesForCurrentActor[0]
      } else {
        return {}
      }
    },
    reportHasBeenSubmitted (report, reportTemplate) {
      if (reportTemplate) {
        return !!report.find(reportField => this.isIncludedInTemplate(reportTemplate, reportField) && reportField.submitted_at)
      }
      return !!report.find(reportField => reportField.submitted_at)
    },
    isOpenCallEmptyAndExpired (report, openCallTemplate) {
      const isOpenCallExpired = this.isOpenCallTemplateDueDateExpired(openCallTemplate)
      const isOpenCallEmpty = !this.hasAtleastOneReportFieldForTemplate(report, openCallTemplate)
      return isOpenCallExpired && isOpenCallEmpty
    },
    onClickApplyForCallOnHomepage () {
      if (this.ownedLegalEntityReportUrl) {
        if (this.selectedReportTemplateForHeadline && !this.isOpenCallEmptyAndExpired(this.$store.getters.ownedLegalEntity.current_report, this.selectedReportTemplateForHeadline)) {
          this.$store.commit(REPORT_MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE, this.selectedReportTemplateForHeadline)
          this.keepReportSelectedAfterDestroy = true
        }

        this.goToReportUrl(this.selectedReportTemplateForHeadline)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { afterCreate: 'goToActor' })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      }
    },
    getLegalEntityOwnerClaim (claims) {
      return claims.find(claim => claim.claim_verified && claim.claim_type === 'actor-owner' && claim.actor_type === 'LegalEntity')
    },
    getLegalEntityContributorClaim (claims) {
      return claims.find(claim => claim.claim_verified && claim.claim_type === 'actor-contributor' && claim.actor_type === 'LegalEntity')
    },
    verifyReportSubmissionStatus (force) {
      if (this.hasSubmittedReport.isLoading) {
        return
      }

      this.hasSubmittedReport.isLoading = true
      if (!this.ownedLegalEntityClaim) {
        this.hasSubmittedReport.value = false
        this.hasSubmittedReport.isLoading = false
        return
      }

      this.hasSubmittedReport.isLoading = true
      if (this.$store.getters.ownedLegalEntity && !force) {
        if (this.$store.getters.ownedLegalEntity.current_report) {
          this.hasSubmittedReport.value = this.reportHasBeenSubmitted(this.$store.getters.ownedLegalEntity.current_report)
        } else {
          this.hasSubmittedReport.value = false
        }
        this.hasSubmittedReport.isLoading = false
      } else {
        fetchActor(this.ownedLegalEntityClaim.id)
          .then((actor) => {
            this.$store.commit(REPORT_MUTATION_TYPES.SET_OWNED_LEGAL_ENTITY, actor)
            if (actor.current_report) {
              this.hasSubmittedReport.value = this.reportHasBeenSubmitted(actor.current_report)
            } else {
              this.hasSubmittedReport.value = false
            }
          })
          .finally(() => {
            this.hasSubmittedReport.isLoading = false
          })
      }
    },
    hasOpenCall () {
      return this.reportSettings.length > 0
    },
    getOpenCall () {
      if (this.reportSettings.length > 0) {
        var openCallReports = this.reportSettings.filter(report => report.is_call == true)
        // @todo check if submission has not ended

        return openCallReports[0]
      }

      return false
    },
    getReportTemplateSlug (reportTemplate, withHash = true, withActor = true) {
      let slug = slugify(reportTemplate.name) + '-' + btoa(reportTemplate.id)

      if (withHash) {
        slug = this.reportTemplateTemplateSlugPrefix + slug
      }

      if (withActor) {
        if (this.$route.params.id) {
          return '/actors/' + this.$route.params.id + slug
        }

        // Upon clicking the "Submit open call" button on the homepage
        if (this.ownedLegalEntityReportUrl && this.ownedLegalEntityClaim && this.ownedLegalEntityClaim.id) {
          return '/actors/' + this.ownedLegalEntityClaim.id + slug
        }

        if (this.actor.id) {
          return '/actors/' + this.actor.id + slug
        }
      }

      return slug
    },
    getReportTemplateUrl (reportTemplate, external = false) {
      if (!this.actor) {
        return
      }

      // eslint-disable-next-line no-undef
      const actorReportUri = this.getReportTemplateSlug(reportTemplate)

      if (external) {
        return window.location.protocol + '//' + window.location.hostname + actorReportUri
      }

      return actorReportUri
    },
  },
  mixins: [
    TranslationsMixin,
  ],
  watch: {
    ownedLegalEntityClaim (val) {
      this.verifyReportSubmissionStatus()
    },
  },
  created () {
    this.$bus.on('actorCreated', this.verifyReportSubmissionStatus)
  },
  async mounted () {
    this.verifyReportSubmissionStatus()
    this.$bus.on('actorReportSubmitted', () => {
      this.verifyReportSubmissionStatus(true)
    })
  },
  beforeUnmount () {
    this.$bus.off('actorCreated')
    this.$bus.off('actorReportSubmitted')
    if (!this.keepReportSelectedAfterDestroy) {
      this.hideReport()
    }
  },
}
