<template>
  <div>
    <h1 class="h1">{{ title }}</h1>
    <h4 class="h4" v-if="!hasUserAnInvitation">{{ onboardingTextConfig.onboardingSecondFinalSubtitle }}</h4>
    <h4 class="h4" v-else>{{ invitedUserCompletedRegistrationMessage }}</h4>

    <div style="display: flex; justify-content: center; margin-top: 1rem;">
      <ds-button
        v-if="hasUserAnInvitation"
        type="submit"
        variant="rounded"
        style="max-width: 300px;"
        :label="$t('onboarding_finish_cta')"
        :disabled="redirecting"
        @click.prevent="submit"
      />
    </div>

    <div class="onboarding-invited-by" v-if="invitedBy">
      {{ $t('onboarding_invited_by') }} {{ invitedBy }}
    </div>
  </div>
</template>

<script lang="ts">
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { defineComponent } from '~/vue'

  export default defineComponent({
    data() {
      return {
        invitedBy: window.invited_by,
        redirecting: false,
      }
    },
    computed: {
      hasUserAnInvitation(): boolean {
        return !!this.$route?.query.invitation
      },
      title(): string {
        if (this.hasUserAnInvitation) {
          return this.onboardingTextConfig.onboardingRegistrationFinalisedThroughInvitationTitle
        }

        return this.onboardingTextConfig.onboardingSecondFinalTitle
      },
      invitedUserCompletedRegistrationMessage(): string {
        return this.$t('invited_user_completed_registration_message') + ' ' + this.$store.getters.ecosystemDisplayName + '.'
      },
    },
    methods: {
      async submit() {
        this.redirecting = true
        this.$emit('submitted-final-step')

        if (this.$route.query.redirect_uri) {
          let redirectUri = decodeURIComponent(this.$route.query.redirect_uri)
          // remove redirect_uri from query
          delete this.$route.query.redirect_uri
          await this.$router.push(redirectUri + '?' + new URLSearchParams(this.$route.query).toString())
        } else {
          await this.$router.push('/profile/update')
        }

        trackHeapEvent('onboarding-registration-complete')

        window.location.reload()
      },
    },
    mixins: [OnboardingMixin],
  })
</script>
