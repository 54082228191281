import { ACTION_TYPES as ONBOARDING_ACTION_TYPES } from '../store/modules/onboarding.js'
import TranslationsMixin from './TranslationsMixin.js'

export default {
  computed: {
    hasAccessToNewSpottingAreas () {
      return this.$store.getters.hasAccessToNewSpottingAreas
    },
    shouldShowActorSignUp () {
      if ((window.role && window.role !== 'actor') || window.hasLinkedActor) {
        return false
      }

      return this.$store.state.config.allowAdd
    },
    isUserSigningUpAsTeamMemberOrOwner () {
      return window.role === 'member' || window.role === 'owner'
    },
    onboardingTranslations () {
      return this.$store.state.onboarding.onboardingTranslations || {
        questions: {},
      }
    },
    fullRedirectUri () {
      if (!this.$route.query.redirect_uri) {
        return ''
      }

      const redirectUri = decodeURIComponent(this.$route.query.redirect_uri)

      return window.location.origin + redirectUri + '?' + new URLSearchParams(this.$route.query).toString()
    },
    isEnglish () {
      return this.$store.state.localization.activeLocale === 'en'
    },
    onboardingTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingTitle
      }

      return this.onboardingTranslations.NLonboardingTitle
    },
    onboardingSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingSubtitle
      }

      return this.onboardingTranslations.NLonboardingSubtitle
    },
    onboardingFinalTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingFinalTitle
      } else {
        return this.onboardingTranslations.NLonboardingFinalTitle
      }
    },
    onboardingSecondFinalTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingSecondFinalTitle
      } else {
        return this.onboardingTranslations.NLonboardingSecondFinalTitle
      }
    },
    onboardingFinalSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingFinalSubtitle
      } else {
        return this.onboardingTranslations.NLonboardingFinalSubtitle
      }
    },
    onboardingSecondFinalSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.onboardingSecondFinalSubtitle
      }
      return this.onboardingTranslations.NLonboardingSecondFinalSubtitle
    },
    onboardingquestion_1 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_1
      } else {
        return this.onboardingTranslations.questions.NLquestion_1
      }
    },
    onboardingquestion_2 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_2
      } else {
        return this.onboardingTranslations.questions.NLquestion_2
      }
    },
    onboardingquestion_3 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_3
      } else {
        return this.onboardingTranslations.questions.NLquestion_3
      }
    },
    onboardingquestion_4 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_4
      } else {
        return this.onboardingTranslations.questions.NLquestion_4
      }
    },
    onboardingquestion_5 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_5
      } else {
        return this.onboardingTranslations.questions.NLquestion_5
      }
    },
    onboardingquestion_6 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_6
      } else {
        return this.onboardingTranslations.questions.NLquestion_6
      }
    },
    onboardingquestion_7 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_7
      } else {
        return this.onboardingTranslations.questions.NLquestion_7
      }
    },
    onboardingquestion_8 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_8
      } else {
        return this.onboardingTranslations.questions.NLquestion_8
      }
    },
    onboardingquestion_9 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_9
      } else {
        return this.onboardingTranslations.questions.NLquestion_9
      }
    },
    onboardingquestion_10 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_10
      } else {
        return this.onboardingTranslations.questions.NLquestion_10
      }
    },
    onboardingquestion_11 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_11
      } else {
        return this.onboardingTranslations.questions.NLquestion_11
      }
    },
    onboardingquestion_12 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_12
      } else {
        return this.onboardingTranslations.questions.NLquestion_12
      }
    },
    onboardingquestion_13 () {
      if (this.isEnglish) {
        return this.onboardingTranslations.questions.question_13
      } else {
        return this.onboardingTranslations.questions.NLquestion_13
      }
    },
    welcomeTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.welcomeTitle
      } else {
        return this.onboardingTranslations.NLwelcomeTitle
      }
    },
    welcomeSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.welcomeSubtitle
      } else {
        return this.onboardingTranslations.NLwelcomeSubtitle
      }
    },
    inspireTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.inspireTitle
      } else {
        return this.onboardingTranslations.NLinspireTitle
      }
    },
    informTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.informTitle
      } else {
        return this.onboardingTranslations.NLinformTitle
      }
    },
    interactTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.interactTitle
      } else {
        return this.onboardingTranslations.NLinteractTitle
      }
    },
    inspireDescription () {
      if (this.isEnglish) {
        return this.onboardingTranslations.inspireDescription
      } else {
        return this.onboardingTranslations.NLinspireDescription
      }
    },
    informDescription () {
      if (this.isEnglish) {
        return this.onboardingTranslations.informDescription
      } else {
        return this.onboardingTranslations.NLinformDescription
      }
    },
    interactDescription () {
      if (this.isEnglish) {
        return this.onboardingTranslations.interactDescription
      } else {
        return this.onboardingTranslations.NLinteractDescription
      }
    },
    relevantActorsDescription () {
      if (this.isEnglish) {
        return this.onboardingTranslations.relevantActorsDescription
      } else {
        return this.onboardingTranslations.NLrelevantActorsDescription
      }
    },
    relevantKeywordsDescription () {
      if (this.isEnglish) {
        return this.onboardingTranslations.relevantKeywordsDescription
      } else {
        return this.onboardingTranslations.NLrelevantKeywordsDescription
      }
    },
    myActorsEmptyText () {
      if (this.isEnglish) {
        return this.onboardingTranslations.myActorsEmptyText
      } else {
        return this.onboardingTranslations.NLmyActorsEmptyText
      }
    },
    myProductsEmptyText () {
      if (this.isEnglish) {
        return this.onboardingTranslations.myProductsEmptyText
      } else {
        return this.onboardingTranslations.NLmyProductsEmptyText
      }
    },
    myKnowledgeEmptyText () {
      if (this.isEnglish) {
        return this.onboardingTranslations.myKnowledgeEmptyText
      } else {
        return this.onboardingTranslations.NLmyKnowledgeEmptyText
      }
    },
    actorsSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.actorsSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLactorsSimplifiedTitle
      }
    },
    actorsSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.actorsSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLactorsSimplifiedSubtitle
      }
    },
    productsSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.productsSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLproductsSimplifiedTitle
      }
    },
    productsSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.productsSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLproductsSimplifiedSubtitle
      }
    },
    challengesSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.challengesSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLchallengesSimplifiedTitle
      }
    },
    challengesSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.challengesSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLchallengesSimplifiedSubtitle
      }
    },
    announcementsSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.announcementsSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLannouncementsSimplifiedTitle
      }
    },
    eventsSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.eventsSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLeventsSimplifiedTitle
      }
    },
    eventsSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.eventsSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLeventsSimplifiedSubtitle
      }
    },
    announcementsSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.announcementsSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLannouncementsSimplifiedSubtitle
      }
    },
    knowledgeBaseSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.knowledgeBaseSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLknowledgeBaseSimplifiedTitle
      }
    },
    knowledgeBaseSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.knowledgeBaseSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLknowledgeBaseSimplifiedSubtitle
      }
    },
    intelligenceSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.intelligenceSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLintelligenceSimplifiedTitle
      }
    },
    intelligenceSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.intelligenceSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLintelligenceSimplifiedSubtitle
      }
    },
    communitiesSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.communitiesSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLcommunitiesSimplifiedTitle
      }
    },
    communitiesSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.communitiesSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLcommunitiesSimplifiedSubtitle
      }
    },
    spottingAreasSimplifiedTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.spottingAreasSimplifiedTitle
      } else {
        return this.onboardingTranslations.NLspottingAreasSimplifiedTitle
      }
    },
    spottingAreasSimplifiedSubtitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.spottingAreasSimplifiedSubtitle
      } else {
        return this.onboardingTranslations.NLspottingAreasSimplifiedSubtitle
      }
    },
    actorsSecondTopBarTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.actorsSecondTopBarTitle
      } else {
        return this.onboardingTranslations.NLactorsSecondTopBarTitle
      }
    },
    actorsPageTitle () {
      if (this.isEnglish) {
        return this.onboardingTranslations.actorsPageTitle
      } else {
        return this.onboardingTranslations.NLactorsPageTitle
      }
    },
    onboardingTextConfig () {
      return {
        onboardingTitle: this.onboardingTitle || this.$t('onboarding_title', { ecosystem: this.$store.getters.ecosystemDisplayName, user: window.name || '' }).replace(' ,', ','), // if the name is empty, remove the extra space
        onboardingSubtitle: this.onboardingSubtitle || this.config.title,
        onboardingFinalTitle: this.onboardingFinalTitle || this.$t('onboarding_final_title'),
        onboardingSecondFinalTitle: this.onboardingSecondFinalTitle || this.$t('onboarding_new_final_title'),
        onboardingRegistrationFinalisedThroughInvitationTitle: this.$t('onboarding_registration_finalised_through_invitation_title'),
        onboardingFinalSubtitle: this.onboardingFinalSubtitle || this.$t('onboarding_final_sub_title'),
        onboardingSecondFinalSubtitle: this.onboardingSecondFinalSubtitle || this.$t('onboarding_new_final_sub_title'),
        onboardingQuestion1: this.onboardingquestion_1 || this.$t('onboarding_question_1'),
        onboardingQuestion2: this.onboardingquestion_2 || this.$t('onboarding_question_2'),
        onboardingQuestion3: this.onboardingquestion_3 || this.$t('onboarding_question_3'),
        onboardingQuestion4: this.onboardingquestion_4 || this.$t('onboarding_question_4'),
        onboardingQuestion5: this.onboardingquestion_5 || this.$t('onboarding_question_5'),
        onboardingQuestion6: this.onboardingquestion_6 || this.$t('onboarding_question_6'),
        onboardingQuestion7: this.onboardingquestion_7 || this.$t('onboarding_question_7'),
        onboardingQuestion8: this.onboardingquestion_8 || this.$t('onboarding_question_8'),
        welcomeTitle: this.welcomeTitle || this.$t('welcome_title'),
        welcomeSubtitle: this.welcomeSubtitle || this.$t('welcome_subtitle'),
        informTitle: this.informTitle || this.$t('personal_home_tab_inform'),
        inspireTitle: this.inspireTitle || this.$t('personal_home_tab_inspire'),
        interactTitle: this.interactTitle || this.$t('personal_home_tab_interact'),
        informDescription: this.informDescription || this.$t('personal_home_tab_inform_description'),
        inspireDescription: this.inspireDescription || (!this.hasAccessToNewSpottingAreas ? this.$t('personal_home_tab_inspire_description') : this.$t('personal_home_tab_inspire_spotting_areas_description')),
        interactDescription: this.interactDescription || this.$t('personal_home_tab_interact_description'),
        relevantActorsDescription: this.relevantActorsDescription || this.$t('personal_home_tab_relevant_actors_description'),
        relevantKeywordsDescription: this.relevantKeywordsDescription || this.$t('personal_home_tab_relevant_keywords_description'),
        myActorsEmptyText: this.myActorsEmptyText || this.$t('personal_home_my_actors_empty_text'),
        myProductsEmptyText: this.myProductsEmptyText || this.$t('personal_home_my_products_empty_text', { product: this.productLabel }),
        myKnowledgeEmptyText: this.myKnowledgeEmptyText || this.$t('personal_home_my_knowledge_empty_text'),
        actorsSimplifiedTitle: this.actorsSimplifiedTitle || this.$t('actors_simplified_title'),
        actorsSimplifiedSubtitle: this.actorsSimplifiedSubtitle || this.$t('actors_simplified_subtitle', { ecosystem: this.$store.getters.ecosystemDisplayName }),
        productsSimplifiedTitle: this.productsSimplifiedTitle || this.$t('products_simplified_title', { product: this.productLabel }),
        productsSimplifiedSubtitle: this.productsSimplifiedSubtitle || this.$t('products_simplified_title', { product: this.productLabel }),
        challengesSimplifiedTitle: this.challengesSimplifiedTitle || this.$t('challenges_title', { challenges: this.challengesLabel }),
        challengesSimplifiedSubtitle: this.challengesSimplifiedSubtitle || this.$t('challenges_subtitle'),
        announcementsSimplifiedTitle: this.announcementsSimplifiedTitle || this.$t('announcements_hero_title'),
        announcementsSimplifiedSubtitle: this.announcementsSimplifiedSubtitle || this.$t('announcements_subtitle', { announcements: this.postsLabel }),
        knowledgeBaseSimplifiedTitle: this.knowledgeBaseSimplifiedTitle || this.$t('knowledge_base_header_title'),
        knowledgeBaseSimplifiedSubtitle: this.knowledgeBaseSimplifiedSubtitle || this.$t('knowledge_base_header_subtitle'),
        intelligenceSimplifiedTitle: this.intelligenceSimplifiedTitle || this.$t('intelligence_simplified_title'),
        intelligenceSimplifiedSubtitle: this.intelligenceSimplifiedSubtitle || this.$t('intelligence_simplified_subtitle'),
        communitiesSimplifiedTitle: this.communitiesSimplifiedTitle || this.$t('communities_simplified_title'),
        communitiesSimplifiedSubtitle: this.communitiesSimplifiedSubtitle || this.$t('communities_simplified_subtitle'),
        spottingAreasSimplifiedTitle: this.spottingAreasSimplifiedTitle || this.$t('spotting_areas_simplified_title'),
        spottingAreasSimplifiedSubtitle: this.spottingAreasSimplifiedSubtitle || this.$t('spotting_areas_simplified_subtitle'),
        eventsSimplifiedTitle: this.eventsSimplifiedTitle || this.$t('events_hero_title'),
        eventsSimplifiedSubtitle: this.eventsSimplifiedSubtitle || this.$t('events_hero_subtitle', { ecosystem: this.$store.getters.ecosystemDisplayName }),
        actorsSecondTopBarTitle: this.actorsSecondTopBarTitle || this.$t('top_bar_actors'),
        actorsPageTitle: this.actorsPageTitle || this.$t('shp_actors_title'),
      }
    },
    currentEcosystem () {
      return this.$store.getters.currentEcosystem
    },
    config () {
      return this.$store.state.config
    },
  },
  mounted () {
    if (!this.$store.state.onboarding.onboardingTranslations && !this.$store.state.onboarding.fetchingOnboardingTranslations) {
      this.$store.dispatch(ONBOARDING_ACTION_TYPES.FETCH_ONBOARDING_TRANSLATIONS)
    }
  },
  mixins: [TranslationsMixin],
}
