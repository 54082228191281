<template>
  <div class="home-simplified static-home-foodleap">
    <Container>
      <div class="static-home-foodleap_contact_body">
        <div class="col-12 static-home-foodleap_contact_flex">
          <div class="col-12 col-sm-12 col-md-6 col-lg-8 static-home-foodleap_contact_help">
            <h1>{{ $t('static_home_foodvalley_contact_title') }}</h1>
            <p>{{ $t('static_home_foodvalley_contact_description') }}</p>
            <div class="static-home-foodleap_contact_standard">
              <div v-for="item in cardData" class="static-home-foodleap_contact_standard_question">
                <ul>
                  <li @click="item.isOpen = !item.isOpen">
                    <icon :name="item.isOpen === true ? 'minus' : 'plus'"/>
                    <a>{{ item.question }}</a>
                  </li>
                </ul>
                <p style="width: 95%;" :style="item.isOpen ? '' : 'display: none'" v-html="item.text"></p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-4 static-home-foodleap_contact_questions">
            <h1>{{ $t('static_home_foodvalley_contact_questions') }}</h1>
            <p>{{ $t('static_home_foodvalley_contact_email') }}</p>
            <ds-button
              variant="secondary"
              :label="$t('action_email_us')"
              :size="null"
              :style="registerButtonStyle"
              href="mailto: info@foodleap.com"
            />
          </div>
        </div>
      </div>
    </Container>
    <SimplifiedFooterFoodleap style="position: relative; margin-top: 5rem;" class="footer"/>
  </div>
</template>


<script lang="ts">
  import { trackHeapEvent } from '../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import Avatar from '../Avatar/Avatar.vue'
  import SimplifiedFooterFoodleap from '../SimplifiedFooter/SimplifiedFooterFoodleap.vue'
  import Container from '../Container/Container.vue'

  export default {
    components: { SimplifiedFooterFoodleap, Avatar, Container },
    data: () => {
      return {
        isHiding: false,
        cardData: [
          {
            isOpen: false,
            question: '',
            text: '',
          },
          {
            isOpen: false,
            question: '',
            text: '',
          },
          {
            isOpen: false,
            question: '',
            text: '',
          },
          {
            isOpen: false,
            question: '',
            text: '',
          },
        ],
      }
    },
    computed: {
      registerButtonStyle() {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: '',
          height: 'auto',
          width: '125px',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
        }
      },
    },
    methods: {
      openSignupModal() {
        trackHeapEvent('staticHomePage.topBarRegister')

        if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else if (this.$store.state.config.allowAdd) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
    },
    mounted() {
      this.cardData[0].question = this.$t('static_home_foodvalley_contact_profile_title')
      this.cardData[1].question = this.$t('static_home_foodvalley_contact_facility_title')
      this.cardData[2].question = this.$t('static_home_foodvalley_contact_password_title')
      this.cardData[3].question = this.$t('static_home_foodvalley_contact_community_title')

      this.cardData[0].text = this.$t('static_home_foodvalley_contact_card_profile_description', { url: `<a href='https://platform.foodleap.com'>foodleap.com</a>` })
      this.cardData[1].text = this.$t('static_home_foodvalley_contact_card_facility_description', { email: `<a href='mailto:facilities@foodvalley.nl'>facilities@foodvalley.nl</a>` })
      this.cardData[2].text = this.$t('static_home_foodvalley_contact_card_password_description', { email: `<a href='mailto:info@foodleap.com'>info@foodleap.com</a>` })
      this.cardData[3].text = this.$t('static_home_foodvalley_contact_card_community_description', { url: `<a href='https://www.foodvalley.nl/about-us/become-foodvalley-partner'>www.foodvalley.nl/about-us/become-foodvalley-partner</a>` })
    },
  }
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .static-home-foodleap {
    font-family: Museo, serif;

    p {
      font-family: 'Zen Kaku Gothic New', sans-serif;
    }

    .container {
      margin-bottom: 14rem;
    }

    .static-home-foodleap_contact_body {
      padding: 70px 0;


      .static-home-foodleap_contact_flex {
        display: flex;

        .static-home-foodleap_contact_help {
          p {
            color: #707070;
            white-space: pre-line;
            width: 83%;
            font-size: 14px;
            margin-top: 20px;
          }

          h1 {
            color: #21244A;
          }
        }

        .static-home-foodleap_contact_questions {
          background-color: #DAE1DA;
          padding: 40px;
          height: 50%;

          p {
            color: #707070;
            margin: 20px 0 40px 0;
            font-size: 14px;
          }

          h1 {
            color: #21244A;
          }
        }
      }

      .static-home-foodleap_contact_standard {
        margin-top: 5rem;

        .static-home-foodleap_contact_standard_question {
          display: flex;
          flex-direction: column;
          background-color: #F2F9FE;
          width: 80%;
          padding: 20px;
          margin-bottom: 20px;

          ul {
            li {
              display: flex;
              align-items: center;

              &:hover {
                cursor: pointer;
              }

              a {
                color: #21244A;
                margin-left: 5px;
              }
            }
          }

          p {
            margin: 10px 0 0 25px;
            color: #707070;
          }
        }
      }
    }

    @media(max-width: $screen-md-max) {
      .container {
        margin-bottom: 0rem;
      }
      .static-home-foodleap_contact_body {
        padding: 20px;

        .static-home-foodleap_contact_flex {
          display: flex;
          flex-direction: column;

          .static-home-foodleap_contact_help {
            p {
              width: 100%;
            }
          }

          .static-home-foodleap_contact_questions {
            max-width: 100% !important;
            margin: 40px 0 40px 0;
          }
        }

        .static-home-foodleap_contact_standard {
          //margin-top: 30%;

          .static-home-foodleap_contact_standard_question {
            width: 100% !important;
          }
        }
      }
    }

    @media(max-width: $screen-lg) {
      .container {
        .static-home-foodleap_contact_body {
          .static-home-foodleap_contact_flex {
            display: flex;
            flex-direction: column;

            .static-home-foodleap_contact_help {
              width: 100%;
            }

            .static-home-foodleap_contact_standard {
              .static-home-foodleap_contact_standard_question {
                width: 100% !important;
              }
            }
          }
        }
      }

      @media(max-width: $screen-xl) {
        .static-home-foodleap_contact_standard {
          margin-top: 3rem;

          .static-home-foodleap_contact_standard_question {
            width: 40% !important;
          }
        }
      }
      @media(min-width: $screen-md-max) {
        .static-home-foodleap_contact_standard {
          margin-bottom: 6.5%;
        }
      }

      @media(max-width: $screen-2xl) {
        .container {
          min-height: 70vh;
        }
      }
    }
  }
</style>
