<template>
  <with-profile-tabs
    show-cards
    :available-tabs="['personal-home', 'notification-settings', 'password-and-security']"
    :title="$t('profile_settings_title')"
    @updatedProfilePicture="resetUserObjects()"
  >
    <div class="profile fixed-heading">
      <div
        class="scrollable notification-settings-panel"
        v-if="isNotificationTab"
      >

        <p
          class="form-group__label__text"
          style="margin-bottom: 0.5rem; text-align: center"
          v-if="isOwner && ! isSlackEnabled"
        >To send notifications via Slack, configure
          your webhook <a href="/settings/integrations"> here</a>.</p>

        <table class="notification-settings-panel__settings-table">
          <tbody v-for="(category, index) in displayedCategories">
          <tr>
            <th class="label-column">{{ category.label }}
              {{ $t('generic_notifications') }}
            </th>
            <th class="website-column">{{ $t('notification_settings_in_app') }}</th>
            <th class="email-column">{{ $t('notification_settings_email') }}</th>
            <th
              class="slack-column"
              v-if="showSlackSettings"
            >Slack
            </th>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr v-for="notification in category.notifications">
            <template
              v-if="updatedNotificationsUserObject.settings.notifications[notification.name]"
            >
              <td class="label-column">{{ notification.label }}</td>
              <td class="website-column">
                <toggle-button
                  v-model="updatedNotificationsUserObject.settings.notifications[notification.name].database.enabled"
                  :enabled="updatedNotificationsUserObject.settings.notifications[notification.name].database.enabled"
                  color="success"
                />
              </td>
              <td class="email-column">
                <div v-if="notification.name === 'user_invited' || notification.name === 'user_account_activated'">
                  <dropdown
                    is-simplified
                    :options="[{ 'label': 'On', 'value': 'instant' }, { 'label': 'Off', 'value': 'none' }]"
                    :modelValue="getEmailValue(notification.name)"
                    @update:modelValue="setEmailValue(notification.name, $event)"
                  />
                </div>
                <dropdown
                  v-else
                  is-simplified
                  :options="notification.notificationOptions"
                  :modelValue="getEmailValue(notification.name)"
                  @update:modelValue="setEmailValue(notification.name, $event)"
                />
              </td>
              <td
                class="slack-column"
                v-if="showSlackSettings"
              >
                <toggle-button
                  v-model="config.notifications[notification.name].slack.enabled"
                  :enabled="config.notifications[notification.name].slack.enabled"
                  color="success"
                />
              </td>
            </template>
          </tr>
          </tbody>
          <tbody>
          <tr>
            <th class="label-column">{{ $t('notification_settings_weekly_digest') }}</th>
            <th class="website-column"></th>
            <th class="email-column"></th>
            <th
              class="slack-column"
              v-if="showSlackSettings"
            ></th>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr v-if="(isOwner || isTeamMember) && hasAccessToPublisher">
            <td class="label-column">{{ $t('notification_settings_curation_digest') }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                is-simplified
                :options="emailOptions"
                v-model="curationDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr>
            <td class="label-column">{{ $t('notification_settings_activities_digest') }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                is-simplified
                :options="emailOptionsOnlyWeekly"
                v-model="weeklyDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="hasAccessToMonitoring">
            <td class="label-column">{{ $t('notification_settings_articles_digest') }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <dropdown
                is-simplified
                :options="emailOptions"
                v-model="contentDigest"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          </tbody>
          <tbody v-if="$store.getters.isPublisherEnabled">
          <tr>
            <th class="label-column">{{ $t('permissions_email_subscriptions') }}</th>
            <th class="website-column"></th>
            <th class="email-column"></th>
            <th
              class="slack-column"
              v-if="showSlackSettings"
            ></th>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td class="label-column">{{
                $t('ecosystem_notification_permission_updates', { ecosystem: ecosystemDisplayName })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userUpdatesAndFeatures"
                :enabled="userUpdatesAndFeatures"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr>
            <td class="label-column">{{
                $t('ecosystem_notification_permission_blogposts', { ecosystem: ecosystemDisplayName })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userBlogPosts"
                :enabled="userBlogPosts"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr>
            <td class="label-column">{{
                $t('ecosystem_notification_permission_events', { ecosystem: ecosystemDisplayName })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userEventInvites"
                :enabled="userEventInvites"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="isMember">
            <td class="label-column">{{
                $t('ecosystem_notification_permission_beta', { ecosystem: ecosystemDisplayName })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userBetaInvites"
                :enabled="userBetaInvites"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="config.newsletter">
            <td class="label-column">{{
                $t('permissions_email_subscriptions_permission', {
                  ecosystem: ecosystemDisplayName,
                })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userNewsletter"
                :enabled="userNewsletter"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr v-if="isMember">
            <td class="label-column">{{
                $t('ecosystem_notification_permission_datascouts_newsletter', { ecosystem: ecosystemDisplayName })
              }}
            </td>
            <td class="website-column"></td>
            <td class="email-column">
              <toggle-button
                v-model="userDataScoutsNewsletter"
                :enabled="userDataScoutsNewsletter"
                color="success"
              />
            </td>
            <td
              class="slack-column"
              v-if="showSlackSettings"
            ></td>
          </tr>
          <tr>
            <td
              colspan="4"
              class="label-column"
              style="font-size: 10px"
            >
              {{ $t('permissions_disclaimer') }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div
        class="scrollable profile-settings-panel"
        v-else-if="$route.hash === '#password-and-security' && isPersonalHomeAvailable"
      >
        <form
          class="profile__form"
          @submit.prevent="saveUpdatedPhoneNumber"
        >
          <h2 class="h2">{{ $t('profile_phone_number_title') }}</h2>
          <div class="row">
            <form-group
              is-simplified
              :label="$t('profile_phone')"
              class="col-xs-12"
            >
              <ds-input
                is-simplified
                placeholder="+32 477 280 200."
                type="tel"
                v-model="updatedEmailUserObject.phone"
              />
              <span v-if="newPhoneNumberMessage">{{ newPhoneNumberMessage }}</span>
              <Checkbox
                style="margin-top: 1rem;"
                label="Share your phone number with other users"
                :model-value="showPhoneNumber"
                @update:model-value="updateShowPhoneNumber($event)"
              />
            </form-group>

            <div
              class="col-xs-12"
              style="margin-bottom: 20px"
            >
              <ds-button
                :label="$t('profile_phone_number_save')"
                variant="rounded"
                :disabled="!isPhoneNumberUnchanged"
                @click="saveUpdatedPhoneNumber"
              />
            </div>
          </div>
        </form>
        <form
          class="profile__form"
          @submit.prevent="saveUpdatedPassword"
        >
          <h2 class="h2">{{ $t('profile_password_title') }}</h2>
          <div class="row">
            <form-group
              is-simplified
              :label="$t('profile_password_current_label')"
              :errors="errors.current_password"
              class="col-xs-12"
            >
              <ds-input
                is-simplified
                type="password"
                v-model="updatedPasswordUserObject.current_password"
              />
            </form-group>

            <form-group
              is-simplified
              :label="$t('profile_password_new_label')"
              :errors="errors.password"
              class="col-xs-12 col-sm-6"
            >
              <ds-input
                is-simplified
                type="password"
                v-model="updatedPasswordUserObject.password"
                :noAutocomplete="true"
              />
            </form-group>

            <form-group
              is-simplified
              :label="$t('profile_password_confirm_label')"
              :errors="errors.password_confirmation"
              class="col-xs-12 col-sm-6"
            >
              <ds-input
                is-simplified
                type="password"
                v-model="updatedPasswordUserObject.password_confirmation"
              />
            </form-group>
            <div
              class="col-xs-12"
              style="margin-bottom: 20px"
            >
              <ds-button
                :label="$t('profile_edit_password_save')"
                variant="rounded"
                :disabled="!isEditingPassword"
                @click="saveUpdatedPassword"
              />
            </div>
          </div>
        </form>
        <form
          class="profile__form"
          @submit.prevent="saveUpdatedEmailAddress"
        >
          <h2 class="h2">{{ $t('profile_email_title') }}</h2>
          <div class="row">
            <form-group
              is-simplified
              :label="$t('profile_email_label')"
              :errors="errors.email"
              class="col-xs-12"
            >
              <ds-input
                is-simplified
                v-model="updatedEmailUserObject.email"
                disabled
              />
            </form-group>

            <form-group
              is-simplified
              :label="$t('profile_update_email_label')"
              :errors="newEmailErrors.newEmail"
              class="col-xs-12"
            >
              <ds-input
                is-simplified
                v-model="newEmail"
              />
              <span v-if="newEmailMessage">{{ newEmailMessage }}</span>
            </form-group>
            <div
              class="col-xs-12"
              style="margin-bottom: 20px"
            >
              <ds-button
                :label="$t('profile_edit_email_save')"
                variant="rounded"
                :disabled="!isEditingEmail || !validNewEmail"
                @click="saveUpdatedEmailAddress"
              />
            </div>
          </div>
        </form>

        <form
          class="profile__form"
          style="margin-bottom: 1rem;"
        >
          <h2 class="h2">{{ $t('user_profile_leave_ecosystem') }}</h2>
          <div class="row">
            <div class="col-xs-12">
              <ds-button
                variant="rounded"
                size="small"
                icon="trash"
                @click="displayLeaveEcosystem"
                :label="$t('user_profile_leave_ecosystem_button_label')"
              />
            </div>
          </div>
        </form>
      </div>
      <div
        class="scrollable personal-home-panel"
        v-else-if="!isPersonalHomeDisabled"
      >
        <personal-home/>
      </div>
      <action-bar
        :editing="isEditingNotifications"
        :class="{'action-bar-with-navigation' : !isSimplifiedTemplateUsed}"
      >
        <ds-button
          variant="primary"
          :label="$t('update_profile_save_notifications')"
          @click="saveUpdatedNotifications"
          :disabled="saving"
        />
      </action-bar>
    </div>
  </with-profile-tabs>
</template>

<script>
  import Dropdown from '../Dropdown/Dropdown.vue'
  import Checkbox from '../Form/Checkbox.vue'

  import { acceptTerms, changeEmailRequest, fetchActorProfiles, updatePhoneNumber, updateProfile } from '../../api/user'

  import isEqual from 'lodash/isEqual'

  import MODAL_IDS from '../../constants/modal-ids'
  import { humanize } from '../../constants/properties'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { prepareUserForEditing } from '../../util/user-settings'
  import { LOCALE_LABELS } from '../../store/modules/localization'
  import { fetchAndPrepareConfigForEditing, prepareConfigForEditing, removeDefaultsFrom } from '../../util/config.js'
  import NotificationSettingConfig from '../../config/notificationSettings.js'
  import { updateConfig } from '../../api/config'
  import { inert } from '../../util/helpers'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ToggleButton from '../Form/ToggleButton.vue'
  import ActionBar from '../Form/ActionBar.vue'
  import SimpleCard from '../SimpleCard/SimpleCard.vue'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
  import HoverOverlayButton from '../Overlay/HoverOverlayButton.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import Modal from '../Modals/Modal.vue'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import WithProfileTabs from '../../pages/WithConfigurationTabs/WithProfileTabs.vue'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import PersonalHome from './PersonalHome.vue'

  export default {
    data () {
      return {
        errors: {},
        user: this.currentUser(),
        profiles: [],
        originalLocale: this.$store.getters.activeLocale,
        config: prepareConfigForEditing(this.$store.state.config),
        // separate user objects to prevent saving other data when pressing a specific save button
        updatedPasswordUserObject: this.currentUser(),
        updatedEmailUserObject: this.currentUser(),
        updatedNotificationsUserObject: this.currentUser(),
        errorMessage: null,
        newEmail: null,
        newEmailMessage: null,
        newEmailErrors: {},
        saving: false,
        newPhoneNumber: null,
        newPhoneNumberMessage: null,
        initialPhoneNumber: '',
        emailOptionsOnlyWeekly: [
          { label: this.$t('notification_settings_off'), value: 'none' },
          { label: this.$t('notification_settings_weekly'), value: 'yes' },
        ],
        emailOptionsWithInstant: [
          { label: this.$t('notification_settings_off'), value: 'none' },
          { label: this.$t('notification_settings_instant'), value: 'instant' },
          { label: this.$t('notification_settings_daily'), value: 'daily' },
          { label: this.$t('notification_settings_weekly'), value: 'weekly' },
        ],
        emailOptions: [
          { label: this.$t('notification_settings_off'), value: 'none' },
          { label: this.$t('notification_settings_daily'), value: 'daily' },
          { label: this.$t('notification_settings_weekly'), value: 'weekly' },
        ],
        emailOptionsOnlyInstant: [
          { label: this.$t('notification_settings_off'), value: 'none' },
          { label: this.$t('notification_settings_instant'), value: 'instant' },
        ],
      }
    },
    mixins: [ConceptSearchMixin, TranslationsMixin],
    computed: {
      showPhoneNumber () {
        return this.user.phone_published === 1;
      },
      isPhoneNumberUnchanged () {
        return this.updatedEmailUserObject.phone !== this.initialPhoneNumber
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      isPersonalHomeDisabled () {
        return !!this.$store.state.config.personalHomeDisabled
      },
      isPersonalHomeAvailable () {
        return true
      },
      isEditingNotifications () {
        return !isEqual(this.updatedNotificationsUserObject.settings, this.currentUser().settings)
      },
      isEditingPassword () {
        return !isEqual(this.updatedPasswordUserObject, this.currentUser())
      },
      isEditingEmail () {
        return !isEqual(this.updatedEmailUserObject, this.currentUser()) || this.newEmail
      },
      validNewEmail () {
        if (!this.newEmail || this.newEmail === this.updatedEmailUserObject.email) {
          return false
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(this.newEmail).toLowerCase())
      },
      canOnlyCreateProducts () {
        return this.$store.getters.claimableActorTypes.includes('Product') && this.$store.getters.claimableActorTypes.length === 1
      },
      isNotificationTab () {
        return this.$route.hash === '#notification-settings' || (!this.$route.hash && this.isPersonalHomeDisabled)
      },
      userCanCreate () {
        return this.$store.getters.userCanCreate
      },
      profile () {
        return this.$store.state.user.profile
      },
      languageOptions () {
        return this.enabledLocales.map(locale => ({
          value: locale,
          label: this.labelForLocale(locale),
        }))
      },
      enabledLocales () {
        return this.$store.state.localization.enabledLocales
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      userAcceptedTerms () {
        return this.$store.getters.hasAcceptedTerms
      },
      userTeams () {
        return this.$store.state.user.profile.teams
      },
      weeklyDigest: {
        get () {
          const setting = (this.updatedNotificationsUserObject.settings || {}).weeklyDigest
          const validOptions = this.emailOptionsOnlyWeekly.map(option => option.value)
          return validOptions.includes(setting) ? setting : 'yes'
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.weeklyDigest = v
        },
      },
      userNewsletter: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).newsletter
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.newsletter = v ? 'yes' : ''
        },
      },
      userUpdatesAndFeatures: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).updatesAndFeatures
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.updatesAndFeatures = v ? 'yes' : ''
        },
      },
      userDataScoutsNewsletter: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).dataScoutsNewsletter
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.dataScoutsNewsletter = v ? 'yes' : ''
        },
      },
      userBlogPosts: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).blogPosts
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.blogPosts = v ? 'yes' : ''
        },
      },
      userEventInvites: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).eventInvites
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.eventInvites = v ? 'yes' : ''
        },
      },
      userBetaInvites: {
        get () {
          return !!(this.updatedNotificationsUserObject.settings || {}).betaInvites
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.betaInvites = v ? 'yes' : ''
        },
      },
      hasAccessToMonitoring () {
        return this.$store.getters.hasAccessToMonitoring
      },
      hasAccessToPublisher () {
        return this.$store.getters.hasAccessToPublisher
      },
      isFollowingSearches () {
        return this.followedConceptSearchIds.length > 0
      },
      showSlackSettings () {
        return (
          this.isOwner && this.config.slack && this.config.slack.endpoint
        )
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isSlackEnabled () {
        return this.config.slack && this.config.slack && this.config.slack.endpoint
      },
      displayedCategories () {
        if (!this.updatedNotificationsUserObject || !this.updatedNotificationsUserObject.settings) {
          return []
        }

        const userNotificationSettings = this.updatedNotificationsUserObject.settings.notifications
        const ecosystemNotificationSettings = this.config.notifications
        const result = []

        for (const category of NotificationSettingConfig) {
          const resultCategory = {
            label: category.label === 'Announcement' ? this.announcementLabel : category.label,
            notifications: [],
          }

          for (const notification of category.notifications) {
            if (userNotificationSettings[notification.name] === undefined) {
              continue
            }

            if (this.showSlackSettings && ecosystemNotificationSettings[notification.name] === undefined) {
              continue
            }

            let notificationOptions = [...this.emailOptions] // emailOptionsWithInstant : emailOptions"

            if (category.label === 'Conversation') {
              notificationOptions = [...this.emailOptionsWithInstant]
            }

            if (notification.name === 'actor_claim_needs_curation') {
              notificationOptions = [...this.emailOptionsOnlyInstant]
            }

            resultCategory.notifications.push({
              name: notification.name,
              notificationOptions: notificationOptions,
              label: notification.label.indexOf('_') >= 0 ? this.$t(notification.label, {
                announcements: this.announcementsLabel,
                announcement: this.announcementLabel,
              }) : notification.label,
            })
          }

          if (resultCategory.notifications.length > 0) {
            result.push(resultCategory)
          }
        }

        // If challenges are enabled, display the settings for challenges
        if (this.$store.getters.areChallengesEnabled) {
          const challengesNotifications = {
            label: this.challengesLabel,
            notifications: [
              {
                name: 'new_public_challenge',
                label: this.$t('notification_new_challenge', { challenge: this.challengeLabel }),
                notificationOptions: this.emailOptions
              },
              {
                name: 'new_challenge_comment',
                label: this.$t('notification_challenge_comment', { challenges: this.challengesLabel }),
                notificationOptions: this.emailOptions
              },
              {
                name: 'challenge_pinned',
                label: this.$t('notification_challenge_pinned', { challenge: this.challengeLabel }),
                notificationOptions: this.emailOptions
              },
              {
                name: 'challenge_mentioned_actor',
                label: this.$t('notification_challenge_mentioned'),
                notificationOptions: this.emailOptions
              },
            ],
          }

          result.push(challengesNotifications)
        }

        return result
      },
      curationDigest: {
        get () {
          const setting = (this.updatedNotificationsUserObject.settings || {}).curationDigest

          return !setting ? 'daily' : setting
        },
        set (v) {
          this.updatedNotificationsUserObject.settings.curationDigest = v
        },
      },
      contentDigest: {
        get () {
          const setting = (this.updatedNotificationsUserObject.settings || {}).contentDigest
          return setting || 'none'
        },
        set (v) {
          if (this.contentDigest === 'none' && v !== 'none' && !this.isFollowingSearches) {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTENT_DIGEST_INFO)
          }

          this.updatedNotificationsUserObject.settings.contentDigest = v
        },
      },
    },
    methods: {
      updateShowPhoneNumber (newValue) {
        updatePhoneNumber({ phone: this.user.phone, phone_published: newValue ? 1 : 0 })
          .then(data => {
            trackHeapEvent('updatePhoneNumber.success')
          })
          .catch((errors) => {
            console.log(errors)
          })
      },
      displayLeaveEcosystem () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.USER_LEAVE_ECOSYSTEM)
      },
      userIsActorOwner (actor) {
        if (this.$store.getters.isActor) {
          const claim = this.$store.state.user.profile.claims.find(claim => claim.id === actor.id) || {}
          return claim.claim_type === 'actor-owner' && claim.claim_verified
        }
        return false
      },
      openCreateActorModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      handleClickActorCard (actor) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, actor)
        trackHeapEvent('userProfile.claims.clickActorCard')

        window.location.href = '/actors/' + actor.id
      },
      humanize,
      labelForLocale (locale) {
        return LOCALE_LABELS[locale]
      },
      acceptTerms () {
        acceptTerms().then(response => {
          this.$store.commit('USER/UPDATE_PROFILE', { accepted_terms: response.accepted_terms })
        })
          .catch(error => {
          })
      },
      currentUser () {
        const profile = this.$store.state.user.profile
        return prepareUserForEditing(profile)
      },
      saveUpdatedPhoneNumber () {
        updatePhoneNumber({ phone: this.updatedEmailUserObject.phone, phone_published: true })
          .then(response => {
            this.newPhoneNumberMessage = 'Your phone number is successfully updated'
            trackHeapEvent('saveUpdatedPhoneNumber.success')
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      saveUpdatedEmailAddress () {
        if (!this.isEditingEmail || !this.validNewEmail) {
          return
        }
        this.newEmailErrors = {}
        this.newEmailMessage = ''

        changeEmailRequest(this.newEmail)
          .then(response => {
            this.newEmailMessage = 'An email has been sent to ' + this.newEmail + ' in order to complete the update.'
            this.newEmail = ''
          })
          .catch(errors => {
            this.newEmailErrors = errors
          })
      },
      resetUserObjects () {
        var currentUser = this.currentUser()

        this.user = { ...currentUser }
        this.updatedPasswordUserObject = { ...currentUser }
        this.updatedEmailUserObject = { ...currentUser }
        this.updatedNotificationsUserObject = { ...currentUser }
      },
      async saveUpdatedPassword () {
        if (!this.isEditingPassword) {
          return
        }

        updateProfile({
          current_password: this.updatedPasswordUserObject.current_password,
          password: this.updatedPasswordUserObject.password,
          password_confirmation: this.updatedPasswordUserObject.password_confirmation,
        })
          .then(data => {
            this.errors = {}
            this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
            setTimeout(() => {
              this.resetUserObjects()
            })
            trackHeapEvent('changePassword.success')
          })
          .catch(errors => {
            this.errors = errors
            trackHeapEvent('changePassword.failure', { errorMessage: JSON.stringify(errors) })
          })
      },
      redirectLink (url) {
        var link = ''
        // If the user has a claimed actor, the user is redirected to the actors page
        // Else the user is redirected to the ecosystem home page
        if (this.profiles.length > 0) {
          this.profiles.forEach(profile => {
            if (profile.url == url) {
              link = profile.url + '/actors/' + profile.id
            } else {
              link = url
            }
          })
        } else {
          link = url
        }
        return link
      },
      hidePanel () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      getEmailValue (notificationName) {
        return this.updatedNotificationsUserObject.settings.notifications[notificationName].email.digest
      },
      setEmailValue (notificationName, value) {
        this.updatedNotificationsUserObject.settings.notifications[notificationName].email.enabled = (value !== 'none')
        this.updatedNotificationsUserObject.settings.notifications[notificationName].email.digest = value
      },
      async saveUpdatedNotifications () {
        if (!this.isEditingNotifications) {
          return
        }

        this.saving = true
        const promises = []

        // Apply changes to user settings
        promises.push(
          updateProfile({ settings: this.updatedNotificationsUserObject.settings })
            .then(data => {
              this.errorMessage = null
              this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
              setTimeout(() => {
                this.resetUserObjects()
              })
              this.saving = false
            })
            .catch(error => {
              this.saving = false
            }),
        )

        if (this.showSlackSettings) {
          // Apply changes to ecosystem config
          const formData = removeDefaultsFrom(this.config, {})
          delete formData.possibleCategories
          delete formData.categories
          delete formData.hexBorders
          delete formData.colors

          promises.push(
            updateConfig(formData)
              .then(config => {
                this.errorMessage = null
                this.$store.commit('CONFIG_SET', inert(config))
                this.config = config
              }),
          )
        }

        try {
          await Promise.all(promises)
        } catch (e) {
          console.log(e)
        }
      },
    },
    mounted () {
      if (this.$route.hash === '#personal-home' && this.$store.state.config.personalHomeDisabled) {
        this.$router.push('/profile/update#notification-settings')
      }

      this.initialPhoneNumber = this.updatedEmailUserObject.phone

      fetchActorProfiles()
        .then(profiles => {
          this.profiles = profiles
        })
        .catch()

      if (this.$store.getters.isMember) {
        fetchAndPrepareConfigForEditing().then(config => {
          this.config = config
        })
      }

      this.resetUserObjects()

      this.$bus.on('updateUserProfile', this.resetUserObjects)
    },
    beforeUnmount () {
      this.$bus.off('updateUserProfile')
    },
    components: {
      WithProfileTabs,
      Checkbox,
      Dropdown,
      DsTextarea,
      ToggleButton,
      ActionBar,
      SimpleCard,
      HoverOverlayButton,
      Modal,
      PersonalHome,
    },
  }
</script>

<style
  lang="scss"
  scoped
>
  @import "../../../scss/_variables.scss";

  :deep(.form-group) {
    .form-group__label__text {
      color: var(--primary);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .profile__form {
    margin-left: 0;
    max-width: 700px;

    @media (min-width: $screen-lg) and (max-width: $screen-xl) {
      max-width: 600px;
    }
    @media (max-width: $screen-lg) {
      margin: 0 auto;
    }

    h2.h2 {
      font-size: 18px;
      padding-bottom: 10px;
      color: var(--primary);
      border-bottom: 2px solid var(--primary-extra-lightest);
    }

    .button:disabled {
      background-color: $color-disabled;
      color: white;
    }
  }

  :deep(.action-bar) {
    position: fixed;

    &.action-bar-with-navigation {
      left: 39px;
    }
  }

  .notification-settings-panel, .profile-settings-panel, .personal-home-panel {
    @media (min-width: $screen-lg) {
      padding-left: 0;
      padding-right: 0;
    }

    .notification-settings-panel__settings-table {
      border-collapse: collapse;
      margin: 0 auto;
      @media (min-width: $screen-lg) {
        margin-left: 0;
        max-width: 700px;
      }
      @media (min-width: $screen-lg) and (max-width: $screen-xl) {
        max-width: 600px;
      }

      th {
        border-bottom: 1px solid var(--primary-lightest);
        color: var(--primary);
      }

      td.label-column, td.website-column, td.label-column, td.slack-column td.email-column {
        padding-bottom: 5px;
        padding-top: 5px;
      }
    }
  }

  .notification-settings-panel {
    @media (max-width: $screen-lg) {
      max-width: 590px;
      margin: 0 auto;
    }
  }

  .profile-settings-panel {
    @media (max-width: $screen-lg) {
      margin: 0 auto;
      max-width: 590px;
    }
  }

  .personal-home-panel {
    //allow some space for the box-shadow
    margin: -10px;
    padding: 20px 0;
    @media (max-width: $screen-md) {
      padding: 0 20px 20px 20px;
    }
  }

  :deep(.multiselect.multiselect--datascouts) {
    .multiselect__tags {
      min-height: 28px;
      margin-top: 2px;

      .multiselect__single {
        height: 18px;
        padding-top: 1px;
      }
    }
  }
</style>
