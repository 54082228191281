<template>
  <finalise-onboarding type="login" :is-closeable="false"/>
</template>

<script>
  import FinaliseOnboarding from '../../pages/Onboarding/RegistrationContainer.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'StaticHomeSimplified',
    components: {
      FinaliseOnboarding,
    },
    mounted () {
    },
    beforeUnmount () {
    },
    computed: {
    },
    mixins: [],
    methods: {
    },
    watch: {},
  })
</script>
