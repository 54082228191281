<template>
  <div class="analytics fixed-heading scrollable">
    <div v-if="loading" class="spider__loading">
      <icon name="loading"/>
    </div>
    <div class="heading" v-else-if="!loading">
      <h1>
        ANALYTICS
        <badge :name="totalCount + ' Actors'" variant="primary" class="score-overview__actor-couter" v-if="totalCount"/>
      </h1>
      <p class="guide_text">Analyse the key properties and evolutions in the ecosystem.</p>
      <p v-if="!isSpottingAreaRoute">
        <router-link to="/actors" @click.capture="setListView">Switch to list view</router-link>
      </p>
    </div>

    <div class="analytics-empty" v-if="!loading && this.isAnalyticsEmpty">
      There are no actors in this {{ this.isSpottingAreaRoute ? 'spotting area' : 'portfolio' }} or there are no
      analytics for this portfolio.
    </div>
    <div class="has-padding" v-if="!loading">
      <div v-if="expandedChart" class="expanded-chart">
        <analytics-card :class="{hideTitle: isSpottingAreaRoute}" :title="expandedChart.title" expand enable
                        customAreaSplitEnabled reportSplitEnabled :isExpandedChart="true"
                        :module="expandedChart.id"
                        :splittable="expandedChart.type !== 'DateHistogram'">
          <div class="ellipsis-hover" v-text="expandedChart.subtitle" :title="expandedChart.subtitle"
               @click="removeEllipsis"></div>
          <component :is="expandedChart.is" :chart="expandedChart" expanded/>
        </analytics-card>
      </div>
      <!-- Use v-show here to avoid re-rendering all charts -->
      <div v-show="!expandedChart" class="row">
        <div class="col-xs-12 col-md-6" v-for="chart in enabledCharts" :key="chart.id">
          <div class="chart-card">
            <analytics-card :class="{hideTitle: isSpottingAreaRoute}" :title="chart.title" :expand="expandable" enable
                            :module="chart.id">
              <div class="ellipsis-hover" v-text="chart.subtitle" :title="chart.subtitle" @click="removeEllipsis"></div>
              <component :is="chart.is" :chart="chart" @filter="toggleKeyword"/>
            </analytics-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AnalyticsCard from '../Card/AnalyticsCard.vue'
import chartsMixin from '../Chart/chartsMixin.js'
import Badge from '../Badge/Badge.vue'

import { toPhpTime } from '../Slider/slider.js'

import { CustomFilters } from '../../api/customfilters.js'
import { getAllReportFields } from '../../util/helpers.js'
import { defineComponent } from 'vue'

import { fetchSpottingArea } from '../../api/spottingareas.js'

export default defineComponent({
  components: {
    AnalyticsCard,
    Badge,
  },
  data() {
    return {
      customFilters: [],
      spottingArea: [],
    }
  },
  computed: {
    totalCount() {
      return this.$store.state.actors.listData.total || 0
    },
    isSpottingAreaRoute() {
      return this.$route.path.startsWith('/spotting-areas')
    },
    analytics() {
      return this.$store.state.analytics || {}
    },
    charts() {
      return this.analytics.charts.data
    },
    expandedChart() {
      return this.analytics.charts.expandedChart
    },
    expandable() {
      return !this.$store.getters.activePortfolio || this.$store.getters.activePortfolio.name != 'Personal portfolio'
    },
    filters() {
      return this.$store.state.filters
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    customAreaSplitEnabled() {
      return this.customFilters.length > 0
    },
    optionReportFields() {
      var reportFields = getAllReportFields()

      if (!reportFields) {
        return []
      }

      var optionReportFields = []

      for (var key in reportFields) {
        if (reportFields[key].type == 'options') {
          optionReportFields.push(reportFields[key].label)
        }
      }

      return optionReportFields
    },
    reportSplitEnabled() {
      return this.isMember && this.optionReportFields && this.optionReportFields.length > 0
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isAnalyticsEmpty() {
      const datasets = []

      if (!this.charts) {
        return true
      }

      this.charts.forEach((chart) => {
        if (chart && chart.datasets && chart.datasets[0] && chart.datasets[0].y) {
          chart.datasets.forEach((dataset) => {
            if (dataset.y === null || dataset.y === undefined) {
              datasets.push(0)
            } else {
              datasets.push(dataset.y)
            }
          })
        }

        datasets.push(0)
      })

      const flat = datasets.flat(1).filter(x => x)

      if (flat.length > 0) {
        return false
      } else {
        return true
      }
    },
    loading() {
      return this.$store.state.analytics.charts.loading
    },
  },
  methods: {
    async fetch() {
      const portfolios = []

      const filterObject = {
        ...this.$store.getters.baseFilterObject,
      }

      if (this.$route.params && this.$route.params.spottingAreaId) {
        filterObject.stacked = 'stacked'
        await fetchSpottingArea({ spottingAreaId: this.$route.params.spottingAreaId })
            .then(async (response) => {
              this.spottingArea = response
            })
      }

      if (this.spottingArea && this.spottingArea.portfolios) {
        this.spottingArea.portfolios.forEach((portfolio) => {
          portfolios.push(portfolio.value)
        })
      }

      if (this.$route.path === `/spotting-areas/${this.$route.params.spottingAreaId}/analytics`) {
        delete filterObject['portfolio']
      }

      filterObject.spotting_area = this.$route.params.spottingAreaId

      this.$store.dispatch('ANALYTICS/FETCH_CHARTS', filterObject)

      filterObject.stacked = null

      this.$store.dispatch('ACTORS/FETCH_ACTORS_LIST', Object.assign(
          {},
          filterObject,
          { limit: 10 },
      ))
    },
    toggleKeyword(filter) {
      // This is a legacy check, when portfolios were implemented we didn't allow for any other filters to be active
      // With portfolios being linked to actors, filters + a selected portfolio are possible, and can be enabled here in the analytics as well
      /* if (this.$store.state.filters.portfolio) {
        return
      } */
      if (filter.facet == 'funding_rounds.capital_raised' && Array.isArray(this.$store.state.config.filterControls) && this.$store.state.config.filterControls.includes('funding') && filter.value < 2025 && filter.value > 1970) {
        this.$store.commit('FILTERS/UPDATE_FUNDING_DATE_RANGE', {
          start: toPhpTime(parseInt(filter.value)),
          end: toPhpTime((new Date()).getFullYear() + 1),
        })
      } else if (filter.value < 2090 && filter.value > 1970 && this.$store.state.config.filterControls) { // && this.$store.state.config.filterControls.includes('founding_date')) {
        this.$store.commit('FILTERS/UPDATE_FOUNDED_DATE_RANGE', {
          start: toPhpTime(parseInt(filter.value)),
          end: toPhpTime(parseInt(filter.value)),
        })
      } else if (filter.facet != 'patent' && filter.facet != 'vacancy') { // Exclude unsupported filters
        this.$store.commit('FILTERS/TOGGLE_KEYWORD', filter)
      }
    },
    flipHorizontal(chart) {
      this.$store.dispatch('ANALYTICS/FLIP_HORIZONTAL', chart)
    },
    flipPercentual(chart) {
      this.$store.dispatch('ANALYTICS/FLIP_PERCENTUAL', chart)
    },
    flipAverage(chart) {
      this.$store.dispatch('ANALYTICS/FLIP_AVERAGE', chart)
    },
    removeEllipsis(evt) {
      if (evt && evt.target) {
        evt.target.className = ''
      }
    },
    setListView() {
      this.$store.commit('USER/STORE_SETTINGS', { viewDirectory: 'LIST' })
    },
  },
  created() {
    this.$store.dispatch('ANALYTICS/EXPAND_CHART', null)

    if (this.isMember) {
      CustomFilters.get()
          .then(data => {
            this.customFilters = data
          })
          .catch(errors => {
          })
    }
  },
  async mounted() {
    this.$store.commit('FILTERS/CLEAR_BY_FACET', 'actor_type')
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetch()

        if (!this.expandable) {
          this.$store.dispatch('ANALYTICS/EXPAND_CHART', null)
        }
      },
    },
  },
  mixins: [chartsMixin]
})
</script>

<style lang="scss">
.ellipsis-hover {
  max-width: 100%;
  /* Assigning a fixed width of 50vw resolves the issue where the grid becomes wider than expected */
  width: 50vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.analytics > .heading > p {
  float: left;
}

.analytics-empty {
  margin: 50px auto 0px auto;
  width: fit-content;
}

.hideTitle {
  .infolayer {
    display: none;
  }
}
</style>
