<template>
  <finalise-onboarding type="actor-sign-up" :is-closeable="isCloseable" is-modal/>
</template>
<script>
  import FinaliseOnboarding from '../../pages/Onboarding/RegistrationContainer.vue'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      }
    },
    components: {
      FinaliseOnboarding,
    },
  }
</script>
