export default [
  {
    'label': 'Actor',
    'notifications': [
      { 'name': 'actor_created', 'label': 'New actor added' },
      { 'name': 'actor_claim_needs_curation', 'label': 'Actor claim needs verification' },
      { 'name': 'actor_report_changed', 'label': 'Actor report updated' },
      { 'name': 'actor_report_call_submitted', 'label': 'Actor report call submitted' },
      { 'name': 'actor_raised_funding', 'label': 'Actor raised funding' },
      { 'name': 'actor_employees_changed', 'label': 'Actor employees updated' },
      { 'name': 'actor_files_changed', 'label': 'Actor files changed' },
      { 'name': 'contributor_added', 'label': 'Contributer added to actor' },
      { 'name': 'actor_agent_ready', 'label': 'Agent finished enriching an actor' },
    ],
  },
  {
    'label': 'Portfolio',
    'notifications': [
      { 'name': 'portfolio_ready', 'label': 'Portfolio ready' },
      { 'name': 'portfolio_comment', 'label': 'Portfolio received comments' },
    ],
  },
  {
    'label': 'Concept Search',
    'notifications': [
      { 'name': 'concept_search_finished', 'label': 'Concept search finished searching for new results' },
    ],
  },
  {
    'label': 'Announcement',
    'notifications': [
      { 'name': 'new_public_announcement', 'label': 'notification_settings_new_announcement' },
      { 'name': 'announcement_mentioned_actor', 'label': 'notification_settings_mentioned_actor' },
      { 'name': 'announcement_new_comment', 'label': 'notification_settings_new_comment' },
    ],
  },
  {
    'label': 'Conversation',
    'notifications': [
      { 'name': 'new_conversation_comment', 'label': 'notification_settings_new_conversation_comment' },
    ],
  },
  {
    'label': 'Admin',
    'notifications': [
      { 'name': 'import_status_change', 'label': 'Imports' },
      { 'name': 'data_credits_low', 'label': 'Actor credits low' },
      { 'name': 'user_invited', 'label': 'User has been invited' },
      { 'name': 'user_account_activated', 'label': 'User activated his account' },
    ],
  },
]
