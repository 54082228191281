<template>
  <div>
    <form class="form" @submit.prevent="submit">
      <form-group class="checkbox" label="hidden" is-simplified>
        <checkbox style="transform: scale(1.5)" type="checkbox" v-model="includeCompany" id="includeCompany" is-simplified/>
        <label for="includeCompany">
          <span style="font-size: 26px; font-weight: bold;">&nbsp;{{ $t('signup_add_company_cta') }}</span>
        </label>
      </form-group>
      <h4 class="sub-title">{{ $t('onboarding_actor_sign_up') }}</h4>
      <div class="inputs">
        <form-group is-simplified :label="$t('signup_company_name')" required :errors="errors.company_name">
          <ds-input v-model="companyName" is-simplified/>
        </form-group>
        <form-group is-simplified :label="$t('signup_company_url')" required :errors="errors.company_url">
          <ds-input v-model="companyUrl" is-simplified @blur="enforceHttpProtocolOnUrl"/>
        </form-group>
        <form-group is-simplified :label="$t('signup_company_country')">
          <country-dropdown
            is-simplified id="company-country"
            @update:modelValue="handleCountryChange"
            :model-value="jurisdiction" :disabled="! includeCompany"
          />
        </form-group>
        <form-group
          is-simplified :required="signUpWithCompanyNumber" :label="$t('signup_company_number')"
          :errors="[...(errors.jurisdiction || []), ...(errors.company_number || [])]"
        >
          <div class="company_number">
            <dropdown
              :options="jurisdictionOptions" v-model="jurisdiction" width="50px"
              :search="true" :disabled="! includeCompany" is-simplified
            />
            <ds-input
              placeholder="0650.599.388" name="actor-company-number"
              v-model="companyNumber" :disabled="! includeCompany" is-simplified
            />
          </div>
        </form-group>
        <form-group is-simplified label="hidden">
          <ds-button variant="rounded" :label="$t('actor_detail_next')" type="submit" :disabled="submitting"/>
        </form-group>
      </div>
    </form>
  </div>
</template>
<script>
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import FormGroup from '../../components/Form/FormGroup.vue'
  import { COUNTRIES } from '../../constants/countries.js'
  import CountryDropdown from '../../components/Dropdown/CountryDropdown.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import { fetchProfile, loginRequest, registerRequest } from '../../api/user.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import { ACTION_TYPES as LOCALIZATION_ACTION_TYPES } from '../../store/modules/localization.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import _isEmpty from 'lodash/isEmpty.js'

  export default {
    props: {
      registrationData: Object,
    },
    data () {
      return {
        errors: {},
        includeCompany: true,
        companyName: '',
        companyUrl: '',
        companyCountry: '',
        companyNumber: '',
        jurisdiction: '',
        submitting: false,
        generalPublisher: {},
      }
    },
    computed: {
      signUpWithCompanyNumber () {
        return this.$store.state.config.signUpWithCompanyNumber
      },
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
      },
      companyConfig () {
        let companyUrl = this.companyUrl
        if (!companyUrl.startsWith('https://') && !companyUrl.startsWith('http://')) {
          companyUrl = 'https://' + companyUrl
        }

        return {
          includeCompany: this.includeCompany,
          company_name: this.companyName,
          company_url: companyUrl,
          company_number: this.companyNumber,
          company_country: this.companyCountry,
          jurisdiction: this.jurisdiction,
        }
      },
      allowRegister () {
        return this.$store.getters.allowRegister
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
    },
    methods: {
      handleCountryChange (country) {
        this.jurisdiction = country.code
        this.company_country = country.name
      },
      enforceHttpProtocolOnUrl () {
        if (!this.companyUrl || !this.companyUrl.length) {
          return
        }

        if (!this.companyUrl.startsWith('https://') && !this.companyUrl.startsWith('http://')) {
          this.companyUrl = 'https://' + this.companyUrl
        }
      },
      submit () {
        this.submitting = true

        const data = {
          ...this.registrationData,
          ...this.companyConfig,
          fullRedirectUri: this.fullRedirectUri
        }

        registerRequest(data)
          .then(data => {
            if (data.success) {

              this.errors = {}

              if (data.invitationCompleted) {
                this.invitationCompleted = data.invitationCompleted
              }

              // Only users completing the registration via an invitation can log in immediately
              if (!!this.invitationCompleted) {
                loginRequest({
                  email: window.email,
                  password: this.registrationData.password,
                  remember: true,
                  locale: this.$store.getters.activeLocale,
                })
                  .then(data => {
                    if (data && data.id) {
                      this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, data)
                      this.$store.dispatch(LOCALIZATION_ACTION_TYPES.UPDATE_ACTIVE_LOCALE, { locale: this.$store.getters.activeLocale, i18n: this.$i18n })
                      this.$emit('next')
                    } else {
                      console.log('login failed', data)
                      this.errors.register = 'Your registration token is invalid'
                    }
                  }).catch(err => {
                  console.log('login failed', err)
                  if (this.isRegistrationFlow) {
                    trackHeapEvent('onboarding-private-registration-password-failure')
                  } else {
                    trackHeapEvent('onboarding-public-registration-password-failure')
                  }
                  this.errors.register = 'Your registration token is invalid'
                })
              } else {
                this.$emit('next')
              }

              trackHeapEvent('signUp', {
                name: this.name,
                email: window.email,
              })
            }

            this.submitting = false
          })
          .catch(err => {
            this.submitting = false

            let shouldGoBack = false
            if (this.isRegistrationFlow) {
              trackHeapEvent('onboarding-private-registration-password-failure')
            } else {
              trackHeapEvent('onboarding-public-registration-password-failure')
            }
            if (err.email) {
              this.errors.message = err.email[0]
              shouldGoBack = true
            }

            if (err.accepted_terms) {
              this.errors.accepted_terms = [err.accepted_terms]
              shouldGoBack = true
            }

            if (err.message || typeof err === 'string') {
              this.errors.register = err.message
              shouldGoBack = true
            }

            this.applyErrorsForField(err, 'company_name')
            this.applyErrorsForField(err, 'company_url')
            this.applyErrorsForField(err, 'jurisdiction')
            this.applyErrorsForField(err, 'company_number')

            if (err.accepted_terms) {
              this.errors.accepted_terms = [err.accepted_terms]
              shouldGoBack = true
            }

            if (_isEmpty(this.errors)) {
              this.errors.register = 'Your registration token has expired'
              shouldGoBack = true
            }

            if (shouldGoBack) {
              this.$emit('setRegistrationErrors', this.errors)
            }
          })
      },
      applyErrorsForField (err, field) {
        if (err[field]) {
          this.errors[field] = err[field]

          if (!Array.isArray(this.errors[field])) {
            this.errors[field] = [err[field]]
          }
        }
      }
    },
    mixins: [OnboardingMixin],
    components: {
      Checkbox,
      FormGroup,
      CountryDropdown,
      Dropdown,
    },
  }
</script>

<style lang="scss" scoped>
  .sub-title {
    margin-bottom: 10px;
    font-weight: 400;
  }

  .form {
    font-size: 16px;

    .inputs {
      width: 50%;
    }

    .checkbox {
      display: flex;
    }
  }

</style>
