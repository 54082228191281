<template>
  <div>
    <h1 class="h1">
      {{ $t('onboarding_sign_in_title', { ecosystem: ecosystemName }) }} <span
      v-if="(isExternalConversation || isExternalViewActor) && externalActorName"
    >to {{ isExternalConversation ? 'chat with' : 'view' }} {{
        externalActorName
      }}</span>
    </h1>
    <h4 class="h4">{{ onboardingTextConfig.onboardingSubtitle }}</h4>
    <form @submit.prevent="login">
      <form-group is-simplified label="hidden" :error="emailErrors">
        <ds-input
          v-model="email" is-onboarding-simplified :placeholder="$t('login_email')" autofocus icon="at"
          @update:modelValue="emailErrors=[]"
        />
      </form-group>
      <form-group is-simplified label="hidden" :error="passwordErrors" style="margin-bottom: 10px;">
        <ds-input
          v-model="password" @update:modelValue="passwordErrors = []" is-onboarding-simplified icon="key"
          :placeholder="$t('login_password')" type="password"
        />
      </form-group>

      <p v-for="msg in errors.login" v-text="msg" class="form-msg error"></p>
      <p v-if="message" v-text="message"></p>
      <a href="#" @click.prevent="openForgotPasswordModal">{{
          $t('login_forgot_password')
        }}</a>
      <br>
      <a
        v-if="$store.getters.canRegister"
        href="#"
        @click.prevent="openRegisterModal"
      >{{ $t('onboarding_register_link') }}</a>
      <br>
      <br>
      <form-group is-simplified label="hidden">
        <ds-button
          id="matomo__track-login" type="submit" variant="rounded" :icon="loggingIn ? 'spinner' : ''"
          label="Log In"
          :disabled="!loginFieldsFilled || loggingIn"
        />
      </form-group>
      <p v-if="! socialLoginDisabled">{{ $t('onboarding_login_social') }}</p>
      <div v-if="! socialLoginDisabled">
        <social-auth-buttons
          :method="$t('login_social_start_text')"
        ></social-auth-buttons>
        <br>
      </div>
    </form>
    <a
      v-if="getInTouchEmail && getInTouchEmail.length"
      href="#"
      @click.prevent="handleClickGetInTouch"
      class="onboarding-get-in-touch-link"
    >{{ $t('onboarding_get_in_touch') }}</a>
  </div>
</template>
<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'

  import { loginRequest } from '../../api/user'

  import MODAL_IDS from '../../constants/modal-ids'

  import ls from '../../util/ls'
  import { identifyHeapUserById, trackHeapEvent, trackMatomoEvent } from '../../util/analytics'

  import Raven from 'raven-js'
  import SocialAuthButtons from '../../components/SocialAuthButtons/SocialAuthButtons.vue'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES, MATOMO_EVENT_NAMES } from '../../constants/analytics-constants'
  import _isEmpty from 'lodash/isEmpty.js'

  export default {
    data () {
      return {
        email: window.email || this.$route.query.email || '',
        emailErrors: [],
        message: null,
        password: '',
        passwordErrors: [],
        errors: window.errors || {},
        ecosystemName: '' + window.ecosystemDisplayName + '',
        loggingIn: false,
      }
    },
    computed: {
      getInTouchEmail () {
        return this.$store.getters.ecosystemEmail
      },
      externalActorName () {
        if (Array.isArray(this.$route.query.actor_name)) {
          return this.$route.query.actor_name[0]
        }
        return this.$route.query.actor_name
      },
      isExternalConversation () {
        return this.$route.query.external && this.$route.query.actor_id && this.$route.query.external == 'conversation'
      },
      isExternalViewActor () {
        return this.$route.query.external && this.$route.query.actor_id && this.$route.query.external == 'view-actor'
      },
      closeable () {
        // The modal is not closeable if either the ecosystem does not allow anonymous users
        // or the login is part of an oauth request being resolved
        return this.$store.getters.hasAccessToPublisher && !!this.$store.state.config.allow_anonymous && !window.oauth_request
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      loginFieldsFilled () {
        return this.email && this.password
      },
      smallModal () {
        return this.socialLoginDisabled
      },
      socialLoginDisabled () {
        return this.$store.state.config.socialLoginDisabled == true || !this.$store.getters.canRegister
      },
      partOfOAuth () {
        return window.oauth_request
      },
      title () {
        // Determine the title of the modal, it's not part of the oauth authorization request
        // then just return the "login" title, if it is, then make a custom title explaining to the user
        // why the login is required
        if (this.partOfOAuth) {
          return '<h2 style="display: inline;">LOG IN</h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;with&nbsp;&nbsp;<img src="/images/logos/datascouts-one-line-logo.svg" style="height: 15px; vertical-align: middle;">'
        }

        return 'LOGIN'
      },
    },
    methods: {
      handleClickGetInTouch () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.CLICK, MATOMO_EVENT_NAMES.GET_IN_TOUCH_ON_LOGIN)

        window.open('mailto:' + this.getInTouchEmail, '_blank')
      },
      performValidation () {
        if (!this.email) {
          this.emailErrors = ['The email field is required.']
        }

        if (!this.password) {
          this.passwordErrors = ['The password field is required.']
        }
      },
      login () {
        this.emailErrors = []
        this.passwordErrors = []
        this.loggingIn = true

        this.performValidation()

        if (this.emailErrors.length || this.passwordErrors.length) {
          trackHeapEvent('onboarding-login-invalid-form')
          this.loggingIn = false
          return
        }

        const queryRedirectUri = (this.$route.query && this.$route.query.redirect_uri ? decodeURIComponent(this.$route.query.redirect_uri) : '')

        loginRequest({
          email: this.email.trim(),
          password: this.password,
          remember: true,
          locale: this.$store.getters.activeLocale,
        })
          .then(data => {
            this.loggingIn = false
            if (data && data.id) {
              this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, data)

              identifyHeapUserById(data.id)
            } else {
              console.warn('LoginModal expected a user object')
              window.redirect_uri = window.redirect_uri || '/?user'
            }

            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.LOGIN)

            if (!this.partOfOAuth && !['challenges-detail'].includes(this.$route.name)) {
              if (!window.redirect_uri || (window.redirect_uri && !window.redirect_uri.includes('/events/'))) {

                if (this.closeable && this.$route.path != '/login') {
                  window.redirect_uri = this.$route.path
                } else {
                  window.redirect_uri = '/'
                }
              }
            }

            // Redirect
            if ((window.redirect_uri || '').indexOf('//') > -1) {
              window.location.href = window.redirect_uri
            } else {
              // This (== window.refreshBeforeRedirect = true) is a work around to fetch all the necessary data that comes with a new load
              window.refreshBeforeRedirect = true

              if (!_isEmpty(queryRedirectUri)) {
                // keep the queries filter if there are any
                this.$router.push({ path: queryRedirectUri, query: this.$route.query })
              } else {
                // keep the queries filter if there are any
                this.$router.push({ path: window.redirect_uri || '/', query: this.$route.query })
              }
            }
            trackHeapEvent('onboarding-login-successful')
            trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.CLICK, MATOMO_EVENT_NAMES.LOGGED_IN)
          })
          .catch(data => {
            this.message = data.message
            this.emailErrors = data.email
            this.passwordErrors = data.password
            this.loggingIn = false
            trackHeapEvent('onboarding-login-failed', data)
          })
      },
      openRegisterModal () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.CLICK, MATOMO_EVENT_NAMES.OPEN_REGISTER_MODAL)

        if (this.$route.query) {
          this.$router.push({
            path: '/register',
            query: { email: this.email, redirect_uri: this.$route.redirect_uri || encodeURIComponent(this.$route.path), ...this.$route.query },
          })
        } else {
          this.$router.push({
            path: '/register',
            query: { email: this.email },
          })
        }
      },
      openForgotPasswordModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.FORGOT_PASSWORD)
      },
    },
    created () {
      // There must have been a csrf token error, let's try again
      const csrfLogin = ls('csrfLogin')

      if (csrfLogin) {
        console.warn('Trying to login with a fresh csrf token')
        ls('csrfLogin', false)
        Object.assign(this, csrfLogin)
        this.login()
      }

      if (this.$store.getters.isLoggedIn) {
        Raven.captureMessage('A user landed on the login modal, but is already logged in.')
      }
    },
    components: {
      SocialAuthButtons,
    },
    mixins: [OnboardingMixin],
  }
</script>
<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .onboarding-get-in-touch-link {
    margin-top: auto;
    width: 100%;
    text-align: right;
    display: block;
  }

  .button:disabled {
    color: white;
  }

  a {
    font-size: 10px;
  }
</style>
