<template>
  <div>
    <div class="card" v-if="$store.getters.isDeveloper">
      <p>
        The ecosystem is <span v-if="!isPublicEcosystem">not </span>public<br>
        The registration is <span v-if="!canRegister">not </span>enabled<br>
        The curation is <span v-if="!allowCuratedAdd">not </span>enabled<br>
        The exploration is <span v-if="!isExplorationEnabled">not </span>enabled<br>
      </p>
    </div>

    <div class="has-padding scrollable">
      <div class="has-action-bar">
        <div class="tabs-border">
          <ul class="tabs__navigation">
            <li class="tabs__navigation__label">
              Choose section to edit:
            </li>
            <li
                v-for="tab in tabs"
                class="tabs__navigation__item"
                :class="{ 'tabs__navigation__item--active': activeTab === tab }"
                @click="activeTab = tab"
                v-text="convertTabOptionToLabel(tab)"
            ></li>
          </ul>
        </div>

        <div v-if="activeTab === 'onboarding_intro'">
          <div class="translation-setting-column-wrap">
            <div class="translation-setting-column">
              <h3>Onboarding Intro (EN)</h3>
              <br>
              <NewCard title="Title">
                <ds-input
                    :placeholder="$t('onboarding_title', { ecosystem: this.ecosystemDisplayName, user: 'user' })"
                    v-model="form.onboardingTitle"/>
              </NewCard>
              <NewCard title="Subtitle">
                <ds-input
                    :placeholder="$store.state.config.title"
                    v-model="form.onboardingSubtitle"/>
              </NewCard>
            </div>
            <div class="translation-setting-column">
              <h3>Onboarding Intro (NL)</h3>
              <br>
              <NewCard title="NL Title">
                <ds-input
                    placeholder="Welcome to the expert community of pioneers in ethical and sustainable wearable tech"
                    v-model="form.NLonboardingTitle"/>
              </NewCard>
              <NewCard title="NL Subtitle">
                <ds-input
                    placeholder="By creating you profile you engage in the open dialogue with peers, exploring and co-creating novel approaches to solve common problems as they might turn out to be the next disruptive innovation in wearable technology."
                    v-model="form.NLonboardingSubtitle"/>
              </NewCard>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'onboarding_final'">
          <div class="translation-setting-column-wrap">
            <div class="translation-setting-column">
              <h3>Onboarding Final screen / Personal Home (EN)</h3>
              <br>
              <!--<NewCard title="Final title">
                <ds-input
                    placeholder="Congratulations your profile is created."
                    v-model="onboardingFinalTitle"/>
              </NewCard>
              <NewCard title="Final subtitle">
                <ds-textarea
                    placeholder="Your personal profile will be published upon curation by the WEAR expert team. You will hear from us soon!"
                    v-model="form.onboardingFinalSubtitle"/>
              </NewCard>-->
              <NewCard title="Welcome Title">
                <ds-textarea
                    :default-text="$t('welcome_title')"
                    v-model="form.welcomeTitle"/>
              </NewCard>
              <NewCard title="Welcome Subtitle">
                <ds-textarea
                    :default-text="$t('welcome_subtitle')"
                    v-model="form.welcomeSubtitle"/>
              </NewCard>
              <NewCard title="personal home tab inform title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inform')"
                    v-model="form.informTitle"/>
              </NewCard>
              <NewCard title="personal home tab inspire title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inspire')"
                    v-model="form.inspireTitle"/>
              </NewCard>
              <NewCard title="personal home tab interact title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_interact')"
                    v-model="form.interactTitle"/>
              </NewCard>
              <NewCard title="personal home tab inform description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inform_description')"
                    v-model="form.informDescription"/>
              </NewCard>
              <NewCard title="personal home tab inspire description" v-if="!hasAccessToNewSpottingAreas">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inspire_description')"
                    v-model="form.inspireDescription"/>
              </NewCard>
              <NewCard title="personal home tab inspire description" v-else>
                <ds-textarea
                    :default-text="$t('personal_home_tab_inspire_spotting_areas_description')"
                    v-model="form.inspireDescription"/>
              </NewCard>
              <NewCard title="personal home tab interact description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_interact_description')"
                    v-model="form.interactDescription"/>
              </NewCard>
              <NewCard title="personal home tab relevant actors description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_relevant_actors_description')"
                    v-model="form.relevantActorsDescription"/>
              </NewCard>
              <NewCard title="personal home tab relevant keywords description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_relevant_keywords_description')"
                    v-model="form.relevantKeywordsDescription"/>
              </NewCard>
              <NewCard title="personal home my actors empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_actors_empty_text')"
                    v-model="form.myActorsEmptyText"/>
              </NewCard>
              <NewCard title="personal home my products empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_products_empty_text')"
                    v-model="form.myProductsEmptyText"/>
              </NewCard>
              <NewCard title="personal home my knowledge empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_knowledge_empty_text')"
                    v-model="form.myKnowledgeEmptyText"/>
              </NewCard>
            </div>
            <div class="translation-setting-column">
              <h3>Onboarding Final screen / Personal Home (NL)</h3>
              <br>
              <!--<NewCard title="NL Final title">
                <ds-input
                    placeholder="Congratulations your profile is created."
                    v-model="form.NLonboardingFinalTitle"/>
              </NewCard>
              <NewCard title="NL Final subtitle">
                <ds-textarea
                    placeholder="Your personal profile will be published upon curation by the WEAR expert team. You will hear from us soon!"
                    v-model="form.NLonboardingFinalSubtitle"/>
              </NewCard>-->
              <NewCard title="NL Welcome Title">
                <ds-textarea
                    :placeholder="$t('welcome_title')"
                    v-model="form.NLwelcomeTitle"/>
              </NewCard>
              <NewCard title="NL Welcome Subtitle">
                <ds-textarea
                    :placeholder="$t('welcome_subtitle')"
                    v-model="form.NLwelcomeSubtitle"/>
              </NewCard>
              <NewCard title="NL personal home tab inform title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inform')"
                    v-model="form.NLinformTitle"/>
              </NewCard>
              <NewCard title="NL personal home tab inspire title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inspire')"
                    v-model="form.NLinspireTitle"/>
              </NewCard>
              <NewCard title="NL personal home tab interact title">
                <ds-textarea
                    :default-text="$t('personal_home_tab_interact')"
                    v-model="form.NLinteractTitle"/>
              </NewCard>
              <NewCard title="NL personal home tab inform description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inform_description')"
                    v-model="form.NLinformDescription"/>
              </NewCard>
              <NewCard title="NL personal home tab inspire description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_inspire_description')"
                    v-model="form.NLinspireDescription"/>
              </NewCard>
              <NewCard title="NL personal home tab interact description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_interact_description')"
                    v-model="form.NLinteractDescription"/>
              </NewCard>
              <NewCard title="NL personal home tab relevant actors description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_relevant_actors_description')"
                    v-model="form.NLrelevantActorsDescription"/>
              </NewCard>
              <NewCard title="NL personal home tab relevant keywords description">
                <ds-textarea
                    :default-text="$t('personal_home_tab_relevant_keywords_description')"
                    v-model="form.NLrelevantKeywordsDescription"/>
              </NewCard>
              <NewCard title="NL personal home my actors empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_actors_empty_text')"
                    v-model="form.NLmyActorsEmptyText"/>
              </NewCard>
              <NewCard title="NL personal home my products empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_products_empty_text')"
                    v-model="form.NLmyProductsEmptyText"/>
              </NewCard>
              <NewCard title="NL personal home my knowledge empty text">
                <ds-textarea
                    :default-text="$t('personal_home_my_knowledge_empty_text')"
                    v-model="form.NLmyKnowledgeEmptyText"/>
              </NewCard>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'page_titles'">
          <div class="translation-setting-column-wrap">
            <div class="translation-setting-column">
              <h3>Pages (EN)</h3>
              <br>
              <NewCard title="secondtopbar actors title">
                <ds-textarea
                    :default-text="$t('top_bar_actors')"
                    v-model="form.actorsSecondTopBarTitle"/>
              </NewCard>
              <NewCard title="actors actors title">
                <ds-textarea
                    :default-text="$t('shp_actors_title')"
                    v-model="form.actorsPageTitle"/>
              </NewCard>
              <NewCard title="actors page title">
                <ds-textarea
                    :default-text="$t('actors_simplified_title')"
                    v-model="form.actorsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="actors simplified subtitle">
                <ds-textarea
                    :default-text="$t('actors_simplified_subtitle', { ecosystem: this.ecosystemDisplayName })"
                    v-model="form.actorsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="products page title">
                <ds-textarea
                    :default-text="$t('products_simplified_title', {product: this.productLabel})"
                    v-model="form.productsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="products simplified subtitle">
                <ds-textarea
                    :default-text="this.$t('products_simplified_subtitle', { products: this.productsLabel, ecosystem: this.ecosystemDisplayName })"
                    v-model="form.productsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="challenges page title">
                <ds-textarea
                    :default-text="$t('challenges_title', {challenges: challengesLabel})"
                    v-model="form.challengesSimplifiedTitle"/>
              </NewCard>
              <NewCard title="challenges simplified subtitle">
                <ds-textarea
                    :default-text="$t('challenges_subtitle')"
                    v-model="form.challengesSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="announcements page title">
                <ds-textarea
                    :default-text="$t('announcements_hero_title')"
                    v-model="form.announcementsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="announcements simplified subtitle">
                <ds-textarea
                    :default-text="$t('announcements_subtitle', {events: postsLabel})"
                    v-model="form.announcementsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="events page title">
                <ds-textarea
                    :default-text="$t('events_hero_title')"
                    v-model="form.eventsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="events page subtitle">
                <ds-textarea
                    :default-text="$t('events_hero_subtitle', {events: postsLabel, ecosystem: this.ecosystemDisplayName})"
                    v-model="form.eventsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="knowledge base page title">
                <ds-textarea
                    :default-text="$t('knowledge_base_header_title')"
                    v-model="form.knowledgeBaseSimplifiedTitle"/>
              </NewCard>
              <NewCard title="knowledge base page subtitle">
                <ds-textarea
                    :default-text="$t('knowledge_base_header_subtitle')"
                    v-model="form.knowledgeBaseSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="intelligence page title">
                <ds-textarea
                    :default-text="$t('intelligence_simplified_title')"
                    v-model="form.intelligenceSimplifiedTitle"/>
              </NewCard>
              <NewCard title="intelligence page subtitle">
                <ds-textarea
                    :default-text="$t('intelligence_simplified_subtitle')"
                    v-model="form.intelligenceSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="communities page title">
                <ds-textarea
                    :default-text="$t('communities_simplified_title')"
                    v-model="form.communitiesSimplifiedTitle"/>
              </NewCard>
              <NewCard title="communities page subtitle">
                <ds-textarea
                    :default-text="$t('communities_simplified_subtitle')"
                    v-model="form.communitiesSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="spotting areas page title">
                <ds-textarea
                    :default-text="$t('spotting_areas_simplified_title')"
                    v-model="form.spottingAreasSimplifiedTitle"/>
              </NewCard>
              <NewCard title="spotting areas simplified subtitle">
                <ds-textarea
                    :default-text="$t('spotting_areas_simplified_subtitle')"
                    v-model="form.spottingAreasSimplifiedSubtitle"/>
              </NewCard>
            </div>
            <div class="translation-setting-column">
              <h3>Pages (NL)</h3>
              <br>
              <NewCard title="NL secondtopbar actors title">
                <ds-textarea
                    :default-text="$t('top_bar_actors')"
                    v-model="form.NLactorsSecondTopBarTitle"/>
              </NewCard>
              <NewCard title="NL actors actors title">
                <ds-textarea
                    :default-text="$t('shp_actors_title')"
                    v-model="form.NLactorsPageTitle"/>
              </NewCard>
              <NewCard title="NL actors page title">
                <ds-textarea
                    :default-text="$t('actors_simplified_title')"
                    v-model="form.NLactorsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL products page title">
                <ds-textarea
                    :default-text="$t('products_simplified_title', {product: this.productLabel})"
                    v-model="form.NLproductsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL actors simplified subtitle">
                <ds-textarea
                    :default-text="$t('actors_simplified_subtitle', { ecosystem: this.ecosystemDisplayName })"
                    v-model="form.NLactorsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL products simplified subtitle">
                <ds-textarea
                    :default-text="this.$t('products_simplified_subtitle', { products: this.productsLabel, ecosystem: this.ecosystemDisplayName })"
                    v-model="form.NLproductsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL challenges page title">
                <ds-textarea
                    :default-text="$t('challenges_title', {challenges: challengesLabel})"
                    v-model="form.NLchallengesSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL challenges simplified subtitle">
                <ds-textarea
                    :default-text="$t('challenges_subtitle')"
                    v-model="form.NLchallengesSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL announcements page title">
                <ds-textarea
                    :default-text="$t('announcements_hero_title')"
                    v-model="form.NLannouncementsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL events simplified subtitle">
                <ds-textarea
                    :default-text="$t('events_hero_subtitle', {events: postsLabel})"
                    v-model="form.NLeventsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL events page title">
                <ds-textarea
                    :default-text="$t('events_hero_title')"
                    v-model="form.NLeventsSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL announcements simplified subtitle">
                <ds-textarea
                    :default-text="$t('announcements_subtitle', {announcements: postsLabel})"
                    v-model="form.NLannouncementsSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL knowledge base page title">
                <ds-textarea
                    :default-text="$t('knowledge_base_header_title')"
                    v-model="form.NLknowledgeBaseSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL knowledge base simplified subtitle">
                <ds-textarea
                    :default-text="$t('knowledge_base_header_subtitle')"
                    v-model="form.NLknowledgeBaseSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL intelligence page title">
                <ds-textarea
                    :default-text="$t('intelligence_simplified_title')"
                    v-model="form.NLintelligenceSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL intelligence simplified subtitle">
                <ds-textarea
                    :default-text="$t('intelligence_simplified_subtitle')"
                    v-model="form.NLintelligenceSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL communities page title">
                <ds-textarea
                    :default-text="$t('communities_simplified_title')"
                    v-model="form.NLcommunitiesSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL communities simplified subtitle">
                <ds-textarea
                    :default-text="$t('communities_simplified_subtitle')"
                    v-model="form.NLcommunitiesSimplifiedSubtitle"/>
              </NewCard>
              <NewCard title="NL spotting areas page title">
                <ds-textarea
                    :default-text="$t('spotting_areas_simplified_title')"
                    v-model="form.NLspottingAreasSimplifiedTitle"/>
              </NewCard>
              <NewCard title="NL spotting areas simplified subtitle">
                <ds-textarea
                    :default-text="$t('spotting_areas_simplified_subtitle')"
                    v-model="form.NLspottingAreasSimplifiedSubtitle"/>
              </NewCard>
            </div>
          </div>
        </div>
      </div>
    </div>

    <action-bar :editing="isEditing">
      <ds-button variant="secondary" label="Save changes" @click="save"/>
      <!--      <ds-button variant="" label="Cancel" @click="cancel"/>-->
      <span class="action-bar__message" v-if="success">{{ success }}</span>
    </action-bar>
  </div>
</template>

<script>
  import ActionBar from '../../Form/ActionBar.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import FormGroup from '../../Form/FormGroup.vue'
  import Modal from '../../Modals/Modal.vue'
  import NewCard from '../../NewCard/NewCard.vue'
  import DsInput from '../../Form/DsInput.vue'
  import { fetchTranslations, Translations } from '../../../api/onboarding'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'

  export default {
    data () {
      return {
        failed: {
          categories: [],
          domains: [],
          industries: [],
        },
        show: {
          errors: false,
        },
        errorMessage: null,
        receivedInput: false,
        form: {
          onboarding: {
            questions: {},
          },
        },
        success: false,
        activeTab: 'page_titles',
      }
    },
    computed: {
      tabs () {
        var tabs = ['onboarding_intro', 'onboarding_final', 'page_titles']

        return tabs
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      hasAccessToNewSpottingAreas () {
        return this.$store.getters.hasAccessToNewSpottingAreas
      },
      isExplorationEnabled () {
        return this.$store.getters.hasAccessToExploration
      },
      isPublicEcosystem () {
        return this.$store.getters.isPublic
      },
      canRegister () {
        return this.$store.getters.canRegister
      },
      allowCuratedAdd () {
        return this.$store.getters.allowCuratedAdd
      },
      onboardingFinalTitle: {
        get (title) {
          return this.form.onboardingFinalTitle && this.form.onboardingFinalTitle ? this.form.onboardingFinalTitle : ''
        },
        set (title) {
          this.form.onboardingFinalTitle = title
        },
      },
      onboardingSectionOneQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_1 ? this.form.onboarding.questions.question_1 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_1 = question
          } else {
            this.form.onboarding = { questions: { question_1: question }}
          }
        },
      },
      onboardingSectionOneQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_2 ? this.form.onboarding.questions.question_2 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_2 = question
          } else {
            this.form.onboarding = { questions: { question_2: question }}
          }
        },
      },
      onboardingSectionOneQuestionThree: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_3 ? this.form.onboarding.questions.question_3 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_3 = question
          } else {
            this.form.onboarding = { questions: { question_3: question }}
          }
        },
      },
      onboardingSectionTwoQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_4 ? this.form.onboarding.questions.question_4 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_4 = question
          } else {
            this.form.onboarding = { questions: { question_4: question }}
          }
        },
      },
      onboardingSectionTwoQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_5 ? this.form.onboarding.questions.question_5 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_5 = question
          } else {
            this.form.onboarding = { questions: { question_5: question }}
          }
        },
      },
      onboardingSectionThreeQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_6 ? this.form.onboarding.questions.question_6 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_6 = question
          } else {
            this.form.onboarding = { questions: { question_6: question }}
          }
        },
      },
      onboardingSectionThreeQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_7 ? this.form.onboarding.questions.question_7 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_7 = question
          } else {
            this.form.onboarding = { questions: { question_7: question }}
          }
        },
      },
      onboardingSectionThreeQuestionThree: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_8 ? this.form.onboarding.questions.question_8 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_8 = question
          } else {
            this.form.onboarding = { questions: { question_8: question }}
          }
        },
      },
      onboardingSectionThreeQuestionFour: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_9 ? this.form.onboarding.questions.question_9 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_9 = question
          } else {
            this.form.onboarding = { questions: { question_9: question }}
          }
        },
      },
      onboardingSectionThreeQuestionFive: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_10 ? this.form.onboarding.questions.question_10 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_10 = question
          } else {
            this.form.onboarding = { questions: { question_10: question }}
          }
        },
      },
      onboardingSectionThreeQuestionSix: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_11 ? this.form.onboarding.questions.question_11 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_11 = question
          } else {
            this.form.onboarding = { questions: { question_11: question }}
          }
        },
      },
      onboardingSectionThreeQuestionSeven: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_12 ? this.form.onboarding.questions.question_12 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_12 = question
          } else {
            this.form.onboarding = { questions: { question_12: question }}
          }
        },
      },
      onboardingSectionThreeQuestionEight: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.question_13 ? this.form.onboarding.questions.question_13 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.question_13 = question
          } else {
            this.form.onboarding = { questions: { question_13: question }}
          }
        },
      },
      NLonboardingSectionOneQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_1 ? this.form.onboarding.questions.NLquestion_1 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_1 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_1: question }}
          }
        },
      },
      NLonboardingSectionOneQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_2 ? this.form.onboarding.questions.NLquestion_2 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_2 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_2: question }}
          }
        },
      },
      NLonboardingSectionOneQuestionThree: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_3 ? this.form.onboarding.questions.NLquestion_3 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_3 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_3: question }}
          }
        },
      },
      NLonboardingSectionTwoQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_4 ? this.form.onboarding.questions.NLquestion_4 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_4 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_4: question }}
          }
        },
      },
      NLonboardingSectionTwoQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_5 ? this.form.onboarding.questions.NLquestion_5 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_5 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_5: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionOne: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_6 ? this.form.onboarding.questions.NLquestion_6 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_6 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_6: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionTwo: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_7 ? this.form.onboarding.questions.NLquestion_7 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_7 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_7: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionThree: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_8 ? this.form.onboarding.questions.NLquestion_8 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_8 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_8: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionFour: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_9 ? this.form.onboarding.questions.NLquestion_9 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_9 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_9: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionFive: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_10 ? this.form.onboarding.questions.NLquestion_10 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_10 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_10: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionSix: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_11 ? this.form.onboarding.questions.NLquestion_11 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_11 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_11: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionSeven: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_12 ? this.form.onboarding.questions.NLquestion_12 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_12 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_12: question }}
          }
        },
      },
      NLonboardingSectionThreeQuestionEight: {
        get () {
          return this.form.onboarding && this.form.onboarding.questions && this.form.onboarding.questions.NLquestion_13 ? this.form.onboarding.questions.NLquestion_13 : ''
        },
        set (question) {
          if (this.form.onboarding && this.form.onboarding.questions) {
            this.form.onboarding.questions.NLquestion_13 = question
          } else {
            this.form.onboarding = { questions: { NLquestion_13: question }}
          }
        },
      },
      questions () {
        return this.form.onboarding.questions
      },
      isEditing () {
        return true
      },
    },
    methods: {
      convertTabOptionToLabel (tab) {
        switch (tab) {
          case 'onboarding_intro':
            return 'Onboarding Intro'
          case 'onboarding_final':
            return 'Onboarding Final'
          case 'page_titles':
            return 'Page title & subtitles'
          default:
            return tab
        }
      },
      input () {
        this.receivedInput = true
      },
      noInput () {
        this.$nextTick(() => {
          this.receivedInput = false
        })
      },
      save () {
        this.errorMessage = null
        const questions = { ...this.form.onboarding.questions }
        const dataToSave = { ...this.form, ...questions }
        delete dataToSave.onboarding

        // Save the onboarding config
        Translations.post(dataToSave)
          .then(() => {
            this.success = 'saved successfully'
          })
          .catch((error) => {
            console.log('error:', error)
          })
      },
      extractErrorMessage (response) {
        // Assume that it's a validation error
        if (Object.keys(response)[0] && !response.message) {
          this.errorMessage = response[Object.keys(response)[0]][0]
        }
      },
    },
    async mounted () {
      const data = await fetchTranslations()
      this.form.onboarding = {
        questions: {},
      }

      for (const q in data) {
        if (data.hasOwnProperty(q)) {
          if (q.startsWith('question_')) {
            this.form.onboarding.questions[q] = data[q]
          } else {
            this.form[q] = data[q]
          }
        }
      }
    },
    components: {
      ActionBar,
      DsTextarea,
      FormGroup,
      Modal,
      NewCard,
      DsInput,
    },
    mixins: [TranslationsMixin],
  }
</script>

<style>
  .translation-setting-column-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .translation-setting-column {
    width: 48%;
  }
</style>
