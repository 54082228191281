<template>
  <modal :allow-overflow="true" ref="modal" :id="modalId" @close="close" :title="$t('add_actor_title')"
         :is-closeable="true">
    <template v-slot:body>
      <div>
        <form-group v-if="enableSuggestions" :label="$t('add_actor_company_name')" :stable-height="true"
                    :errors="errors.name">
          <suggestion-input
              v-model="actorData.name"
              ref="suggestionInput"
              icon="search"
              :placeholder="suggestionsInputPlaceholder"
              :options="actorSuggestionSettings"
              :render-results="actorSuggestionSettings.renderResults"
              :clear-on-select="false"
              @on-select="handleSelectSuggestion"
              @update:modelValue="resetErrors"
          />
        </form-group>
        <form-group v-else :label="$t('add_actor_person_name')" :stable-height="true" :errors="errors.name">
          <ds-input v-model="actorData.name" @update:modelValue="resetErrors"
                    :placeholder="$t('add_actor_company_name_placeholder')" ref="personNameInput"/>
        </form-group>

        <form-group label="Select a portfolio" :errors="errors.message"
                    v-if="$store.getters.isPortfolioMember || $store.getters.isTeamMember || $store.getters.isOwner && isSpottingAreaDetail">
          <dropdown :options="portfolioOptions" v-model="selectedPortfolio"/>
        </form-group>
        <form-group :label="$t('add_actor_company_email')" :stable-height="true" :errors="emailErrors.email"
                    v-if="isActor">
          <ds-input v-model="actorData.email" :placeholder="$t('add_actor_company_email')" @input="resetErrors"/>
        </form-group>

        <form-group :label="$t('add_actor_company_url')" v-if="actorData.actor_type !== 'Person'" :stable-height="true"
                    :errors="errors.url"
                    :style="actorData.address && 'margin-bottom: 0'">
          <ds-input placeholder="" v-model="actorData.url" @update:modelValue="resetErrors"/>
        </form-group>
        <form-group :label="$t('add_actor_person_email')" v-if="actorData.actor_type === 'Person'" :stable-height="true"
                    :errors="errors.email">
          <ds-input placeholder="" v-model="actorData.email"/>
        </form-group>

        <template v-if="actorData.address">
          <checkbox inline :label="`Use the same contact details as ${parentName}`" v-model="keepAddress"/>
          <br>
          <br>
        </template>

        <div class="row">
          <form-group class="col-xs-12 col-sm-6" :label="$t('add_actor_type')">
            <dropdown force :options="typeOptions" v-model="actorData.actor_type"/>
          </form-group>
          <form-group class="col-xs-12 col-sm-6" :label="getTaxonomyAliasWithDefault('category')"
                      :key="actorData.actor_type" v-if="categoryOptions && categoryOptions.length > 0">
            <dropdown force :options="categoryOptions" v-model="actorData.category"/>
          </form-group>
        </div>

        <div class="row" v-if="shouldShowCompanyNumber">
          <form-group :label="$t('add_actor_company_number')" :errors="errors.company_number" class="col-sm-12">
            <div class="company_number">
              <dropdown :options="jurisdictionOptions" v-model="actorData.jurisdiction" width="50px" :search="true"/>
              <ds-input :placeholder="$t('add_actor_company_number')" name="actor-number"
                        v-model="actorData.company_number" @update:modelValue="resetErrors"/>
            </div>
          </form-group>
        </div>

        <form-group label="Tags">
          <!-- For some reason, vue-tags-input doesn't correctly lose focus in this modal, which is why nofocus is true (disables focus outline) -->
          <AutocompleteTagInput
              :tags="actorData.tags"
              :options="tagOptions"
              :nofocus="true"
              :openUpwards="true"

              :placeholder="$t('add_actor_tags_placeholder')"
              @tagChanged="handleTagChanged"
              @tagsChanged="updateTags"
          />
        </form-group>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <template v-if="$store.getters.isActor">
          <template v-if="claimMessage">
            <div style="display: flex; align-items: flex-end;">
              <div style="text-align: left; margin-right: auto;">{{ claimMessage }}</div>
              <ds-button
                  variant="secondary"
                  label="Ok"
                  @click="close"
              />
            </div>
          </template>
          <template v-else>
            <ds-button
                :label="$t('add_actor_claim_profile')"
                variant="secondary"
                @click="claimActor(errors.id)"
                :disabled="busy"
                v-if="errors.id"
            />
            <ds-button
                @click="close"
                :label="addedActors.length ? 'Stop adding' : $t('add_actor_cancel_button')"
                variant="outline"
                :disabled="busy"
            />
            <ds-button
                :label="$t('add_actor_add_button')"
                variant="secondary"
                :icon="busy ? 'spinner' : 'plus'"
                :disabled="!isActorValidForCreation || busy"
                @click="addActor"
                v-if="! errors.id"
            />
          </template>
        </template>
        <template v-else>
          <ds-button
              :href="'/actors/' + errors.id"
              label="Show existing actor"
              variant="outline"
              v-if="errors.id"
          />

          <ds-button
              @click="close"
              :label="addedActors.length ? 'Stop adding' : 'Cancel'"
              variant="outline"
              :disabled="busy"
          />
          <ds-button
              :label="$t('add_actor_add_button')"
              variant="secondary"
              :icon="busy ? 'spinner' : 'plus'"
              :disabled="!isActorValidForCreation || busy"
              @click="addActor"
          />
        </template>

        <div class="added-actors-container" v-if="addedActors.length > 0">
          <div class="added-actors-container__title">
            <span>Actors added so far</span>
          </div>
          <transition-group name="added-actors" tag="div">
            <a
                class="card add-actor__added"
                v-for="actor in addedActors.slice().reverse()"
                :key="actor.name"
                :href="'/actors/' + actor.id"
                @click.prevent="clickActor(actor.id)"
            >
              <div class="form-group__error pull-right text-right" v-if="actor.sameAs">
                Already an actor
              </div>
              <div class="pull-right text-right" v-else-if="!actor.id">
                Saving...
              </div>
              <div v-else class="card__sub-title pull-right">{{ subtitle(actor) }}</div>
              <h1 class="card__title">{{ actor.name }}</h1>
            </a>
          </transition-group>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import debounce from 'lodash/debounce'

import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids'

import RadioButton from '../Form/RadioButton.vue'
import DsInput from '../Form/DsInput.vue'
import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
import Dropdown from '../Dropdown/Dropdown.vue'
import FormGroup from '../Form/FormGroup.vue'
import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'

import TagsMixin from '../../util/TagsMixin'
import ConfigMixin from '../../util/ConfigMixin'
import escapeHTML from '../../util/escapeHTML'
import { trackHeapEvent } from '../../util/analytics'
import { extractDomain } from '../../util/actor'
import { COUNTRIES } from '../../constants/countries'

import { claimActor, createActor, validateActor } from '../../api/actors'
import { fetchProfile } from '../../api/user.js'
import { fetchAllPortfolios } from '../../api/portfolios'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

import CountryDropdown from '../Dropdown/CountryDropdown.vue'
import Checkbox from '../Form/Checkbox.vue'
import TranslationsMixin from '../../util/TranslationsMixin.js'

/*
  Modal context options keys:
      * prefilled: data to fill into actorData
      * afterCreate: action to take after creating the actor, one of "addMore", "goToActor", "close" (defaults to "close")
 */

export default {
  data () {
    const modalContext = this.$store.state.ui.modalContext || {}

    return {
      modalId: MODAL_IDS.ADD_ACTOR,
      actorData: emptyActor({
        category: null,
        ...modalContext.prefilled,
      }),
      keepAddress: false,
      parentName: '',
      actorSuggestionSettings: {
        apiSettings: {
          url: '/actors/autocomplete?query={query}',
          onResponse: data => ({
            results: Object.values(data).map(({
                                                name,
                                                logo,
                                                domain,
                                                jurisdiction,
                                                company_number,
                                                founding_date,
                                                email
                                              }) => ({
              name,
              logo,
              url: domain,
              jurisdiction,
              company_number,
              founding_date,
              email,
            })),
          }),
        },
        showNoResults: false,
        renderResults: renderActorSuggestionResults,
      },
      selectedPortfolio: null,
      busy: false,
      errors: {},
      emailErrors: {},
      claimMessage: '',
      addedActors: [],
      portfolios: [],
    }
  },
  computed: {
    isSpottingAreaDetail () {
      return this.$route.name === 'spotting-area-detail'
    },
    modalContext () {
      return this.$store.state.ui.modalContext || {}
    },
    includeCompanyNumberInSearch () {
      return this.$store.state.config.includeCompanyNumberInSearch
    },
    enableSuggestions () {
      return this.$store.getters.canUseActorAutocomplete && this.actorData.actor_type === 'LegalEntity'
    },
    actorTypeMapping () {
      return {
        LegalEntity: this.$t('add_actor_legal_entity_label'),
        Person: this.$t('add_actor_person_label'),
        Product: this.productLabel,
      }
    },
    typeOptions () {
      return this.$store.getters.viewActorTypes
          .filter(actorType => {
            return actorType !== 'Product' || (this.modalContext.prefilled && this.modalContext.prefilled.parentName)
          })
          .map(actorType => ({
                value: actorType,
                label: this.actorTypeMapping[actorType] || actorType,
                disabled: this.$store.getters.isActor && !this.$store.getters.claimableActorTypes.includes(actorType),
              }),
          ).filter(type => {
            return !type.disabled
          })
    },
    categoryOptions () {
      const list = this.categoryValuesAndLabelsPerType[this.actorData.actor_type] || []

      if (list.length === 0) {
        return []
      }

      var categories = list.slice(0)

      categories = categories.sort((a, b) => {
        return a.label > b.label
      })

      return categories.map(item => ({
        value: item.value,
        label: item.label,
      }))
    },
    portfolioOptions () {
      const portfolios = this.portfolios || []

      return portfolios.map(item => ({
        value: item.id,
        label: item.name,
      }))
    },
    jurisdictionOptions () {
      // Filter out the UK, it's not considered a legal jurisdiction
      return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
    },
    isVatSuggestionEnabled () {
      return this.$store.state.config.kboSuggestionsEnabled
    },
    suggestionsInputPlaceholder () {
      if (this.isVatSuggestionEnabled === true) {
        return this.$t('add_actor_company_name_by_vat_placeholder')
      } else {
        return this.$t('add_actor_company_name_placeholder')
      }
    },
    isActorValidForCreation () {
      if (this.isActor) {
        return this.$store.getters.userCanCreate &&
            this.actorData.name &&
            this.actorData.email &&
            Object.keys(this.emailErrors).length === 0 &&
            Object.keys(this.errors).length === 0
      } else {
        return this.$store.getters.userCanCreate &&
            this.actorData.name &&
            Object.keys(this.errors).length === 0
      }
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isActor () {
      return this.$store.getters.isActor
    },
    userPortfolioContributorIds () {
      return this.$store.getters.userContributionPortfolios
    },
    shouldShowCompanyNumber () {
      const companyNumberForced = (
          this.modalContext &&
          this.modalContext.prefilled &&
          this.modalContext.prefilled.company_number
      )

      return (
          this.actorData.actor_type === 'LegalEntity' &&
          (this.includeCompanyNumberInSearch || companyNumberForced)
      )
    },
    action () {
      return this.announcement.id ? 'actor' : 'product'
    },
  },
  methods: {
    resetErrors () {
      this.errors = {}
      this.emailErrors = {}
      this.validateActor()
      if (this.isActor) {
        this.validateEmail()
      }
    },
    validateActor () {
      if (this.actorData.name && this.actorData.name.length < 2) {
        // This error occurs way to many times as a user is typing, play it smart and handle this front-end
        this.errors = { 'name': ['The name must be at least 2 characters.'] }
      } else {
        validateActor(Object.assign({}, this.actorData, {
          logo: null,
          portfolio_id: this.selectedPortfolio,
          spotting_area_id: this.$route.name === 'spotting-area-detail' ? this.$route.params.spottingAreaId : null,
          add_to_spotting_area: this.$route.name === 'spotting-area-detail',
        }))
            .then(() => {
              this.errors = {}
            })
            .catch(this.handleErrors)
      }
    },
    validateEmail () {
      if (this.$store.state.user.profile.email === this.actorData.email) {
        this.emailErrors = { 'email': ['the company email address cannot be the same as the email address you are logged in with'] }
      } else {
        this.emailErrors = {}
      }
    },
    handleSelectSuggestion (actor) {
      this.resetErrors()
      // Apply in next tick to fix v-model issue
      this.$nextTick(() => {
        Object.assign(this.actorData, withoutUndefinedValues(actor), { id: null })
        this.validateActor()
      })
    },
    updateTags (tags) {
      this.actorData.tags = tags
    },
    handleTagChanged (tag) {
      // Update the options according to the given tag
      this.updateTagOptions(tag)
    },
    addActor () {
      trackHeapEvent('addActorModal.addActor')

      this.busy = true
      let parameters = []

      if (!this.keepAddress) {
        delete this.actorData.address
      }

      // These are the default settings when creating a new actor.
      // New default settings can be added, removed or modified when necessary.
      const defaultSettings = {
        logo: null,
        is_email_published: true,
      }

      // If The user is a portfolio memmber and is a contributor of at least one portfolio
      if ((this.$store.getters.isPortfolioMember) && this.userPortfolioContributorIds.length > 0) {
        // If there is a portfolio selected, send the portfolio id in the request
        if (this.selectedPortfolio) {
          parameters = Object.assign({}, this.actorData, {
            portfolio_id: this.selectedPortfolio,
            add_to_spotting_area: true
          }, defaultSettings)
        } else {
          // Else send the id of the firts portfolio if there are portfolios
          if (this.portfolios.length > 0) {
            parameters = Object.assign({}, this.actorData, {
              portfolio_id: this.portfolios[0].id,
              add_to_spotting_area: true
            }, defaultSettings)
          }
        }
      } else {
        // Else don't send the portfolios id
        parameters = Object.assign({}, this.actorData, defaultSettings)
      }

      if (this.isMember && this.modalContext.forceClaim) {
        parameters.forceClaim = true
      }

      if (this.isMember && this.selectedPortfolio) {
        parameters = Object.assign({}, this.actorData, {
          portfolio_id: this.selectedPortfolio,
          add_to_spotting_area: true
        }, defaultSettings)
      }

      createActor(parameters)
          .catch(error => {
            if (error && error.id) {
              if (!this.isMember) {
                // Go to profile page
                this.$router.push('/actors/' + error.id)
                this.close()
                throw new Error()
              }
            }
            console.error(error)
            throw error
          })
          .then(actor => {
            if (!actor || !actor.id) {
              throw new Error('finishCompletion expected an actor as return value')
            }

            this.$bus.emit('actorCreated', { id: actor.id, name: actor.name })
            trackHeapEvent('global.addActor', { name: actor.name, id: actor.id })

            if (this.modalContext.successCallback) {
              try {
                this.modalContext.successCallback(actor)
              } catch (e) {
                console.error('Problem with success callback (from modal context)')
                console.error(e)
              }
            }

            this.actorData = emptyActor(this.actorData)
            this.errors = {}
            this.$refs.suggestionInput && this.$refs.suggestionInput.clear()

            if (!this.modalContext.afterCreate) {
              // Update user profile
              this.$store.commit('USER/HAS_CLAIMED', actor)
              fetchProfile()
                  .then(profile => {
                    this.$store.commit('USER/UPDATE_PROFILE', profile)
                    this.$bus.emit('updateUserProfile')

                    this.close()
                  })
            } else if (this.modalContext.afterCreate === 'goToActor') {
              // Update user profile
              this.$store.commit('USER/HAS_CLAIMED', actor)
              fetchProfile()
                  .then(profile => {
                    this.$store.commit('USER/UPDATE_PROFILE', profile)
                    this.$bus.emit('updateUserProfile')

                    // Go to profile page
                    this.$router.push('/actors/' + actor.id)
                    this.close()
                  })
            } else if (this.modalContext.afterCreate === 'addMore') {
              // Prepare to add next
              this.addedActors.push(actor)
            } else {
              this.close()
            }
          })
          .catch(this.handleErrors)
          .then(() => {
            this.busy = false
          })
    },
    claimActor (actorId) {
      this.busy = true

      claimActor(actorId)
          .then(response => {
            this.busy = false
            this.claimMessage = response.message
          })
          .catch(errors => {
            this.errors.claim = errors || {}
            this.claimMessage = ''
          })
    },
    handleErrors (errors) {
      this.errors = errors || {}

      if (this.errors.name && this.errors.id && this.$store.getters.isActor) {
        this.errors.name = ['An actor with similar properties already exists. You can claim the profile via the button below or change the properties.']
      }
    },
    close () {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
      this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
      this.$emit('close')
      this.$bus.emit('closeAddActorModal')
    },
    fetchPortfolios () {
      let filters = {}
      if (this.$route.name === 'spotting-area-detail') {
        filters = { spottingAreaId: this.$route.params.spottingAreaId }
      }
      this.portfolios = []
      fetchAllPortfolios(filters)
          .then(portfolios => {
            this.portfolios.push(portfolios)

            this.selectedPortfolio = this.portfolios[0].id
          })
          .catch(errors => {
            this.errors = errors
          })
    },
    subtitle ({ url, category, industries, type }) {
      return [
        type,
        category,
        industries && industries.join && industries.join(', '),
        extractDomain(url || ''),
      ].filter(Boolean).join(' - ')
    },
    clickActor (id) {
      this.$router.push('/actors/' + id)
      this.close()
    },
    actorCategoryIsValid (actorData) {
      for (const option of this.categoryOptions) {
        if (option.value === actorData.category) {
          return true
        }
      }

      return false
    },
  },
  watch: {
    'actorData.actor_type': function (newVal, oldVal) {
      // TODO: this line isn't necessary anymore
      this.actorData.company_type = 'Headquarter'
      this.actorData.category = null

      if (!this.actorCategoryIsValid(this.actorData) && this.categoryOptions[0]) {
        this.actorData.category = this.categoryOptions[0].value
      }
    },
  },
  created () {
    this.validateActor = debounce(this.validateActor, 150)
  },
  mounted () {
    if (this.$store.getters.isActor && !this.$store.getters.claimableActorTypes.includes(this.actorData.actor_type)) {
      this.actorData.actor_type = this.$store.getters.claimableActorTypes[0]
    }

    if (this.modalContext.globalsearchSearchQuery) {
      this.actorData.name = this.modalContext.globalsearchSearchQuery
    }

    if ((this.$store.getters.isTeamMember || this.$store.getters.isOwner)
        || (this.$store.getters.isPortfolioMember && this.userPortfolioContributorIds.length > 0) && this.isSpottingAreaDetail) {
      this.fetchPortfolios()
    }

    // Initialize url search when actor name is prefilled
    if (this.actorData && this.actorData.name) {
      this.$refs.suggestionInput.$refs.mainInput.click()
    }

    if (this.$refs.suggestionInput) {
      this.$refs.suggestionInput.focus()
    } else if (!this.$refs.suggestionInput && this.$refs.personNameInput) {
      this.$refs.personNameInput.focus()
    }

    if (this.actorData.address) {
      this.keepAddress = true
      this.parentName = this.actorData.parentName
      delete this.actorData.parentName
    }
  },
  mixins: [TagsMixin, ConfigMixin, TranslationsMixin],
  components: {
    Checkbox,
    CountryDropdown,
    Modal,
    RadioButton,
    DsInput,
    SuggestionInput,
    Dropdown,
    FormGroup,
    AutocompleteTagInput,
  },
}

function emptyActor ({ actor_type, ...other }) {
  return {
    id: null,
    actor_type: actor_type || 'LegalEntity',
    company_type: 'Headquarter',
    name: null,
    url: null,
    logo: null,
    company_number: null,
    jurisdiction: null,
    tags: [],
    email: null,
    ...other,
  }
}

function withoutUndefinedValues (value) {
  const result = {}

  for (const key in value) {
    if (value.hasOwnProperty(key) && value[key] !== undefined) {
      result[key] = value[key]
    }
  }

  return result
}

function renderActorSuggestionResults (response) {
  return response.results.map(r => {
    if (r.logo) {
      return `
                  <a class="result company-suggestion">
                    <img class="company-suggestion__avatar" src="${r.logo}" />
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                      <div class="company-suggestion__domain">${escapeHTML(r.url)}</div>
                    </div>
                  </a>
                `
    }

    if (r.company_number) {
      return `
                  <a class="result company-suggestion">
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                      <div class="company-suggestion__domain">${escapeHTML(r.jurisdiction)}${escapeHTML(r.company_number)}</div>
                    </div>
                  </a>
                `
    }

    return `
                  <a class="result company-suggestion">
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    </div>
                  </a>
                `
  })
}
</script>

<style lang="scss" scoped>
.row {
  margin-bottom: 0;
}

.added-actors-container {
  text-align: left;
  margin-top: 20px;
}

.added-actors-container__title {
  position: relative;

  span {
    background-color: white;
    z-index: 1;
    position: relative;
    padding-right: 10px;
    color: #999;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ccc;
  }
}

.add-actor__added {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
