<template>
  <finalise-onboarding type="forgot-password" :is-closeable="isCloseable" is-modal/>
  <!--<forgot-password-modal-old v-else @close="$emit('close')" :is-closeable="isCloseable"/>-->
</template>
<script>
  import FinaliseOnboarding from '../../pages/Onboarding/RegistrationContainer.vue'
  import ForgotPasswordModalOld from './ForgotPasswordModalOld.vue'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      }
    },
    components: {
      ForgotPasswordModalOld,
      FinaliseOnboarding,
    },
  }
</script>
