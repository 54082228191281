<template>
  <div class="static-top-bar" :class="{'static-top-bar-PnG': isPnG, 'static-top-bar-innovaud' : isInnovaud, 'static-top-bar-isFoodleap': isFoodleap }">
    <div style="display: flex;">
      <img
        :src="leftLogoUrl" :alt="config.name"
        class="static-logo-image"
      >
      <div
        style="margin-left: auto; display: flex; flex-direction: column; justify-content: center"
      >
        <div>
          <ds-button
            variant="primary"
            label="BAwear Score"
            style="background-color: #1064DC; margin-right: 2rem;"
            href="https://ellieconnect.com/bawear-score-tool"
            v-if="false && this.$store.getters.isAriadne"
          />
          <ds-button
            variant="secondary"
            :label="$t('action_register')"
            :size="isPnG ? 'extra-small' : null"
            :style="registerButtonStyle"
            @click="openSignupModal"
            v-if="!isInnovaud"
          />
          <!--      Button for go to the landing page from BAwear     -->
          <ds-button
            variant="primary"
            label="Login"
            :style="loginButtonStyle"
            :size="isPnG ? 'extra-small' : null"
            @click.prevent="openSigninModal"
          />
        </div>
      </div>
    </div>
    <img v-if="isPnG" :src="config.logo_url" alt="" class="PnG-logo">
  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { lighten } from 'polished'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    methods: {
      openSigninModal () {
        trackHeapEvent('staticHomePage.topBarLogin')

        this.$router.push('/login')
      },
      openSignupModal () {
        trackHeapEvent('staticHomePage.topBarRegister')
        if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else if (this.$store.state.config.allowAdd) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
    },
    computed: {
      leftLogoUrl () {
        if (this.isPnG) {
          return '/images/static-home-page/inqbet-logo.png'
        }
        return this.config.logo_url
      },
      isPnG () {
        return this.$store.getters.isPnG
      },
      isInnovaud () {
        return this.$store.getters.isInnovaud
      },
      isFoodleap () {
        return this.$store.getters.isFoodleap
      },
      registerButtonStyle () {
        if (this.isPnG) {
          return {
            backgroundColor: 'var(--primary)',
            color: 'white',
            fontFamily: `'Fira Sans', sans-serif`,
            fontWeight: '500',
            height: '22px',
          }
        }
        return {
          backgroundColor: 'white',
          color: 'var(--primary)',
          fontFamily: '',
          fontWeight: '',
          height: 'auto',
        }
      },
      loginButtonStyle () {
        if (this.isPnG) {
          return {
            backgroundColor: '#FEDA00',
            fontFamily: `'Fira Sans', sans-serif`,
            fontWeight: '500',
            color: 'white',
            height: '22px',
          }
        }
        if (this.isInnovaud) {
          return {
            backgroundColor: 'var(--primary)',
            fontFamily: '',
            fontWeight: '',
            color: 'white',
            height: 'auto',
          }
        }
        return {
          backgroundColor: 'var(--primary-extra-darkest)',
          fontFamily: '',
          fontWeight: '',
          color: 'white',
          height: this.isPnG ? '22px' : 'auto',
        }
      },
      config () {
        return this.$store.state.config
      },
    },
    mounted () {
    },
    beforeUnmount () {
    },
    mixins: [],
    components: {},
  })
</script>

<style scoped lang="scss">
  @import "resources/assets/scss/variables";

  .static-logo-image {
    max-height: 40px;
    height: auto;
    max-width: calc(100vw - 100px - 190px);
    object-fit: contain;
  }

  .static-top-bar {
    padding: 20px 50px;
    background: var(--primary);

    &.static-top-bar-PnG {
      padding: 20px;
      background: white;

      .static-logo-image {
        max-height: 29px;
      }
    }

    &.static-top-bar-innovaud {
      background: #2A3646;
    }
  }

  .PnG-logo {
    $logoSize: 100px;
    z-index: 1;
    position: absolute;
    top: calc(69px - calc($logoSize / 2));
    left: calc(50% - calc($logoSize / 2));
    width: $logoSize;
    @media (max-width: $screen-md) {
      $logoSize: 10vw;
      top: calc(69px - calc($logoSize / 2));
      left: calc(50% - calc($logoSize / 2));
      width: $logoSize;
    }
  }
</style>
