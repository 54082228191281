<template>
  <div class="row conversation-detail" style="height: 100%;">
    <!-- Conversation left side: navigation -->
    <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 col-xl-2 conversation-detail__left">
      <div class="conversation__title-container">
        <h2 class="h2">
          {{ $t('conversations_title') }}
        </h2>
        <p style="padding: 0 20px;margin-bottom: 20px;">Get new conversations started by searching for users or
          actors.</p>
      </div>
      <div class="conversation__search-container">
        <ds-input
          :disableAutoComplete="true"
          :icon="loadingSearchQuery ? 'spinner' : 'search'"
          type="search"
          ref="searchInput"
          class="input-field"
          :placeholder="'Type to search by username, email or claim...'"
          v-model="filters.searchQuery"
        />
      </div>

      <!-- Conversation left side: list -->
      <div class="conversation-preview__container">
        <loading v-if="loadingSearchQuery"/>

        <!-- List: suggested conversations -->
        <div
          v-if="actorsCanBeContacted && !loadingSearchQuery && filters.searchQuery"
          class="conversation-suggestions__container"
        >
          <template v-for="suggestedConversation in suggestedConversations">
            <div class="conversation-preview">
              <div class="conversation-preview-avatar">
                <avatar
                  :src="suggestedConversation.photo_url"
                  :alt="suggestedConversation.name"
                  variant="border"
                />
              </div>
              <div class="conversation-preview-text">
                <div class="conversation-preview__title">
                  {{ suggestedConversation.name }}
                  <template v-if="suggestedConversation.claims.length > 0">
                    (<span v-for="(actor, actorIndex) in suggestedConversation.claims">
                        <router-link :to="`/actors/${actor.actor_elastic_id}`">
                          {{ actor.actor_name }}
                        </router-link><span v-if="actorIndex !== suggestedConversation.claims.length - 1">, </span>
                    </span>)
                  </template>
                </div>
                <div class="conversation-preview__content">
                  <rich-body-content
                    :html="suggestedConversation.bio ? suggestedConversation.bio :'No user bio provided.'"
                  />
                </div>
                <div class="conversation-preview__actions">
                  <button
                    @click.prevent="startConversation(suggestedConversation.id)"
                    class="button button--rounded button--small" style="margin-top: 7px;"
                  >Start conversation
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>

        <!-- List: ongoing suggestions -->
        <div v-if="!loadingSearchQuery" class="conversation-ongoing__container">
          <router-link
            :to="`/profile/conversations/${conversation.id}`"
            @click="selectConversation(conversation.id, true)"
            v-for="(conversation, index) in conversations" :key="index"
          >
            <div
              class="conversation-preview"
              :class="{'selected': conversation && Number(conversationId) === conversation.id}"
            >
              <div class="conversation-preview-avatar">
                <template v-if="conversation.creator_name === userName">
                  <avatar
                    :src="conversation.recipient_photo_url"
                    :alt="conversation.recipient_name" variant="border"
                  />
                </template>
                <template v-else-if="conversation.recipient_name === userName">
                  <avatar
                    :src="conversation.creator_photo_url"
                    :alt="conversation.creator_name"
                    variant="border"
                  />
                </template>
              </div>
              <div class="conversation-preview-text">
                <div class="conversation-preview__subtitle">
                  {{ timeAgoTranslated(conversation.updated_at) }}
                </div>
                <div
                  class="conversation-preview__title"
                  v-if="conversation.creator_name === userName"
                >
                  {{ conversation.recipient_name }}
                </div>
                <div
                  class="conversation-preview__title"
                  v-else-if="conversation.recipient_name === userName"
                >
                  {{ conversation.creator_name }}
                </div>
                <div class="conversation-preview__content">
                  <rich-body-content
                    :html="conversation.last_comment ? conversation.last_comment.comment : $t('conversation_no_last_comment')"
                  />
                </div>
              </div>
            </div>
            <badge-counter
              no-count v-if="!conversation.is_read_by_user"
              class="conversation-preview__badge"
            />
          </router-link>
        </div>
      </div>
    </div>

    <!-- Conversation right side: conversation comments -->
    <div
      class="col-xs-12 col-sm-8 col-md-6 col-lg-8 col-xl-10 conversation-detail__right"
      v-if="(conversations && conversations.length || suggestedConversations && suggestedConversations.length)"
    >
      <div class="notification-detail__comments-container" ref="commentsContainer">
        <template v-if="conversation && Number(conversationId) === conversation.id">
          <div class="notification-detail__conversation-title">
            <template v-if="conversation.creator_name === userName">
              <avatar
                :src="conversation.recipient_photo_url"
                :alt="conversation.recipient_name" variant="border"
              />
              <div class="notification-detail__conversation-user">
                <template v-if="canAccessUserInformationPanel">
                  <div style="display: flex; flex-direction: row; justify-content: flex-start; gap: 5px;">
                    {{ conversation.recipient_name }}
                    <a
                      class="notification-detail__conversation-user__link"
                      @click.prevent="openUserSidePanel(conversation.recipient_id)"
                    >
                      <icon name="information-outline" size="14"/>
                    </a>
                  </div>
                </template>
                <template v-else>
                  {{ conversation.recipient_name }}
                </template>
                <span class="recipient_department">{{ receivingDepartment }}</span>
                <template
                  v-if="conversation.recipient_actors"
                >
                  <div class="recipient-actors">
                    <span v-for="(actor, actorIndex) in conversation.recipient_actors">
                      <router-link v-if="actor.is_accessible" :to="`/actors/${actor.id}`">
                        {{ actor.name }}
                      </router-link>
                      <span v-else>{{ actor.name }}</span>
                      <span v-if="actorIndex != conversation.recipient_actors.length - 1">, </span>
                    </span>
                  </div>
                </template>
                <template
                  v-else-if="conversation.recipient_actor_name && conversation.recipient_actor_id && conversation.recipient_actor_id !== '0'"
                >
                  <router-link :to="`/actors/${conversation.recipient_actor_id}`">
                    {{ conversation.recipient_actor_name }}
                  </router-link>
                </template>
              </div>
            </template>
            <template v-else-if="conversation.recipient_name === userName">
              <avatar
                :src="conversation.creator_photo_url"
                :alt="conversation.creator_name"
                variant="border"
              />
              <div class="notification-detail__conversation-user">
                <div style="display: flex; justify-content: flex-start; gap: 5px;">
                  {{ conversation.creator_name }}
                  <a class="notification-detail__conversation-user__link" @click.prevent="openUserSidePanel(conversation.creator_id)">
                    <icon name="information-outline" size="14"/>
                  </a>
                </div>
                <span class="recipient_department">{{ receivingDepartment }}</span>
                <template
                  v-if="conversation.creator_actor_name && conversation.creator_actor_id && conversation.creator_actor_id !== '0'"
                >
                  <router-link :to="`/actors/${conversation.creator_actor_id}`">
                    {{ conversation.creator_actor_name }}
                  </router-link>
                </template>
              </div>
            </template>
          </div>
          <div
            class="notification-detail__comments"
            v-if="(conversation.last_comment && conversation.last_comment.comment) || (conversationItems && conversationItems.length > 0)"
          >
            <div
              class="notification-detail__comment"
              v-for="(comment, index) in conversationItems" :key="comment.id"
            >
              <div
                class="comment__container"
                :class="{'comment__container-self': comment.user_name === userName}"
              >
                <avatar :src="comment.user_photo_url" :alt="comment.user_name"/>
                <div class="comment__body">
                  <div class="comment__time-container">
                    <time class="comment__time">{{
                        timeAgoTranslated(comment.created_at)
                      }}
                    </time>
                  </div>
                  <div
                    class="talkbubble"
                    :class="{ 'talkbubble-info': comment.comment_type === 'email', 'talkbubble-self': comment.user_name === userName }"
                  >
                    <richBodyContent :html="comment.comment"></richBodyContent>
                  </div>
                </div>
                <div v-if="comment.comment_type === 'comment'" class="comment__cta">
                  <div>
                    <ds-button
                      @click="deleteComment(comment)"
                      v-if="editable(comment)"
                      class="button--minimal button--default" icon="trash"
                      size="small" iconSize="18"
                    />
                  </div>
                </div>
              </div>
            </div>
            <template
              v-if="(!conversation.last_comment || !conversation.last_comment.comment) && (!conversationItems || conversationItems.length === 0)"
            >
            </template>
          </div>
          <div
            v-else-if="!loadingConversations && (!conversation.last_comment || !conversation.last_comment.comment) && (!conversationItems || conversationItems.length === 0)"
            class="notification-detail__comments empty-comments"
          >
            {{
              $t('conversation_no_messages', { user: conversation.recipient_name === userName ? conversation.creator_name : conversation.recipient_name })
            }}
          </div>
          <div v-else-if="$store.state.conversations.detail.error" class="col-xs-12">
            <p class="conversation__no-conversations">
              Something went wrong while loading the conversation
              <br/>
              <icon name="no-conversation"/>
            </p>
          </div>
          <div class="notification-detail__comments empty-comments" v-else>
            <!-- the loading indication will be shown during each new interval (check for new conversations) -->
            <loading/>
          </div>
          <div class="comment__row" style="">
            <div class="notification-detail__newcomment">
              <form-group class="newcomment_form" :errors="errors.comment">
                <p
                  contenteditable @input="newComment = $event.target.innerHTML"
                  @keydown.enter.exact.prevent="addComment"
                  ref="contenteditable"
                  class="newcomment__contenteditable"
                  :placeholder="$t('conversations_type_your_message')"
                  v-if="canUserReceiveNewMessage"
                />
                <div class="newcomment__actions-container">
                  <div class="newcomment__actions">
                    <div
                      @click="addComment" class="newcomment__actions-icon"
                      style="margin-left: 10px;"
                      v-if="canUserReceiveNewMessage"
                    >
                      <icon name="paper-plane" size="14"/>
                    </div>
                  </div>
                </div>
              </form-group>
            </div>
          </div>
        </template>
        <div v-else-if="$store.state.conversations.detail.error" class="col-xs-12">
          <p class="conversation__no-conversations">
            Something went wrong while loading the conversation
            <br/>
            <icon name="no-conversation"/>
          </p>
        </div>
        <template v-else-if="filters.searchQuery">
          <p class="conversation__no-conversations">
            Please select a conversation.
            <br/>
            <icon name="no-conversation"/>
          </p>
        </template>
        <template v-else-if="externalErrorMessage">
          <p class="conversation__no-conversations">
            {{ externalErrorMessage }}
            <br/>
            <icon name="no-conversation"/>
          </p>
        </template>
        <template v-else-if="loadingCreatedConversation || loadingSearchQuery">
          <loading/>
        </template>
      </div>
    </div>

    <!-- Conversation right side: empty state -->
    <div
      class="col-xs-12 col-sm-8 col-md-6 col-lg-8 col-xl-10 conversation-detail__right"
      v-else-if="filters.searchQuery && !loadingConversations && conversations.length === 0 && suggestedConversations.length === 0"
    >
      <p class="conversation__no-conversations">
        No conversations found
        <br/>
        <icon name="no-conversation"/>
      </p>
    </div>

    <!-- Conversation right side: no search & no conversations found -->
    <div
      class="col-xs-12 col-sm-8 col-md-6 col-lg-8 col-xl-10 conversation-detail__right"
      v-else-if="!filters.searchQuery && (!conversations || !conversations.length)"
    >
      <loading v-if="loadingConversations"/>
      <h3 v-else-if="externalErrorMessage" style="text-align: center; margin-top: 140px;">{{ externalErrorMessage }}</h3>
      <template v-else>
        <!--<div class="conversation__title-container">
          <h2 class="h2">
            {{ $t('conversations_title') }}
          </h2>
        </div>-->
        <p class="conversation__no-conversations">
          <h3 v-if="externalErrorMessage" style="text-align: center; margin-top: 140px;">{{ externalErrorMessage }}</h3>
          <template v-else-if="$store.getters.canActorsBeContacted">
            {{ $t('conversations_none_available', { announcement: announcementLabel }) }}
          </template>
          <template v-else>
            {{ $t('conversations_none_available_no_contact') }}
          </template>
          <br>
          <!-- -->
          <icon name="no-conversation"/>
        </p>
      </template>
      <!--<p style="text-align: center" v-else>No conversations available</p>-->
    </div>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'
  import RichTextArea from '../TextArea/RichTextArea.vue'
  import RichBodyContent from '../RichBodyContent/RichBodyContent.vue'
  import { timeAgoTranslated } from '../../util/date.js'
  import debounce from 'lodash/debounce'
  import { Conversations, createComment, fetchComments, fetchEmailLogs, getSuggestedConversations, } from '../../api/conversations.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import _throttle from 'lodash/throttle.js'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations.js'
  import Loading from '../Dashboard/ConceptMap/Loading.vue'
  import BadgeCounter from '../Badge/BadgeCounter.vue'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import _isEmpty from 'lodash/isEmpty.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'

  const COMMENT_LIMIT = 1000
  export default {
    data () {
      return {
        errors: [],
        externalErrorMessage: '',
        comments: [],
        emailLogs: [],
        offset: 0,
        offsetEmailLogs: 0,
        commentCount: 0,
        emailLogCount: 0,
        newComment: '',
        fetchingComments: false,
        fetchingEmailLogs: false,
        conversationId: null,
        department: null,
        filters: {
          searchQuery: null
        },
        loadingCreatedConversation: false,
        loadingSearchQuery: false,
        suggestedConversations: [],
      }
    },
    mixins: [TranslationsMixin],
    computed: {
      canAccessUserInformationPanel () {
        return this.isOwner || this.isPortfolioMember || this.isMember || this.isEcosystemMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      actorsCanBeContacted () {
        return this.$store.getters.canActorsBeContacted
      },
      receivingDepartment () {
        return this.department
      },
      receivingUserName () {
        return this.conversation.creator_id == this.userId ? this.conversation.recipient_name : this.conversation.creator_name
      },
      canUserReceiveNewMessage () {
        return this.receivingUserName !== 'removed user'
      },
      userId () {
        return this.$store.state.user.profile.id
      },
      userName () {
        return this.$store.state.user.profile.name
      },
      loadingConversations () {
        return this.$store.state.conversations.listData.loading
      },
      conversationLoading () {
        return this.store.state.conversations.detail.loading
      },
      conversations () {
        return this.$store.state.conversations.listData.data
      },
      conversation () {
        return this.$store.state.conversations.detail.data
      },
      conversationItems () {
        const items = this.comments.concat(this.emailLogs)

        if (!items || items.length < 1) {
          return []
        }

        return items.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })
      },
    },
    methods: {
      openUserSidePanel (userId) {
        trackHeapEvent('conversationDetail.openUserSidePanel')
        // Only open the side panel if it's a registered user, not an invitation
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'user-information-side-panel',
          metaData: {
            userId: userId,
            allowDelete: false,
          },
        })
      },
      startConversation (userId) {
        this.loadingCreatedConversation = true

        Conversations.post({ recipient_id: userId }).then((conversation) => {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)

          this.selectConversation(conversation.id)
          // this.setConversationId(conversation.id)
          // this.fetchConversation(true)
          // this.fetchComments()
          // this.fetchEmailLogs()

          this.$router.push('/profile/conversations/' + conversation.id)

          this.loadingCreatedConversation = false
        }).catch(errors => {
          console.log('something went wrong while creating the conversation')
          this.loadingCreatedConversation = false
        })

        // Reset search query
        this.filters.searchQuery = ''
        this.suggestedConversations = []
      },
      async reloadConversationsDetail () {
        if (!this.$route.params || (typeof this.$route.params.id === 'undefined')) {
          if (!this.conversations || !this.conversations.length) {
            if (!this.$store.state.conversations.listData.promise) {
              await this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            }
          }

          if (!this.$store.state.conversations.listData.loading) {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          }

          if (this.conversations && this.conversations.length) {
            this.$router.push('/profile/conversations/' + this.conversations[0].id)
          }
        } else {
          this.selectConversation(this.$route.params.id)
        }
      },
      setConversationId (conversationId) {
        this.conversationId = conversationId
      },
      selectConversation (conversationId, resetError = false) {

        if (resetError) {
          this.externalErrorMessage = ''
        }

        if (this.externalErrorMessage) {
          return
        }
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATION_DETAIL, conversationId)
        this.setConversationId(conversationId)
        this.fetchComments(true)
        this.fetchEmailLogs(true)
        this.externalErrorMessage = ''
      },
      timeAgoTranslated,
      fetchComments (reset) {
        if (!Number(this.conversationId) || _isEmpty(this.conversation)) {
          return
        }

        if (this.fetchingComments && !reset) {
          return
        }

        if (reset) {
          this.offset = 0
        } else if (this.comments.length > 0 && this.comments.length < this.commentCount) {
          this.offset += 10
        } else if (this.offset !== 0) {
          return
        }

        this.fetchingComments = true
        fetchComments(this.conversationId, COMMENT_LIMIT, this.offset).then((result) => {
          if (this.offset === 0) {
            this.comments = []
          }

          this.comments = this.comments.concat(result.comments)
          this.fetchingComments = false

          if (reset || result.count !== this.commentCount) {
            this.commentCount = result.count

            if (this.$refs.commentsContainer) {
              this.$nextTick(() => {
                this.$refs.commentsContainer.scrollTop = this.$refs.commentsContainer.scrollHeight
              })
            }
          }
        })
      },
      fetchEmailLogs (reset) {
        if (!Number(this.conversationId) || _isEmpty(this.conversation)) {
          return
        }

        if (this.fetchingEmailLogs && !reset) {
          return
        }

        if (reset) {
          this.offsetEmailLogs = 0
        } else if (this.emailLogs.length > 0 && this.emailLogs.length < this.emailLogCount) {
          this.offsetEmailLogs += 10
        } else if (this.offsetEmailLogs !== 0) {
          return
        }

        this.fetchingEmailLogs = true
        fetchEmailLogs(this.conversationId, COMMENT_LIMIT, this.offsetEmailLogs).then((result) => {
          if (this.offsetEmailLogs === 0) {
            this.emailLogs = []
          }

          this.emailLogs = this.emailLogs.concat(result.emailLogs)
          this.fetchingEmailLogs = false

          if (reset || result.count !== this.emailLogCount) {
            this.emailLogCount = result.count

            if (this.$refs.commentsContainer) {
              this.$nextTick(() => {
                this.$refs.commentsContainer.scrollTop = this.$refs.commentsContainer.scrollHeight
              })
            }
          }
        })
      },
      addComment () {
        if (!this.newComment) {
          return
        }

        trackHeapEvent('conversationDetail.addComment')

        createComment(this.conversationId, this.newComment)
          .then(() => {
            this.newComment = ''
            if (this.$refs.contenteditable) {
              this.$refs.contenteditable.innerText = ''
            }
            this.fetchComments(true)
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      deleteComment (comment) {
        return false
      },
      editable (comment) {
        return false
      },
      fetchFilteredList () {
        // if (!this.$store.state.conversations.listData.promise) {
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST_FILTERED_NOW, this.filters.searchQuery)
        // }

        // await this.$store.state.conversations.listData.promise

        if (!this.$store.state.conversations.listData.loading) {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST_FILTERED, this.filters.searchQuery)
        }
      },
      fetchSuggestedConversations () {
        getSuggestedConversations(this.filters.searchQuery).then(result => {
          setTimeout(() => {
            this.suggestedConversations = result
            this.loadingSearchQuery = false
          }, 1000)

        }).catch(errors => {
          console.log(errors)
          this.loadingSearchQuery = false
        })
      },
      fetchConversation (clearCommentCache) {
        if (!this.conversationId) {
          console.log('skipping fetching conversation details')

          return
        }

        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATION_DETAIL, Number(this.conversationId))
          .then(result => {
            if (!this.commentCount || !this.emailLogCount || (clearCommentCache && (this.commentCount < this.conversation.comment_count || this.emailLogCount < this.conversation.email_log_count))) {
              this.fetchComments(true)
              this.fetchEmailLogs(true)
            }

            this.department = this.conversation.recipient_department
          }).catch(errors => {
          console.log(errors)
        })
      },
      onScrollConversation: _throttle(function () {
        const elem = this.$refs.commentsContainer
        if (elem && elem.scrollTop < 25) {
          this.fetchComments()
          this.fetchEmailLogs()
        }
      }, 1000),
      startConversationWithActorOwner () {
        Conversations
          .post({
            actor_id: this.$route.query.actor_id,
          })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id).then(() => {
              this.selectConversation(conversation.id)
              this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
                component: 'user-information-side-panel',
                metaData: {
                  userId: conversation.recipient_id,
                  allowDelete: false,
                },
              })
            })
          })
          .catch((err) => {
            this.externalErrorMessage = err.message
            console.log('err', err)
          })

        // unset router query
        this.$router.push({ query: {} })
      }
    },
    async mounted () {
      if (this.$route.query.external && this.$route.query.external === 'conversation' && this.$route.query.actor_id && this.$store.getters.isLoggedIn) {
        this.startConversationWithActorOwner()
      }

      if (!this.conversations || !this.conversations.length) {
        if (!this.$store.state.conversations.listData.promise) {
          await this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST_NOW)
        }
        await this.$store.state.conversations.listData.promise
      }

      if (!this.$store.state.conversations.listData.loading) {
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
      }

      if (!this.conversations || !this.conversations.length) {
        if (!this.$route.params.id) {
          return
        }
      } else {
        if (!this.$route.params.id) {
          this.$router.push({
            name: 'profile',
            params: {
              panel: 'conversations',
              id: this.conversations[0].id,
            },
          })
        }
      }

      this.setConversationId(this.$route.params.id)
      this.fetchConversation()
      this.fetchComments()
      this.fetchEmailLogs()

      this.interval = setInterval(() => this.fetchConversation(true), 10000)
      this.$refs.commentsContainer.addEventListener('scroll', this.onScrollConversation, { passive: true })

    },
    watch: {
      filters: {
        deep: true,
        handler: debounce(function (d) {
          this.loadingSearchQuery = true

          this.setConversationId(null)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)

          this.fetchFilteredList()
          this.fetchSuggestedConversations()
        }, 600, {
          trailing: true,
        }),
      },
      '$route.params.id': {
        handler () {
          if (this.$route.path.startsWith('/profile/conversations')) {
            this.department = null
            this.reloadConversationsDetail()

            Conversations.get(this.$route.params.id)
              .then(conversation => {
                this.department = conversation.recipient_department
              })
              .catch(errors => {
                console.log(errors)
              })
          }
        },
        immediate: false, // calls the handler on component mount/create as well, so no need to call fn on mounted again
      },
    },
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      this.setConversationId(null)
      clearInterval(this.interval)

      if (this.$refs.commentsContainer) {
        this.$refs.commentsContainer.removeEventListener('scroll', this.onScrollChallenges, { passive: true })
      }
    },
    components: {
      WithConfigurationTabs,
      Avatar,
      RichTextArea,
      RichBodyContent,
      BadgeCounter,
      Loading,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .old-conversations {
    .notification-detail__comments-container {
      margin-bottom: 50px;

      .notification-detail__comments {
        display: flex;
        flex-direction: column-reverse;
      }

      .notification-detail__comment {
        padding: 20px 0;
      }

      .comment__container {
        display: flex;

        .comment__user {
          color: #9CBEBD;
        }

        .comment__time {

        }

        .comment__body {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }

        .comment__cta {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          min-width: 103px;
          margin-top: 16px;
          margin-left: 5px;
          text-align: right;
        }

      }

      :deep(.avatar) {
        width: 40px;
        height: 40px;
        margin-right: 32px;
        margin-top: 18px;

        &.newcomment__avatar {
          margin-top: 0
        }

        img {
          width: 40px;
          height: 40px;
        }
      }

      .newcomment_form {
        display: flex;

        .newcomment__input {
        }

        :deep(.form-group__header) {
          display: none;
        }
      }

      .challenge-detail__submit {
        width: 103px;
        margin-left: 5px;
        height: 32px;
        margin-top: auto;
      }
    }

    :deep(.rich-body-content) {
      margin-bottom: 0;
    }
  }

  .conversation-detail {
    .conversation-detail__left {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding-right: 0;
      border-right: 1px solid $color-borders;

      @media (max-width: $screen-md) {
        height: 38%;
      }
    }

    .conversation-detail__right {
      height: 100%;
      padding-left: 0;
      overflow: inherit;

      @media (max-width: $screen-md) {
        height: 60%;
        overflow: scroll;
        border-top: 2px solid $color-primary;

        .notification-detail__conversation-title {
          box-shadow: 0 0 10px rgba(0, 0, 0, .2);
        }
      }
    }

    .conversation__title-container {
      border-bottom: 1px solid $color-borders;
      font-family: $font-stack-secondary;
      height: 81px;

      h2.h2 {
        font-size: 22px;
        height: 36px;
        line-height: 36px;
        padding: 0 20px;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 10px;
        letter-spacing: 1px;
      }
    }

    .conversation__search-container {
      border-bottom: 1px solid $color-borders;
      font-family: $font-stack-secondary;

      :deep(input) {
        border: 1px solid transparent;

        &:focus {
          border: 1px solid var(--primary);
          box-shadow: none !important;
        }
      }
    }

    .notification-detail__comments-container {
      margin-bottom: 50px;
      height: 100%;
      display: flex;
      flex-direction: column;
      $titleHeight: 80px;

      @media (max-width: $screen-md) {
        margin-bottom: 0px;
      }

      .notification-detail__conversation-title {
        border-bottom: 1px solid $color-borders;
        padding: 20px;
        display: flex;

        :deep(.avatar) {
          width: 40px;
          height: 40px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      .notification-detail__conversation-user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
      }

      .notification-detail__conversation-user__link {
        cursor: pointer;
      }

      .notification-detail__comments {
        display: flex;
        flex-direction: column-reverse;
        flex-grow: 1;
        overflow: auto;
        padding: 0 20px;

        &.empty-comments {
          justify-content: start;
          max-width: 50%;
          margin: 10px auto;
          font-family: $font-stack-secondary;
          text-align: center;
        }
      }

      .notification-detail__comment {
        padding: 20px 0;
      }

      .comment__container {
        display: flex;

        .comment__user {
          color: #9CBEBD;
        }

        .comment__time-container {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .comment__time {
            font-size: 10px;
            font-family: $font-stack-secondary;
            margin: 0 10px;
          }
        }

        .comment__body {
          display: flex;
          flex-direction: row-reverse;
          flex-grow: 1;
        }

        &.comment__container-self {
          .comment__body {
            flex-direction: row;
          }
        }

        .comment__cta {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          min-width: 103px;
          margin-top: 16px;
          margin-left: 5px;
          text-align: right;
        }

        :deep(.avatar) {
          width: 40px;
          height: 40px;
          margin-right: 32px;
          cursor: help;

          &.newcomment__avatar {
            margin-top: 0
          }

          img {
            width: 40px;
            height: 40px;
          }
        }

        &.comment__container-self {
          flex-direction: row-reverse;

          :deep(.avatar) {
            margin-right: 0;
            margin-left: 32px;
          }
        }

      }

      .newcomment_form {
        display: flex;
        margin: 0;
        padding: 20px;
        border-top: 1px solid $color-borders;

        .newcomment__actions-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .newcomment__actions {
          display: flex;

          .newcomment__actions-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--primary-extra-lightest);
            padding: 8px;
            border-radius: $default-border-radius-narrow;
            width: 36px;
            cursor: pointer;

            > svg {
              color: var(--primary);
              font-size: 12px;
            }
          }
        }

        .newcomment__contenteditable {
          background-color: var(--primary-extra-lightest);
          min-height: 20px;
          flex-grow: 1;
          border-radius: $default-border-radius-narrow;
          margin-bottom: 0;
          padding: 10px 20px;
          cursor: text;
          max-height: 100px;
          overflow: auto;

          &:focus {
            outline: 1px solid var(--primary);
          }
        }

        [contenteditable][placeholder]:empty:before {
          content: attr(placeholder);
          position: absolute;
          color: gray;
          background-color: transparent;
        }

        :deep(.form-group__header) {
          display: none;
        }
      }

      .challenge-detail__submit {
        width: 103px;
        margin-left: 5px;
        height: 32px;
        margin-top: auto;
      }

      :deep(.rich-body-content) {
        margin-bottom: 0;
        white-space: pre-line;
      }

    }

    .conversation-suggestions__container {
      a {
        text-decoration: none;
        text-transform: none;
        position: relative;
      }

      .conversation-preview {
        margin-bottom: 0;
      }
    }

    .conversation-preview__container {
      flex-grow: 1;
      overflow: auto;
      font-size: 0;
    }

    .conversation-ongoing__container {
      a {
        text-decoration: none;
        position: relative;
        display: block;
      }

      .conversation-preview__badge {
        position: absolute;
        top: 28px;
        left: 52px;
        z-index: 83;
      }
    }

    .conversation-preview {
      border-bottom: 1px solid $color-borders;
      padding: 1rem;
      margin-bottom: 1em;
      display: flex;

      .conversation-preview-avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;

        :deep(.avatar) {
          height: 40px;
          width: 40px;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }

      &.selected {
        background-color: var(--primary-extra-lightest);
      }

      &:hover {
        background-color: var(--primary-extra-lightest);
      }

      .conversation-preview-text {
        width: calc(100% - 67px);
        margin-left: 30px;
      }

      .conversation-preview__title {
        font-size: 14px;
        margin-bottom: 5px;
        letter-spacing: 1px;
        line-height: 16px;
        text-transform: uppercase;
        color: $color-text-grey;
      }

      .conversation-preview__subtitle {
        font-size: 12px;
        margin-bottom: 5px;
        color: $color-text-grey-light
      }

      .conversation-preview__content {
        word-break: break-word;
        flex: 1;
        font-size: 11px;
        font-weight: 100;
        line-height: 16px;
        color: $color-text-grey;
        overflow: hidden;
        max-height: 100px;
        width: 100%;

        :deep(.rich-body-content) {
          white-space: nowrap;
          overflow: hidden;
          height: 16px;
          /* max-width: 100%; */
          line-height: 16px;
          text-overflow: ellipsis;

          * {
            font-size: 12px;
            font-weight: normal;
            text-decoration: none;
          }

          img {
            display: none;
          }
        }
      }

      :deep(.rich-body-content) {
        margin-bottom: 0;
      }
    }

    .conversation__no-conversations {
      font-size: 18px;
      text-align: center;
      margin: 20px 50px;

      .svg-icon {
        width: 200px;

        :deep(svg) {
          width: 200px;
          height: 200px;
          fill: var(--primary-extra-lightest);
        }
      }
    }
  }

  .recipient_department {
    font-size: 12px;
    color: grey;
  }

  @media only screen and (max-width: 767px) {
    .comment__row {
      margin-bottom: 70px;
    }
  }

</style>
