const MODAL_IDS = {
  ACTOR_SIGN_UP: 'ACTOR_SIGN_UP',
  ACTOR_VIDEO: 'ACTOR_VIDEO',
  ADD_ACTOR: 'ADD_ACTOR',
  ADD_DOMAIN: 'ADD_DOMAIN',
  ADD_INSTANT_MONITORING: 'ADD_INSTANT_MONITORING',
  ADD_RELATION: 'ADD_RELATION',
  ARTICLE_RELATION_MODAL: 'ARTICLE_RELATION_MODAL',
  MANAGE_USER: 'ADD_USER',
  ALL_FILERS: 'ALL_FILERS',
  ALL_FILTERS: 'ALL_FILTERS',
  CUSTOM_FILTERS: 'CUSTOM_FILTERS',
  CANCEL_ACTOR_EDIT_CONFIRMATION: 'CANCEL_ACTOR_EDIT_CONFIRMATION',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CLAIM: 'CLAIM',
  COLOUR_PICKER: 'COLOUR_PICKER',
  CONFIRMATION: 'CONFIRMATION',
  CONTACT: 'CONTACT',
  CONTACT_SUPPORT: 'CONTACT_SUPPORT',
  DELETE_CONFIRMATION: 'DELETE_CONFIRMATION',
  EDIT_TOPIC: 'EDIT_TOPIC',
  EDIT_USER_PROFILE: 'EDIT_USER_PROFILE',
  EXPLORE_CONTENT: 'EXPLORE_CONTENT',
  FINALISE_SIGN_UP: 'FINALISE_SIGN_UP',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  GDPR: 'GDPR',
  LOGIN: 'LOGIN',
  LOGGED_OUT: 'LOGGED_OUT',
  MAGIC_SIGN_UP: 'MAGIC_SIGN_UP',
  MANAGE_ANNOUNCEMENT: 'MANAGE_ANNOUNCEMENT',
  ANNOUNCEMENT_ADDRESS: 'ANNOUNCEMENT_ADDRESS',
  ANNOUNCEMENT_CALENDAR: 'ANNOUNCEMENT_CALENDAR',
  CREATE_COMMUNITY: 'CREATE_COMMUNITY',
  MANAGE_COMMUNITY_MEMBERS: 'MANAGE_COMMUNITY_MEMBERS',
  CREATE_SPOTTING_AREA: 'CREATE_SPOTTING_AREA',
  CREATE_PROOF_POINT: 'CREATE_PROOF_POINT',
  MORE_ANNOUNCEMENT_FILTER_TAGS: 'MORE_ANNOUNCEMENT_FILTER_TAGS',
  MORE_FILTER_TAGS: 'MORE_FILTER_TAGS',
  MORE_LEGENDS: 'MORE_LEGENDS',
  MORE_TAGS: 'MORE_TAGS',
  PORTFOLIO_ACTORS_REMOVE: 'PORTFOLIO_ACTORS_REMOVE',
  REMOVE_DOMAIN: 'REMOVE_DOMAIN',
  REMOVE_RELATION: 'REMOVE_RELATION',
  RESTRICTED: 'RESTRICTED',
  SIGN_UP: 'SIGN_UP',
  SUCCESS: 'SUCCESS',
  TAXONOMY_INFO: 'TAXONOMY_INFO',
  USER_EDIT: 'USER_EDIT',
  USER_LEAVE_ECOSYSTEM: 'USER_LEAVE_ECOSYSTEM',
  USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA: 'USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA',
  REMOVE_USER_FROM_ECOSYSTEM: 'REMOVE_USER_FROM_ECOSYSTEM',
  CONTENT_DIGEST_INFO: 'CONTENT_DIGEST_INFO',
  EDIT_NACE_CODES: 'EDIT_NACE_CODES',
  PACKAGES_SIGN_UP: 'PACKAGES_SIGN_UP',
  CONFIRM_PACKAGE: 'CONFIRM_PACKAGE',
  IMAGE_DETAIL: 'IMAGE_DETAIL',
  MAP: 'MAP',
  WELCOME_MESSAGE: 'WELCOME_MESSAGE',
  RESTRICTED_MODAL: 'RESTRICTED_MODAL',
  ADD_PRODUCT: 'ADD_PRODUCT',
  NO_ACCESS_TO_COMMUNITY_MODAL: 'NO_ACCESS_TO_COMMUNITY_MODAL',
  ADD_ACTOR_TO_ARTICLE_MODAL: 'ADD_ACTOR_TO_ARTICLE_MODAL',
  ADD_MEMBER_TO_COMMUNITY_MODAL: 'ADD_MEMBER_TO_COMMUNITY_MODAL',
  ADD_CONTRIBUTOR_MODAL: 'ADD_CONTRIBUTOR_MODAL',
  MANAGE_EVENT: 'MANAGE_EVENT',
  SELECT_SPOTTING_AREA_MODAL: 'SELECT_SPOTTING_AREA_MODAL',
  REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL: 'REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL',
}

export default MODAL_IDS
