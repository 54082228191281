import isString from 'lodash/isString'

export function strAfter (str: string, needle: string): string {
  return str.includes(needle) ? str.slice(str.indexOf(needle) + needle.length) : ''
}

export function strUpto (str: string, needle: string): string {
  return str.includes(needle) ? str.slice(0, str.indexOf(needle)) : str
}

export function shortFilename (filename: string | null): string | null {
  if (!filename) {
    return filename
  }
  return filename && filename.length < 35 ? filename : filename.slice(0, 27) + '..' + filename.slice(filename.lastIndexOf('.'))
}

export function textLimit (text: string, stringLength: number = 250): string {
  return text.length < stringLength ? text : text.slice(0, stringLength) + '…'
}

export function clipFileName (filename: string, stringLength: number = 27): string {
  if (stringLength == undefined) {
    stringLength = 27
  }

  return filename.length < stringLength ? filename : filename.slice(0, stringLength) + '..' + filename.slice(filename.lastIndexOf('.'))
}

export function capitalize (str: string): string {
  if (!isNaN(str)) {
    str = str.toString()
  }

  if (!isString(str)) {
    console.log('capitalize() expects a string argument', str)
    return
  }

  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function camelize (str: string): string {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

export function snakeCase (string: string): string {
  return string.replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_')
}
