<template>
  <div>
    <h4 class="h4">Add coupon code or credit card information for account verification. Payment will be effective once you complete the
      registration.</h4>
    <br>
    <div>
      <div class="promocode-validation__container">
        <h3 class="h3">Add a coupon code</h3>
        <ask-coupon-code
          :model-value="couponCode"
          @setCouponValidation="setCouponValidation"
          :coupon-validation="couponValidation"
          :payment-error="paymentError"
          :updating-plan="updatingPlan"
          :selected-package="selectedPackage"
          @update:modelValue="setCouponCode"
        />
      </div>
      <div class="payment-info__container">
        <ask-payment-method
          @updatePaymentMethod="updatePaymentMethod"
          v-show="registrationRequiresPayment"
        />
        <div v-show="!registrationRequiresPayment">
          <h3 class="h3">Credit card not required because the
            {{ useTrial ? 'trial' : 'package' }} is free</h3>
        </div>
        <div v-show="paymentMethodId">
          Your credit card information is verified.
        </div>
      </div>
    </div>
    <div>
      <div>
        <div class="person_subscription__plan-cta" v-if="paymentError">
          <div v-if="paymentError && paymentError.route">
            <span>For security reasons, we need you to complete a secondary verification step, <a
              :href="paymentError.route"
            >click here</a> to finalise your payment.</span>
          </div>
          <div v-else-if="paymentError && paymentError.paymentMethodId">
            Please enter the credit card number, expiry date, CVC code and postal code of your credit card
          </div>
          <div class="form-group__error" v-else-if="paymentError && paymentError.message">
            <span>Something went wrong while processing your payment, contact us so we can help you further.</span>
          </div>
          <div
            class="form-group__error" v-else-if="Object.keys(paymentError).length"
            style="font-size: 14px"
          >
            Something went wrong while updating your subscription:
            <div v-if="!Array.isArray(paymentError)">
              {{ paymentError }}
            </div>
            <div v-for="error in paymentError" v-else>
              {{ Array.isArray(error) ? error[0] : error }}
            </div>
          </div>
          <br>
        </div>
        <ds-button
          style="margin-top: 5%;"
          :label="updatingPlan ? $t('confirm_package_updating') : $t('confirm_package_register_plan')"
          v-if="paymentMethodId || !registrationRequiresPayment"
          variant="primary"
          @click="goToNextStep"
          :disabled="currentCouponCodeIsInvalid || updatingPlan || (!couponCode && !paymentMethodId)"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import AskCouponCode from '../../components/Modals/PackagesSignUpModal/AskCouponCode.vue'
  import AskPaymentMethod from '../../components/Modals/PackagesSignUpModal/AskPaymentMethod.vue'

  export default {
    props: {
      selectedPackage: Object,
      couponCode: String,
      paymentError: String,
      useTrial: Boolean,
      couponValidation: Object,
      updatingPlan: Boolean,
      paymentMethodId: String,
    },
    data () {
      return {
        billingInfo: null,
      }
    },
    emits: ['updatePaymentMethod', 'setCouponValidation', 'setCouponCode', 'next'],
    computed: {
      registrationRequiresPayment () {
        return !this.couponValidation || !this.couponValidation.valid || this.couponValidation.discount !== 100
      },
      currentCouponCodeIsInvalid () {
        return this.couponValidation && this.couponValidation.valid === false
      },
    },
    methods: {
      updatePaymentMethod (paymentMethod) {
        this.$emit('updatePaymentMethod', paymentMethod)
      },
      setCouponValidation (value) {
        this.$emit('setCouponValidation', value)
      },
      setCouponCode (couponCode) {
        this.$emit('setCouponCode', couponCode)
      },
      goToNextStep () {
        this.$emit('next')
      },
    },
    components: {
      AskCouponCode,
      AskPaymentMethod,
    },
  }
</script>
<style>

</style>
